import React, {useState, useEffect} from 'react'
import { DotsSixVertical } from 'phosphor-react'
import { useTheme } from '../styles/ThemeContext'

const ListItem = (props) => {

  const {colors} = useTheme();

  return (
    <li key={props.key} className={`List-item-${props.appearance} flex`} onClick={props.onClick} style={{background: colors.background, borderWidth: 1, borderColor: colors.border}}>
      {props.draggable ? <DotsSixVertical/> : null}
      {props.componentBefore}
      {props.iconBefore}
      {props.component}
      <h4 style={{color: colors.text}}>{props.name}</h4>
      {props.value}
      {props.iconAfter}
      {props.componentAfter}
    </li>
  )
}

export default ListItem