import React from 'react'
import { useTheme } from '../styles/ThemeContext'
import Button from '../components/Button.tsx'
import Spacer from './Spacer.js';

const DownloadMobileBanner = () => {
    const { colors } = useTheme();

    const appUrl = "https://apps.apple.com/au/app/bentofy/id6470709729";

    const handleGoToAppUrl = () => {
        window.location.href = appUrl;
    }

    const isMobile = /iPhone|iPad/i.test(window.navigator.userAgent);

    return (
        <div>
            {isMobile && (
                <div>
                    <div style={{ background: colors.backgroundInverse, display: 'flex', flexDirection: 'row', padding: 16, borderRadius: 16, alignItems: 'center', }}>
                        <h3 style={{ color: colors.textInverse, display: 'flex', flexGrow: 1, margin: 0 }}>Download the Bentofy iOS app for a better experience</h3>
                        <Button
                            name="Download"
                            appearance='default'
                            onClick={handleGoToAppUrl}
                            style={{ height: 40 }}
                        />

                    </div>
                    <Spacer size="md" />
                </div>
            )}

        </div>
    )
}

export default DownloadMobileBanner