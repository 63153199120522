import { useState, useEffect, useContext } from 'react';

// import { useRecipeSubmission } from './recipeSubmission';
// import AuthContext from '../contexts/AuthContext';
// import SubscriptionContext from '../contexts/SubscriptionContext';
import { countUserRecipes } from '../controllers/getRecipes';
import { useNavigate } from 'react-router-dom';
// import { useSubscription } from '../controllers/useSubscription';
import { MAX_RECIPES_LIMIT } from '../data/MaxRecipesLimit';
import { auth } from '../firebase-config';
import { useRecipeSubmission } from './recipeSubmission';

export const useCreateRecipeModal = () => {
    //   const { isSubscribed } = useContext(SubscriptionContext);
    const [showCreateRecipeModal, setShowCreateRecipeModal] = useState(false);
    const [showRecipeFromUrlModal, setShowRecipeFromUrlModal] = useState(false);
    const userId = auth.currentUser && auth.currentUser.uid;
    // const { userId } = useContext(AuthContext);
    const [userRecipeCount, setUserRecipeCount] = useState(0);

    const { onRecipeSubmit } = useRecipeSubmission();

    const [isGenerating, setIsGenerating] = useState(false);
    const [recipeDate, setRecipeDate] = useState(new Date());
    useEffect(() => {
        const fetchUserRecipeCount = async () => {
            const count = await countUserRecipes(userId);
            setUserRecipeCount(count);
        };

        fetchUserRecipeCount();
    }, [userId]);

    const navigate = useNavigate();
    const handleOpenCreateModal = () => {
        // if (!auth.currentUser) {
        //   navigate('AccountModal');
        // } else if (userRecipeCount >= MAX_RECIPES_LIMIT && !isSubscribed) {
        //   navigate('Upgrade');
        // } else {
        //   setShowCreateRecipeModal(true);
        // }

        setShowCreateRecipeModal(true);
    };

    const handleOpenCreateRecipeFromUrlModal = () => {
        // if (!auth.currentUser) {
        //   navigate('AccountModal');
        // } else if (userRecipeCount >= MAX_RECIPES_LIMIT && !isSubscribed) {
        //   navigate('Upgrade');
        // } else {
        //   setShowRecipeFromUrlModal(true);
        // }
        setShowRecipeFromUrlModal(true);
    };

    const handleOpenGenerateRecipeModal = () => {
        // if (!auth.currentUser) {
        //   navigation.navigate('AccountModal');
        // } else if (userRecipeCount >= MAX_RECIPES_LIMIT && !isSubscribed) {
        //   navigation.navigate('Upgrade');
        // } else {
        //   navigation.navigate('GenerateRecipe');
        // }
        // navigation.navigate('GenerateRecipe');
    };

    return {
        showCreateRecipeModal,
        setShowCreateRecipeModal,
        showRecipeFromUrlModal,
        setShowRecipeFromUrlModal,
        handleOpenCreateModal,
        handleOpenCreateRecipeFromUrlModal,
        handleOpenGenerateRecipeModal,
    };
};
