import React from 'react'
import Twemoji from 'react-twemoji';
import { useTheme } from '../styles/ThemeContext';

const Greetings = () => {
const currentDate = new Date();
const currentHour = currentDate.getHours();
let greeting = '';
let emoji = '';

const { colors } = useTheme();
if (currentHour >= 0 && currentHour < 12) {
    greeting = 'Good morning';
    emoji = '☕️';
} else if (currentHour >= 12 && currentHour < 18) {
    greeting = 'Good afternoon';
    emoji = '☀️';
} else {
    greeting = 'Good evening';
    emoji = '🌘';
}

return (
    <div className='flex Text-center' style={{alignItems: 'center'}}>
        <Twemoji options={{className: 'Twemoji-small'}}>{emoji}</Twemoji>
        <h2 className="Greetings" style={{marginBottom: 0, color: colors.text}}> {greeting}</h2>
    </div>
);
}

export default Greetings