import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../styles/ThemeContext';
import { collection, getDocs, where, query } from 'firebase/firestore';
import { db, } from '../firebase-config';
import { getAuth } from 'firebase/auth';
import Button from '../components/Button.tsx';
import { CaretLeft } from 'phosphor-react';
import RecipeCard from './RecipeCard.js';
import Spacer from './Spacer.js';
import { handleSaveRecipe, handleUnsaveRecipe, fetchSavedRecipes } from '../controllers/saveRecipe.js';

const RecipeCollection = () => {
    const location = useLocation();
    const { cuisine } = location.state;
    const { colors } = useTheme();
    const navigate = useNavigate();
    const auth = getAuth();
    const [recipes, setRecipes] = useState([]);
    const userId = auth.currentUser ? auth.currentUser.uid : null;

    const recipesCollectionRef = collection(db, 'recipes');

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                const recipesDocs = await getDocs(
                    query(
                        recipesCollectionRef,
                        where('cuisine', '==', cuisine.name),
                        where('access', '==', 'public')
                    )
                );
                const recipesData = recipesDocs.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setRecipes(recipesData);
            } catch (error) {
                console.log(error);
            }
        };

        fetchRecipes();
    }, [cuisine]);

    const handleOpenRecipeModal = recipe => {
        navigate(`/recipe-detail/${recipe.id}`, { state: { recipe } });
    };

    // SAVE RECIPE
    const handleSaveRecipeClick = async recipe => {
        if (userId) {
            const success = await handleSaveRecipe(db, userId, recipe);
            if (success) {
                setRecipes(prevRecipes =>
                    prevRecipes.map(prevRecipe =>
                        prevRecipe.id === recipe.id
                            ? { ...prevRecipe, savedByCurrentUser: true }
                            : prevRecipe
                    )
                );
            }
        }
    };

    const handleUnsaveRecipeClick = async recipe => {
        if (userId) {
            const success = await handleUnsaveRecipe(db, userId, recipe);
            if (success) {
                setRecipes(prevRecipes =>
                    prevRecipes.map(prevRecipe =>
                        prevRecipe.id === recipe.id
                            ? { ...prevRecipe, savedByCurrentUser: false }
                            : prevRecipe
                    )
                );
            }
        }
    };


    return (
        <div style={{ height: '100%' }}>
            <div className='Page-container' style={{ backgroundColor: colors.background }}>
                <div className="Page-small">

                    <div className="Page-header">
                        <div className="Page-title">
                            <div style={{ background: cuisine.color, padding: 24, borderRadius: 24, display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                                <Button appearance='default Button-icon' iconBefore={<CaretLeft />} onClick={() => window.history.back()} />
                                <Spacer size="lg" />
                                <h2>{cuisine.icon} {cuisine.name}</h2>
                                <h4> This is all the essentials of {cuisine.name} food, made for your
                                    home cooking</h4>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 8 }}>
                        {recipes.map((recipe, index) => {
                            return (
                                <div style={{ width: '31%' }}>
                                    <RecipeCard
                                        key={index}
                                        recipe={recipe}
                                        imageUrl={recipe.imageUrl}
                                        type={recipe.type}
                                        onClick={() => handleOpenRecipeModal(recipe)}
                                        showSchedule={true}
                                        showIsSaved={true}
                                        handleUnsaveRecipe={() => handleUnsaveRecipeClick(recipe)}
                                        handleSaveRecipe={() => handleSaveRecipeClick(recipe)}
                                        showMoreMenu={false}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecipeCollection