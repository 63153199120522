import React, { useState, useEffect } from "react";
import { IconContext, House, CookingPot, CalendarBlank, ShoppingCart, ChartPie, FadersHorizontal, Coffee, DeviceMobile, MegaphoneSimple, Coin } from 'phosphor-react';
import '../App.css';
import Button from '../components/Button.tsx';
import Avatar from "./Avatar.tsx";
import Avatar1 from "../images/memoji-01.png"
import { useNavigate } from "react-router-dom";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
import { auth } from '../firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useFetchUserProfile } from '../controllers/fetchUserProfile';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where, } from "firebase/firestore";
import { db } from "../firebase-config"
import { getAuth } from "firebase/auth";
import { Modal, Tag } from "antd";
import toast from "react-hot-toast";
import FeedbackCollector from "./FeedbackCollector";
import { useTheme } from "../styles/ThemeContext.js";


const MainNavigation = (props) => {

    const { onFetchUserProfile } = useFetchUserProfile();
    const { colors } = useTheme();

    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
        window.scrollTo({
            top: 0,
            behaviour: 'smooth',
        });
        // setIsLoggedIn(true)
    };

    const navigateToRegister = () => {
        navigate('/register');
        window.scrollTo({
            top: 0,
            behaviour: 'smooth',
        });
    };

    const navigateToProfile = () => {
        navigate('/preferences');
        window.scrollTo({
            top: 0,
            behaviour: 'smooth',
        });
    };

    // GET USER PROFILE PICTURE
    const auth = getAuth();
    const userAuth = auth.currentUser;
    const [photoURL, setPhotoURL] = useState("https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg")

    useEffect(() => {
        if (userAuth !== null) {
            // The user object has basic properties such as display name, email, etc.
            const displayName = userAuth.displayName;
            const email = userAuth.email;
            // const photoURL = userAuth.photoURL;
            const providerData = userAuth.providerData;
            const photoURL = providerData[0].photoURL;
            const emailVerified = userAuth.emailVerified;
            const uid = userAuth.uid;

            setPhotoURL(photoURL)
        }
    }, [userAuth])

    // FEEDBACK
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)

    const handleShowFeedbackModal = () => {
        setShowFeedbackModal(!showFeedbackModal)
    }

    const [feedbackForm, setFeedbackForm] = useState({
        feedbackDetail: "",
        feedbackRating: "",
        feedbackEmail: "",
        feedbackSubmittedDate: new Date(),

    })

    const feedbackCollectionRef = collection(db, "feedback")

    const handleSubmitFeedback = () => {
        addDoc(feedbackCollectionRef, feedbackForm)

        setFeedbackForm({
            feedbackDetail: "",
            feedbackRating: "",
            feedbackEmail: userAuth.email,
            feedbackSubmittedDate: new Date(),
        })

        setShowFeedbackModal(false)
        toast.success("Thank you for submitting your feedback!")
    }


    return (
        <>

            {showFeedbackModal &&
                <Modal
                    open={showFeedbackModal}
                    onCancel={() => setShowFeedbackModal(false)}
                    footer={null}
                    width={800}
                    style={{ top: 40 }}
                >
                    <FeedbackCollector
                        feedbackEmailValue={userAuth.email}
                        feedbackRatingOnChange={(event) => setFeedbackForm({ ...feedbackForm, feedbackRating: event })}
                        feedbackDetailOnChange={(event) => setFeedbackForm({ ...feedbackForm, feedbackDetail: event.target.value })}
                        feedbackEmailOnChange={(event) => setFeedbackForm({ ...feedbackForm, feedbackEmail: userAuth.email })}
                        onSubmit={handleSubmitFeedback}
                        onCancel={() => setShowFeedbackModal(false)}
                    />

                </Modal>
            }
            <nav className="Top-nav-container" >
                <ul
                    // id="nav-list"
                    className="Main-nav-container" id="Top-nav" style={{ backgroundColor: colors.background }}
                >
                    <li className="Main-nav-tab">
                        <a href={userAuth ? "/app-home" : "./"} style={{ margin: 16 }}>
                            <img src={require("../images/logo192.png")} className="Logo-small" alt="logo" />
                        </a>
                    </li>

                    {/* <Button name="Test" appearance="subtle" 
                        onClick={handleTest}
                    /> */}
                    <li className="Main-nav-tab" style={{ marginRight: 16 }}>
                        {
                            userAuth
                                ?
                                <div className="Main-nav-tab-actions">
                                    <a href="https://forms.gle/Yq2QUj4wYocLAsscA" target="_blank" rel="noreferrer">
                                        <Button
                                            appearance="default"
                                            iconBefore={<MegaphoneSimple />}
                                            name="Give feedback"
                                        // onClick={handleShowFeedbackModal}
                                        />
                                    </a>
                                    <Avatar
                                        size="medium"
                                        url={photoURL}
                                        // emoji={userData[0].userAvatarEmoji}
                                        // backgroundColor={userData[0].userAvatarBackground}
                                        onClick={navigateToProfile}
                                    />
                                </div>
                                // <img src={photoURL} style={{ width: 40, height: 40 }} />

                                :
                                <div className="Auth-buttons-nav-container">
                                    <li className="Main-nav-tab"><a href="/about">About</a></li>
                                    {/* <li className="Main-nav-tab"><a href="/pricing">Pricing</a></li> */}
                                    <Button name="Sign in" appearance="default" onClick={handleLogin} />
                                    <Button name="Sign up" appearance="primary" onClick={navigateToRegister} />
                                </div>

                        }
                    </li>
                </ul>
            </nav>
        </>
    )
}


export default MainNavigation