import React, { useState, useEffect } from 'react'
import LoadingPage from './LoadingPage';
import Button from '../components/Button.tsx';
import { AuthErrorCodes, createUserWithEmailAndPassword, onAuthStateChanged, signInWithPopup, browserPopupRedirectResolver, } from 'firebase/auth'
import { auth, provider } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { db } from "../firebase-config"
import { useAuthValue } from '../context/AuthContext';
import {
    doc,
    setDoc,
} from "firebase/firestore"


const Register = () => {
    const [loading, setLoading] = useState(true)
    setTimeout(() => setLoading(false), 500);

    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerError, setRegisterError] = useState(false);
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerErrorMessage, setRegisterErrorMessage] = useState("")


    const navigate = useNavigate();
    // const history = useHistory();

    const { setTimeActive } = useAuthValue()

    const [user, setUser] = useState({
        username: "",
        email: "",
        password: "",
        userAvatarEmoji: "",
        userAvatarBackground: "",
        userAvatar: "",
        allergies: [],
        cuisines: [],
        diets: [],
        dislikes: [],
        cookingGoals: [],
        cookingLevel: [],
        recipes: [{
            title: "",
            description: "",
            imageUrl: "",
            date: "",
            cost: "",
            cuisines: [],
            difficulty: "",
            time: "",
            servings: "",
            ratings: "",
            ingredients: [{
                name: "",
                quantity: "",
                unit: ""
            }],
            steps: [{
                step: "",
                stepImageUrl: "",
            }]
        }]
    })

    const foodEmojis = ["🥚", "🍟", "🌶", "🍊", "🧀", "🍔", "🐟", "🍣", "🍱", "🍜", "🌮", "🥗", "🍕", "🥦", "🍆", "🍑"]
    const randomEmoji = foodEmojis[Math.floor(Math.random() * foodEmojis.length)]

    const backgroundColour = ["#FFF3EB", "#FCDCDB", "#FFCAD1", "#FFD195", "#FFF0CD", "#D3FFDA", "#DAEFFF", "#BED0FF", "#E2C7BB", "#F9F9F9"]
    const randomBackgroundColour = backgroundColour[Math.floor(Math.random() * backgroundColour.length)]


    const setUpPreferences = async (result) => {
        try {
            console.log(result.user);
            const ref = doc(db, "users", result.user.uid);
            const docRef = await setDoc(ref, {
                userId: result.user.uid,
                username: registerUsername,
                email: result.user.email,
                password: "",
                userAvatar: "",
                userAvatarEmoji: randomEmoji,
                userAvatarBackground: randomBackgroundColour,
                allergies: [{
                    icon: "✅",
                    id: "1",
                    name: "None"
                }],
                cuisines: [{
                    icon: "✅",
                    id: "1",
                    name: "None"
                }],
                diets: [{
                    icon: "✅",
                    id: "1",
                    name: "None"
                }],
                dislikes: [{
                    icon: "✅",
                    id: "1",
                    name: "None"
                }],
                cookingGoals: [{
                    icon: "✅",
                    id: "1",
                    name: "None"
                }],
                cookingLevel: [{
                    icon: "⏲",
                    id: "2",
                    name: "Beginner"
                }],
                // skill: "none"    
                recipes: {
                    title: "",
                    description: "",
                    imageUrl: "",
                    date: "",
                    cost: "",
                    cuisines: [],
                    difficulty: "",
                    time: "",
                    servings: "",
                    ratings: "",
                    ingredients: [{
                        name: "",
                        quantity: "",
                        unit: ""
                    }],
                    steps: [{
                        step: "",
                        stepImageUrl: "",
                    }]
                }
            })
                .then((e) => {
                    console.log("Yes the data has been entered")
                })
                .catch((e) => {
                    console.log(e.message)
                })
        }
        catch (error) {
            if (error.code == "auth/email-already-in-use") {
                console.log("email is already in use try another email");
                setRegisterErrorMessage("email is already in use try another email")
            }
            else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
                console.log("Password must be at least 6 characters")
                setRegisterErrorMessage("Password must be at least 6 characters")
            }
            else {
                console.log(error.message)
                setRegisterErrorMessage("Something is wrong. Please try again later")
            }
        }
    }

    const handleRegister = async () => {
        try {
            navigate('/onboarding');
            const result = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword, registerUsername);
            setUpPreferences(result);
        }
        catch (error) {
            setRegisterError(true);
            console.log(error.message);
            setRegisterErrorMessage(error.message)
        }
    }

    
    

    const handleGoogleSignIn = async () => {
        try {
            navigate('/app-home');
            const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
            
            setUpPreferences(result);
            console.log(result)
            console.log("Successfully logged in to your account!")
            const name = result.user.displayName;
            const email = result.user.email;
            const profilePicture = result.user.photoURL;

            localStorage.setItem("name", name)
            localStorage.setItem("email", email)
            localStorage.setItem("profilePicture", profilePicture)
        }
        catch (error) {
            setRegisterError(true);
            console.log(error.message);
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) { navigate('/onboarding'); }
            console.log("Registered")
        });
    }, []);





    return (
        <div>
            {loading ? <LoadingPage /> :
                <div className="Auth-container">
                    <img src={require("../images/small-logo.png")} className="Logo-big" alt="Logo" />
                    <br></br>
                    <h3>Create your account</h3>

                    <form onSubmit={handleRegister}>
                        <input
                            type="email"
                            placeholder="Email"
                            className="Form-input"
                            onChange={(event) => { setRegisterEmail(event.target.value) }}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            className="Form-input"
                            onChange={(event) => { setRegisterPassword(event.target.value) }}
                        />
                        <input
                            type="text"
                            id="register-name"
                            placeholder="Your name"
                            className="Form-input"
                            onChange={(event) => { setRegisterUsername(event.target.value) }}
                        />
                        <Button name="Sign up" appearance="primary" />
                        <small>OR</small>
                        <Button
                            iconBefore={<img src={require("../images/google-icon.png")} alt="Google icon" style={{ width: 16, height: 16 }} />}
                            name="Sign up with Google" appearance='default' onClick={handleGoogleSignIn}
                        />
                        {/* {registerError && <span class="Input-error">Password must be more than 6 characters</span>} */}
                        <small>Already have an account? <a href="/login">Sign in</a></small>
                        <br></br>
                        <small>By signing up, you agree to the <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy policy</a></small>

                    </form>
                    {registerErrorMessage}
                    <div className='Image-bottom-left-corner'>
                        <img src={require("../images/3d_illos_pancake.png")} alt="illustration" />
                    </div>
                    <div className='Image-bottom-right-corner'>
                        <img src={require("../images/3d_illos_sushi.png")} alt="illustration" />
                    </div>
                </div>
            }
        </div>
    )
}

export default Register