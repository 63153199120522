import React, {useState} from 'react'
import Button from './Button.tsx'
import {Plus} from 'phosphor-react'
import { Popover } from 'antd'
import GenerateButton from './GenerateButton'
import ContextualCreateDialog from './ContextualCreateDialog'


const ContextualCreateRecipe = (props) => {
    const [showContextualCreate, setShowContextualCreate] = useState(false)

    const handleOpenContextualCreate = (newOpen) => {
        setShowContextualCreate(newOpen)
    }

    const handleCloseContextualCreate = () => {
        setShowContextualCreate(false)
    }

    const contextualCreateDialog = (
        <ContextualCreateDialog
            onCancel={handleCloseContextualCreate}
            recipeNameValue={props.recipeNameValue}
            recipeNameOnChange={props.recipeNameOnChange}
            recipeDateValue={props.recipeDateValue}
            recipeDateOnChange={props.recipeDateOnChange}
            handleCreate={props.handleCreate}
            onGenerate={props.onGenerate}
            generateText={props.generateText}
            generateAppearance={props.generateAppearance}
            onMore={props.onMore}
        />
    )

    return (
        <div className="Calendar-add-container">
            <Popover 
                content={contextualCreateDialog}
                trigger="click"
                placement="bottom"
                open={showContextualCreate}
                onOpenChange={handleOpenContextualCreate}
            >
                <Button
                    iconBefore={<Plus size={12} />}
                    onClick={props.onClick}
                    appearance="icon"
                />
            </Popover>
        </div>
    )
}

export default ContextualCreateRecipe