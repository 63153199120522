import React, { useState } from 'react'
import Button from './Button.tsx'
import { Rate } from 'antd'
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';


const FeedbackCollector = (props) => {

    const customIcons = {
        1: <FrownOutlined/>,
        2: <FrownOutlined />,
        3: <MehOutlined />,
        4: <SmileOutlined />,
        5: <SmileOutlined />,
      };


    return (
        <div className='Feedback-container'>
            <h3 style={{textAlign: 'center'}}>How was your experience?</h3>
            <div className="Form Feedback-container-form">
                <Rate 
                    defaultValue={0} 
                    // character={({ index }) => customIcons[index + 1]} 
                    style={{ fontSize: 32 }}
                    onChange={(event) => props.feedbackRatingOnChange(event)}
                />
                <br></br>
                <textarea
                    autoFocus
                    placeholder='Let us know any feedback, thoughts, and suggestions'
                    onChange={(event) =>  props.feedbackDetailOnChange(event)}
                />
                <input 
                    className="Form-input" 
                    type="email" 
                    placeholder="Your email"
                    value={props.feedbackEmailValue}
                    onChange={(event) => props.feedbackEmailOnChange(event)}
                />
                <div className="Button-group">
                    <Button name="Cancel" appearance="default Button-full" onClick={props.onCancel}/>
                    <Button name="Submit" appearance="primary Button-full" onClick={props.onSubmit}/>
                </div>
            </div>
        </div>
    )
}

export default FeedbackCollector