import React, { FC } from 'react';
import Twemoji from 'react-twemoji';

interface Props {
  size: string;
  backgroundColor: string;
  onClick: () => void;
  emoji: string;
  url: string;
}

const Avatar: FC<Props> = ({ size, backgroundColor, onClick, emoji, url }) => {

  return (
    <div className={`Avatar Avatar-${size}`}
      style={{ backgroundColor }}
      onClick={onClick}
    >
      
        {url ? <div className='Avatar-photo'><img src={url} alt="avatar"/></div> : null}
       
      
      <div className='Avatar-emoji'>
        <Twemoji options={{ className: 'twemoji' }}>{emoji}</Twemoji>
      </div>
    </div>
  );
};

export default Avatar;
