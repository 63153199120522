import React from 'react';
import { Modal } from 'antd';
import { useTheme } from '../styles/ThemeContext';
import Button from './Button.tsx'
import Spacer from './Spacer.js';

const DeleteModal = ({ open, handleOk, handleCancel }) => {
    const { colors } = useTheme();
    return (
        <Modal
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Delete"
            cancelText="Cancel"
            footer={null}
            width={400}
        >
            <div className='Modal-container'>
                <Spacer size="lg" />
                <h2 style={{ color: colors.text }}>⛔️ Delete recipe?</h2>
                <p style={{ color: colors.text, textAlign: 'center' }}>Are you sure you want to delete this recipe? This cannot be undone</p>
                <Spacer size="md" />
                <div className='Button-group' style={{ width: '100%' }}>

                    <Button
                        name="Cancel"
                        appearance="default Button-full"
                        onClick={handleCancel}
                    />
                    <Button
                        name="Delete"
                        appearance="primary Button-full"
                        onClick={handleOk}
                        style={{ background: colors.R800 }}
                    />
                </div>
            </div>

        </Modal>
    );
};

export default DeleteModal;