import { collection, getDocs, query, where } from 'firebase/firestore';

import { db } from '../firebase-config';

export const countUserRecipes = async userId => {
  const recipesCollectionRef = collection(db, 'recipes');
  const userRecipesQuery = query(
    recipesCollectionRef,
    where('userId', '==', userId)
  );
  const userRecipesSnapshot = await getDocs(userRecipesQuery);
  return userRecipesSnapshot.docs.length;
};
