import React, { FC, KeyboardEvent, MouseEvent, ReactNode } from "react";
import { useTheme } from "../styles/ThemeContext";

interface ButtonProps {
  appearance: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  iconBefore?: ReactNode;
  name: string;
  iconAfter?: ReactNode;
  disabled: boolean;
}

const Button: FC<ButtonProps> = ({
  appearance,
  onClick,
  onKeyDown,
  type,
  style,
  iconBefore,
  name,
  iconAfter,
  disabled,
}: ButtonProps) => {
  const {colors} = useTheme();
  return (
    <button
      className={`Button-${appearance}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      type={type}
      style={{...style}}
      disabled={disabled}
    >
      {iconBefore}
      {name}
      {iconAfter}
    </button>
  );
};

export default Button;
