import React from 'react'
import Button from './Button.tsx'
import { Sparkle, X } from 'phosphor-react'

const GenerateButton = (props) => {
  return (
    // <div className='Button-generate-border'>
        <Button
            appearance={props.generateAppearance}
            name={props.generateText}
            iconBefore={props.generateIcon}
            iconAfter={props.onCancel ? <X/> : null}
            onClick={props.onClick}
            onCancel={props.onCancel}
        />
    // </div>
  )
}

export default GenerateButton