import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAb0WoTNyWqlV2fluoipNPQrJcdrXZ2SQo",
    authDomain: "menuboard-2528d.firebaseapp.com",
    projectId: "menuboard-2528d",
    storageBucket: "menuboard-2528ds.appspot.com",
    messagingSenderId: "470937298328",
    appId: "1:470937298328:web:1e53386638451f0c65b7ed",
    measurementId: "G-38S7D1XJHN"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();


export const auth = getAuth(app)


export {db, storage, provider}

export default app

// STORAGE
export async function upload(file, currentUser, setLoading){
  const fileRef = ref(storage, 'images/' + currentUser.uid + '.jpg');
  setLoading(true);
  const snapshot = await uploadBytes(fileRef, file);
  setLoading(false);
  alert('Uploaded file')
}
