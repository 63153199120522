import React, { useState, useEffect } from 'react'
import { Modal, Tag } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import Moment from "react-moment";
import Button from './Button.tsx';
import { ArrowSquareOut, CalendarPlus, Clock, CookingPot, CurrencyCircleDollar, Knife, Pencil, ShoppingCart, Trash, Users, Heart, Export, Copy } from 'phosphor-react';
import parse from 'html-react-parser';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import Avatar from "./Avatar.tsx";
import Footer from './Footer.js';
import { ReactComponent as HeartFilled } from '../images/Heart-filled.svg';

const RecipeModal = ({recipe, description, imageUrl, name, authorPhoto, author, linkSource, recipeDateValue, recipeDateOnChange, difficulty, cookTime, cuisine, diets, type, cost, ingredientsEditorContent, stepsEditorContent, onCopyLink, onClick, onSmartIngredients, onEdit, onDelete, onOk, onCancel, handleSaveRecipe, handleUnsaveRecipe, savedByCurrentUser, }) => {


    const [imageLoaded, setImageLoaded] = useState(false)


    const [showRecipeModal, setShowRecipeModal] = useState(true)

    let typeColor;

    switch (type) {
        case "Breakfast":
            typeColor = "green";
            break;
        case "Lunch":
            typeColor = "gold";
            break;
        case "Dinner":
            typeColor = "volcano";
            break;
        case "Snack":
            typeColor = "cyan";
            break;
        case "Dessert":
            typeColor = "magenta";
            break;
        case "Others":
            typeColor = "geekblue";
            break;
        case "Eat out":
            typeColor = "purple";
            break;
        case "Takeaway":
            typeColor = "lime";
            break;
        case "Side dish":
            typeColor = "cyan";
            break;
        default:
            typeColor = "green";
    }

    const navigate = useNavigate();

    const handleNavigateToHome = () => {
        navigate('/')
    }

    const handleOpenRecipeDetail = () => {
        navigate(`/recipe-detail?recipeId=${recipe.id}`, { state: { recipe } });
        // console.log("This is the", recipe)
      };


    // GET USER PROFILE DATA
    const auth = getAuth();
    const user = auth.currentUser;
    const [photoURL, setPhotoURL] = useState("https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg")
    const [username, setUsername] = useState("")

    useEffect(() => {
        if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            const displayName = user.displayName;
            const photoURL = user.photoURL;
            const uid = user.uid;

            setPhotoURL(photoURL)
            setUsername(displayName)
        }

    }, [user])

    // const [isSaved, setIsSaved] = useState(recipe.savedByCurrentUser);


    // const handleSaveClick = async () => {
    //     if (isSaved) {
    //       await handleUnsaveRecipe(recipe);
    //     } else {
    //       await handleSaveRecipe(recipe);
    //     }
    //     setIsSaved(!isSaved);
    //   };


    return (
        <div>
            {showRecipeModal ?
                <Modal
                    open={showRecipeModal}
                    onOk={onOk}
                    onCancel={onCancel}
                    width={880}
                    footer={null}
                    style={{ top: 40 }}
                >
                    <div className="Modal-content-wrapper">
                        {/* {imageUrl ? 
                    <img src={imageUrl} alt="Recipe cover" className="Recipe-cover"/>
                    :
                    <img src={require("../images/food-illos.png")} alt="Recipe thumbnail" className="Recipe-cover" onClick={onClick}/>
                } */}
                        <div className="Modal-header-container">
                            <div>
                                <Tag color={typeColor}>{type ? type : "none"}</Tag>

                            </div>
                            <div className='flex-grow'>

                            </div>
                            <div className='Button-group'>
                            {/* <Link to={`/recipe-detail?recipeId=${recipe.id}`}> */}
                                {/* <Button
                                    appearance="icon"
                                    iconBefore={<ArrowSquareOut/>}
                                    style={{ padding: 0 }}
                                onClick={(recipe) => handleOpenRecipeDetail(recipe)}
                                /> */}
                            {/* </Link> */}
                                {/* {isSaved
                                    ?
                                    <Button
                                        appearance="icon"
                                        iconBefore={<HeartFilled style={{ transform: 'scale(0.5)', fill: "#dc2626" }} />}
                                        onClick={handleSaveClick}
                                        style={{ padding: 0 }}
                                    />
                                    :
                                    <Button
                                        appearance="icon"
                                        iconBefore={<Heart />}
                                        onClick={handleSaveClick}

                                    />
                                } */}
                                {onCopyLink ?
                                    <Button
                                        onClick={onCopyLink}
                                        onKeyDown={onCopyLink}
                                        role="button"
                                        tabIndex={0}
                                        appearance="icon"
                                        iconBefore={<Copy />}
                                    />
                                    :
                                    null
                                }
                                {onSmartIngredients ? 
                                    <Button
                                        onClick={onSmartIngredients}
                                        onKeyDown={onSmartIngredients}
                                        role="button"
                                        tabIndex={0}
                                        appearance="icon"
                                        iconBefore={<ShoppingCart />}
                                    />
                                    :
                                    null
                                }
                                {onEdit ?
                                    <Button
                                        onClick={onEdit}
                                        onKeyDown={onEdit}
                                        role="button"
                                        tabIndex={0}
                                        appearance="icon"
                                        iconBefore={<Pencil />}
                                    />
                                    :
                                    null
                                }
                                {onDelete ? 
                                    <Button
                                        onKeyDown={onDelete}
                                        onClick={onDelete}
                                        role="button"
                                        tabIndex={0}
                                        appearance="icon"
                                        iconBefore={<Trash />}
                                    />
                                    :
                                    null
                                }
                                
                                
                            </div>
                        </div>
                        {imageUrl ?
                            <img src={imageUrl} alt="Recipe cover" className="Recipe-cover" />
                            :
                            <img src={require("../images/food-illos.png")} alt="Recipe thumbnail" className="Recipe-cover" onClick={onClick} />
                        }
                        <div className="Modal-content-container">
                            <br></br>
                            <div className='flex Center' style={{ flexDirection: "column" }}>
                                <h2 className='flex-grow Text-center'>{name}</h2>

                                <div className='flex' style={{ alignItems: "center", marginBottom: 8 }}>

                                    
                                        {authorPhoto ? <Avatar size="small" url={authorPhoto} /> : null}
                                        {author ? <small>{author}</small> : null}
                                  
                                    <small>
                                        {linkSource ?
                                            <>&nbsp; based on <a href={linkSource} rel="noreferrer" target="_blank"><ArrowSquareOut /> source</a> </>
                                            : null
                                        }
                                    </small>
                                </div>
                                <div className='flex' style={{ alignItems: "center" }}>
                                    <input
                                        type="date"
                                        value={recipeDateValue}
                                        onChange={recipeDateOnChange}
                                    />
                                </div>

                                <br></br>
                                <div className='Recipe-metadata-container'>
                                    {difficulty ?
                                        <div className='Recipe-metadata'>
                                            <Knife size={16} />
                                            <small> Difficulty: {difficulty}</small>
                                        </div>
                                        : null
                                    }

                                    {cookTime ?
                                        <div className='Recipe-metadata'>
                                            <Clock size={16} />
                                            <small> {cookTime}</small>
                                        </div>
                                        : null
                                    }
                                    {cuisine ?
                                        <div className='Recipe-metadata'>
                                            <CookingPot size={16} />
                                            <small> {cuisine}</small>
                                        </div>
                                        : null
                                    }
                                    {diets
                                        ?
                                        <div className='Recipe-metadata'>
                                            <Users size={16} />
                                            <small>{diets}</small>
                                        </div>
                                        :
                                        null
                                    }
                                    {cost
                                        ?
                                        <div className='Recipe-metadata'>
                                            <CurrencyCircleDollar size={16} />
                                            <small>${cost} / servings</small>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>

                            <br></br>
                            <br></br>

                            {description ?
                                <div onClick={onEdit}>
                                    <div className='Editor-content'>
                                        {parse(description)}
                                    </div>
                                    <br></br>
                                    <br></br>
                                </div>
                                :
                                null
                            }



                            {ingredientsEditorContent ?
                                <div>
                                    <h3 style={{ marginBottom: 8 }}>Ingredients</h3>
                                    <div className='Editor-content'>
                                        {parse(ingredientsEditorContent)}
                                    </div>
                                    <br></br>
                                    <br></br>
                                </div>
                                :
                                null
                            }



                            {stepsEditorContent ?
                                <div>
                                    <h3 style={{ marginBottom: 8 }}>Steps</h3>
                                    <div className='Editor-content'>
                                        {parse(stepsEditorContent)}
                                    </div>
                                    <br></br>
                                </div>
                                :
                                null
                            }


                            {/* {ingredients 
                        ? 
                        <>
                            <h3 style={{marginBottom: 8}}>Ingredients</h3>
                            {ingredients}
                        </>
                        : 
                        null
                    } */}



                            {/* <br></br>

                    {steps ? 
                        <>
                            <h3 style={{marginBottom: 8}}>Steps</h3>
                            {steps}
                        </>
                        :
                        null
                    } */}


                            <Footer />
                        </div>
                    </div>
                </Modal>
                : null}
        </div>
    )
}

export default RecipeModal