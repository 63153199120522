import React, {useState, useEffect} from 'react'
import Personalisation from '../components/Personalisation'
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import {db, auth} from "../firebase-config"
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import OnboardingModal from '../components/OnboardingModal';
import Twemoji from 'react-twemoji';

const Onboarding = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState(undefined);


    useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
            setUser(currentUser);
        }
        else {
        navigate("/");
        }
    });

    }, [])


    // Get user data
    const usersCollectionRef = collection(db, "users");
    const userId = auth.currentUser && auth.currentUser.uid;
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (!userId) {
          return
        }
    
        const q = query(usersCollectionRef, where("userId", "==", userId))
        onSnapshot(q, (snapshot) => {
          setUserData(snapshot.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            }
          }))
        })    

    }, [userId])


    const handleDone = () => {
        navigate('/app-home')
    }

    const handleSkip = () => {
        navigate('/app-home')
    }


    const [showGreetings, setShowGreetings] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowGreetings(true);
        }, 1000); // change this value to adjust the delay before the animation starts

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [showTutorialGreetings, setShowTutorialGreetings] = useState(false)


    useEffect(() => {
        const tutorialGreetingsTimer = setTimeout(() => {
            setShowGreetings(false);
            setShowTutorialGreetings(true);
        }, 2500); // change this value to adjust the delay before the animation starts
    
        return () => {
          clearTimeout(tutorialGreetingsTimer);
        };
      }, []);

    const [showTutorial, setShowTutorial] = useState(false)

    useEffect(() => {
        const tutorialTimer = setTimeout(() => {
            setShowTutorialGreetings(false);
            setShowTutorial(true);
        }, 4500); // change this value to adjust the delay before the animation starts
    
        return () => {
          clearTimeout(tutorialTimer);
        };
      }, []);



  return (
        <div className='Focused-task-container'>
            {/* <Personalisation/> */}
            {showGreetings && (
                <div className="Fade-in">
                    <div className='Center' style={{flexDirection: 'column', gap: 16}}>
                        <img src={require("../images/small-logo.png")} className="Logo-big" alt="Logo"/>
                    
                        <h2>Welcome to Bentofy!</h2>
                    </div>
                </div>
            )}
            {showTutorialGreetings && (
                <div className="Fade-in">
                    <div className='Center' style={{flexDirection: 'column', gap: 16}}>
                        <Twemoji options={{className: "Twemoji"}}>🍳</Twemoji>
                        <h2>Let&apos;s get cookin&apos;!</h2>
                    </div>
                </div>
            )}
            {showTutorial && (
                <div className='Fade-in'>
                    <OnboardingModal
                        handleDone={handleDone}
                        destination='/app-home'
                    />
                </div>
            )}
            
            {/* <Button appearance="subtle" name="Skip tutorial" onClick={handleSkip}/>  */}
        </div>
  )
}

export default Onboarding