import { onAuthStateChanged } from 'firebase/auth';
import React, {useContext, useEffect, useState} from 'react'
import {auth} from '../firebase-config';

const AuthContext = React.createContext()

export function AuthProvider({children, value}) {
    
    const [currentUser, setCurrentUser] = useState();
    useEffect(() => {
      
        onAuthStateChanged(auth, (user) => {
            if(user){
                setCurrentUser(user);
                console.log(user.uid)
            }
            else{
                console.log("no user available")
            }
        })
    }, [])
    
    
    return (
        <AuthContext.Provider value={value}>
        {children}
        </AuthContext.Provider>
    )
}

export function useAuthValue(){
  return useContext(AuthContext)
}