import React, {FC} from 'react'
import Twemoji from 'react-twemoji'
import Checkbox from './Checkbox.tsx'


interface BubbleProps {
  onChange: () => void;
  onClick: () => void;
  checked: boolean;
  id: string;
  icon: string;
  name: string;
}

const Bubble: FC<BubbleProps> = ({onChange, onClick, checked, id, icon, name}) => {
  return (
    <li 
      className={checked ? 'Bubble-option-active' : 'Bubble-option'} 
      onClick={onClick}
      key={id}
    >
        <Checkbox
          checked={checked}
          onChange={onChange}
        />
        <Twemoji options={{className: 'Twemoji-small'}}>
            <p>{icon}</p>
        </Twemoji>
        
        <p>{name}</p>
    </li>
  )
}

export default Bubble