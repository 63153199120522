import { CheckCircle, Medal } from 'phosphor-react'
import React, { useState } from 'react'
import Button from './Button.tsx'
import { useNavigate } from 'react-router-dom';
import ListItem from './ListItem.js'
import { db } from "../firebase-config.js"
import {
    collection,
    doc,
    addDoc,
    updateDoc,
    deleteDoc,
    onSnapshot,
    query,
    where,
} from "firebase/firestore";
import { auth } from '../firebase-config.js';
import cuisines from '../data/cuisines.json';
import Bubble from './Bubble.tsx';
import allergies from '../data/allergies.json'
import diets from '../data/diets.json';
import dislikes from '../data/dislikes.json';
import cookingLevel from '../data/cookingLevel.json';
import cookingGoals from '../data/cookingGoals.json';


const TutorialModal = ({ props, destination }) => {
    const [tutorialStep, setTutorialStep] = useState(1);

    const handleNext = () => {
        setTutorialStep(tutorialStep + 1);
    }

    const handlePrev = () => {
        setTutorialStep(prevStep => prevStep > 1 ? prevStep - 1 : 1);
    }

    const userId = auth.currentUser && auth.currentUser.uid;
    const userPreferencesRef = doc(db, "users", userId);

    // CUISINES
    const [selectedCuisines, setSelectedCuisines] = useState([]);
    const handleCuisineSelection = (cuisine) => {
        const isSelected = selectedCuisines.includes(cuisine.name);
      
        if (isSelected) {
          setSelectedCuisines(selectedCuisines.filter((item) => item !== cuisine.name));
        } else {
          setSelectedCuisines([...selectedCuisines, cuisine.name]);
        }
      };

    const handleSaveCuisine = async () => {
        try {
            updateDoc(userPreferencesRef, {
                cuisines: selectedCuisines
            }, {merge: true})
            handleNext();
            console.log("Cuisine preferences saved successfully!");
        }

        catch (error) {
            console.log("Error saving preferences: ", error);
        }
    }

    // ALLERGIES
    const [selectedAllergies, setSelectedAllergies] = useState([]);
    const handleAllergiesSelection = (allergy) => {
        const isSelected = selectedAllergies.includes(allergy.name);
      
        if (isSelected) {
            setSelectedAllergies(selectedAllergies.filter((item) => item !== allergy.name));
        } else {
            setSelectedAllergies([...selectedAllergies, allergy.name]);
        }
      };

    const handleSaveAllergies = async () => {
        try {
            updateDoc(userPreferencesRef, {
                allergies: selectedAllergies
            }, {merge: true})
            handleNext();
            console.log("Allergies preferences saved successfully!");
        }

        catch (error) {
            console.log("Error saving preferences: ", error);
        }
    }

    // DIETS
    const [selectedDiets, setSelectedDiets] = useState([]);
    const handleDietsSelection = (diet) => {
        const isSelected = selectedDiets.includes(diet.name);
      
        if (isSelected) {
            setSelectedDiets(selectedDiets.filter((item) => item !== diet.name));
        }
        else {
            setSelectedDiets([...selectedDiets, diet.name]);
        }
    };

    const handleSaveDiet = async () => {
        try {
            updateDoc(userPreferencesRef, {
                diets: selectedDiets
            }, { merge: true })
            handleNext();
            console.log("Diet preferences saved successfully!");
        }

        catch (error) {
            console.log("Error saving preferences: ", error);
        }
    }

    // DISLIKES
    const [selectedDislikes, setSelectedDislikes] = useState([]);
    const handleDislikesSelection = (dislike) => {

        const isSelected = selectedDislikes.includes(dislike.name);

        if (isSelected) {
            setSelectedDislikes(selectedDislikes.filter((item) => item !== dislike.name));
        }
        else {
            setSelectedDislikes([...selectedDislikes, dislike.name]);
        }
    };

    const handleSaveDislikes = async () => {
        try {
            updateDoc(userPreferencesRef, {
                dislikes: selectedDislikes
            }, { merge: true })
            handleNext();
            console.log("Dislike preferences saved successfully!");
        }

        catch (error) {
            console.log("Error saving preferences: ", error);
        }
    }

    // COOKING LEVEL
    const [selectedCookingLevel, setSelectedCookingLevel] = useState([]);
    const handleCookingLevelSelection = (level) => {

        const isSelected = selectedCookingLevel.includes(level.name);

        if (isSelected) {
            setSelectedCookingLevel(selectedCookingLevel.filter((item) => item !== level.name));
        }
        else {
            setSelectedCookingLevel([...selectedCookingLevel, level.name])
        }
    }

    const handleSaveCookingLevel = async () => {
        try {
            updateDoc(userPreferencesRef, {
                cookingLevel: selectedCookingLevel
            }, { merge: true })
            handleNext();
            console.log("Cooking level preferences saved successfully!");
        }
        catch (error) {
            console.log("Error saving preferences: ", error);
        }
    }

    // COOKING GOALS
    const [selectedCookingGoals, setSelectedCookingGoals] = useState([])
    const handleCookingGoalsSelection = (goal) => {

        const isSelected = selectedCookingGoals.includes(goal.name);

        if (isSelected) {
            setSelectedCookingGoals(selectedCookingGoals.filter((item) => item !== goal.name));
        }
        else {
            setSelectedCookingGoals([...selectedCookingGoals, goal.name])
        }
    }

    const navigate = useNavigate();

    const handleDone = () => {
        navigate(destination);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const handleSaveCookingGoals = async () => {
        try {
            updateDoc(userPreferencesRef, {
                cookingGoals: selectedCookingGoals
            }, { merge: true })
            // handleDone();
            handleNext();
            console.log("Cooking goal preferences saved successfully!");
        }
        catch (error) {
            console.log("Error saving preferences: ", error);
        }
    }

    const handleSkip = () => {
        navigate('/app-home')
    }

    return (
        <div className='Onboarding-container'>
            {tutorialStep === 1 && (
                <form className="Section-center" onSubmit={handleSaveCuisine}>
                <h3>What are some of your favourite cuisines? </h3>
                <div className='Bubble-list'>
                    {cuisines.map((cuisine) => (
                        <label key={cuisine.name}>
                            <Bubble
                                type="checkbox"
                                name={cuisine.name}
                                icon={cuisine.icon}
                                value={cuisine.name}
                                checked={selectedCuisines.includes(cuisine.name)}
                                onChange={() => handleCuisineSelection(cuisine)}
                            />
                        </label>
                    ))}
                </div>
                <br></br>
                <div className='Button-group'>
                    <Button name="Save" appearance="primary" type="submit" />
                </div>
            </form>
            )}
            {
                tutorialStep === 2 && (
                    <div className="Section-center">
                        <h3>Do you have any food allergies?</h3>
                        <div className='Bubble-list'>
                            {allergies.map((allergy) => (
                                <label key={allergy.name}>
                                    <Bubble
                                        type="checkbox"
                                        name={allergy.name}
                                        icon={allergy.icon}
                                        value={allergy.name}
                                        checked={selectedAllergies.includes(allergy.name)}
                                        onChange={() => handleAllergiesSelection(allergy)}
                                    />
                                </label>
                            ))}
                        </div>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Back" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleSaveAllergies} />
                        </div>
                    </div>
                )
            }
            {
                tutorialStep === 3 && (
                    <div className="Section-center">
                        <h3>Do you follow any of these diets?</h3>
                        <div className='Bubble-list'>
                            {diets.map((diet) => (
                                <label key={diet.name}>
                                    <Bubble
                                        type="checkbox"
                                        name={diet.name}
                                        icon={diet.icon}
                                        value={diet.name}
                                        checked={selectedDiets.includes(diet.name)}
                                        onChange={() => handleDietsSelection(diet)}
                                    />
                                </label>
                            ))}
                        </div>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Back" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleSaveDiet} />
                        </div>
                    </div>
                )
            }
            {
                tutorialStep === 4 && (
                    <div className="Section-center">
                        <h3>Any ingredients you dont want to see in recommended recipes?</h3>
                        <div className='Bubble-list'>
                            {dislikes.map((dislike) => (
                                <label key={dislike.name}>
                                    <Bubble
                                        type="checkbox"
                                        name={dislike.name}
                                        icon={dislike.icon}
                                        value={dislike.name}
                                        checked={selectedDislikes.includes(dislike.name)}
                                        onChange={() => handleDislikesSelection(dislike)}
                                    />
                                </label>
                            ))}
                        </div>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Back" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleSaveDislikes} />
                        </div>
                    </div>
                )
            }
            {
                tutorialStep === 5 && (
                    <div className="Section-center">
                        <h3>How would you describe your cooking skills?</h3>
                        <div className='Bubble-list'>
                            {cookingLevel.map((level) => (
                                <label key={level.name}>
                                    <Bubble
                                        type="checkbox"
                                        name={level.name}
                                        icon={level.icon}
                                        value={level.name}
                                        checked={selectedCookingLevel.includes(level.name)}
                                        onChange={() => handleCookingLevelSelection(level)}
                                    />
                                </label>
                            ))}
                        </div>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Back" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleSaveCookingLevel} />
                        </div>
                    </div>
                )
            }
            {
                tutorialStep === 6 && (
                    <div className="Section-center">
                        <h3>What are your cooking goals?</h3>
                        <div className='Bubble-list'>
                            {cookingGoals.map((goal) => (
                                <label key={goal.name}>
                                    <Bubble
                                        type="checkbox"
                                        name={goal.name}
                                        icon={goal.icon}
                                        value={goal.name}
                                        checked={selectedCookingGoals.includes(goal.name)}
                                        onChange={() => handleCookingGoalsSelection(goal)}
                                    />
                                </label>
                            ))}
                        </div>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Back" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleSaveCookingGoals} />
                        </div>
                    </div>
                )
            }
            {tutorialStep === 7 && (
                <div className='Onboarding-content'>
                    <img src={require("../images/product-shot_schedule.jpeg")} alt="pro illustration" className='Onboarding-image' />
                    <div className='Modal-container' style={{ textAlign: "center" }}>
                        <h2>Schedule your meals</h2>
                        <p>Tap the day when you would like to add your meal. <br></br>Drag and drop to move the scheduled meals to the desired day.</p>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Back" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleNext} />
                        </div>
                    </div>

                </div>
            )}
            {tutorialStep === 8 && (
                <div className='Onboarding-content'>
                    <img src={require("../images/product-shot_recipes.jpeg")} alt="pro illustration" className='Onboarding-image' />
                    <div className='Modal-container' style={{ textAlign: "center" }}>
                        <h2>Manage your recipes</h2>
                        <p>Create and tag your recipes to categorise them. <br></br> You can also generate recipes with AI when feeling less inspired.</p>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Previous" appearance="default" onClick={handlePrev} />
                            <Button name="Next" appearance="primary" onClick={handleNext} />
                        </div>
                    </div>
                </div>
            )}
            {tutorialStep === 9 && (
                <div className='Onboarding-content'>
                    <img src={require("../images/product-shot_groceries.jpeg")} alt="pro illustration" className='Onboarding-image' />
                    <div className='Modal-container' style={{ textAlign: "center" }}>
                        <h2>Track your groceries</h2>
                        <p>Create your grocery list and have it on the go.<br></br>Tap the + button next to ingredients in your recipe to add to your list. </p>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Previous" appearance="default" onClick={handlePrev} />
                            <Button name="Done" appearance="primary" onClick={handleDone} />
                        </div>
                    </div>
                </div>
            )}
            {/* {tutorialStep === 10 && (
                <div className='Onboarding-content'>
                    <img src={require("../images/product-shot_preferences.jpg")} alt="pro illustration" className='Onboarding-image' />
                    <div className='Modal-container' style={{ textAlign: "center" }}>
                        <h2>Get personalised recommendation</h2>
                        <p>Keep your taste and preferences up to date to get the best recommendations. <br></br>Add your family members to help juggle your meals better </p>
                        <br></br>
                        <div className='Button-group'>
                            <Button name="Previous" appearance="default" onClick={handlePrev} />
                            <Button name="Done" appearance="primary" onClick={props.handleDone} />
                        </div>
                    </div>
                </div>
            )} */}
            <div className='Center'>
                <small>{tutorialStep} of 9</small>
            </div>
            <br></br>
            <br></br>
            <div className='Center'>
                <a href="/app-home" onClick={handleSkip}><small>Skip tutorial</small></a>
            </div>


        </div>

    )
}

export default TutorialModal