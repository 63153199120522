import { useState, useEffect } from 'react';
import { auth } from '../firebase-config';
import { db } from "../firebase-config";
import { getAuth } from "firebase/auth";
import {
    collection,
    addDoc,
    query,
    where,
    getDocs,
    setDoc,
    doc,
} from "firebase/firestore"
import { toast } from "react-hot-toast";
import * as moment from 'moment'

export const useRecipeSubmission = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const [userPhotoUrl, setUserPhotoUrl] = useState("https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg")
    const [username, setUsername] = useState("");

    
    useEffect(() => {
        if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            const displayName = user.displayName;
            const photoURL = user.photoURL;
            const uid = user.uid;

            setUserPhotoUrl(photoURL)
            setUsername(displayName)
        }

    }, [user])
    const userId = auth.currentUser && auth.currentUser.uid;
    const recipesCollectionRef = collection(db, "recipes");

    // const [loading, setLoading] = useState(false)
    const dateAdded = new Date().getTime();
    const dateString = moment(dateAdded).format("YYYY-MM-DD  hh:mm:ss");

    const mergeRecipes = async (title) => {
        if(!title){
            return null;
        }

        const existingRecipeQuery = query(recipesCollectionRef, where("title", "==", title), where("userId", "==", userId));
        const querySnapshot = await getDocs(existingRecipeQuery);
        const existingRecipes = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        return existingRecipes.length > 0 ? existingRecipes[0] : null;
    }

    const onRecipeSubmit = async ({
        title,
        description,
        ingredients,
        ingredientsEditorContent,
        steps,
        stepsEditorContent,
        date,
        imageUrl,
        tags,
        type,
        cuisine,
        diets,
        difficulty,
        cookTime,
        cost,
        linkSource,
        author,
        authorPhoto,
        dateAdded,
        saved,
    }) => {

        const existingRecipes = await mergeRecipes(title);

        if(existingRecipes){
            const updatedRecipe = {
                ...existingRecipes, 
                title: title ?? "",
                description: description ?? "",
                ingredients: ingredients ?? [],
                ingredientsEditorContent: ingredientsEditorContent ?? "",
                steps: steps ?? [],
                stepsEditorContent: stepsEditorContent ?? "",
                date: moment(date).format("YYYY-MM-DD") ?? "",
                dateAdded: dateString,
                imageUrl: imageUrl ?? "",
                tags: tags ?? [],
                type: type ?? "",
                cuisine: cuisine ?? "",
                diets: diets ?? "",
                difficulty: difficulty ?? "",
                cookTime: cookTime ?? "",
                cost: cost ?? "",
                linkSource: linkSource ?? "",
                author: username,
                authorPhoto: userPhotoUrl,
                userId: userId,
                saved: saved ?? true,
                count: (existingRecipes.count || 0) + 1
            }

            await setDoc(doc(db, "recipes", existingRecipes.id), updatedRecipe)

            toast.success(`"${updatedRecipe.title} already created`)

        } else {
            
            const newRecipe = {
                title: title ?? "",
                description: description ?? "",
                ingredients: ingredients ?? [],
                ingredientsEditorContent: ingredientsEditorContent ?? "",
                steps: steps ?? [],
                stepsEditorContent: stepsEditorContent ?? "",
                date: moment(date).format("YYYY-MM-DD") ?? "",
                dateAdded: dateString,
                imageUrl: imageUrl ?? "",
                tags: tags ?? [],
                type: type ?? "",
                cuisine: cuisine ?? "",
                diets: diets ?? "",
                difficulty: difficulty ?? "",
                cookTime: cookTime ?? "",
                cost: cost ?? "",
                linkSource: linkSource ?? "",
                author: username ?? "",
                authorPhoto: userPhotoUrl ?? "",                
                userId: userId,
                saved: saved ?? true,
                count: 1,
            };

            await addDoc(recipesCollectionRef, newRecipe);
            toast.success(`😋 "${newRecipe.title}" planned for ${newRecipe.date}`);
        }
    };

    return {
        onRecipeSubmit
        // , loading
    }
}