import React, { useState } from 'react'
import Button from './Button.tsx'
import GenerateButton from './GenerateButton'
import { CalendarBlank, Check, DotsThree, Link, Plus, Sparkle, X } from 'phosphor-react'


const ContextualCreateDialog = (props) => {
  const [showDate, setShowDate] = useState(false);

  const handleShowDate = () => {
    setShowDate(!showDate)
  }

  return (
        <div className='Inline-create-container'>
          <input
            autoFocus={true}
            type="text"
            placeholder='New meal or recipe'
            className='Form-input-subtle'
            value={props.recipeNameValue}
            onChange={props.recipeNameOnChange}
          />
        

          <div className='flex'>
            {/* <Button type="button" appearance="icon" iconBefore={<Sparkle />} onClick={props.onGenerate} />
            <Button type="button" appearance="icon" iconBefore={<CalendarBlank />} onClick={() => handleShowDate()}/>             */}
            <input type="date"
              value={props.recipeDateValue}
              onChange={props.recipeDateOnChange}
            />
            {/* <div className='flex-grow'></div>
             */}
          </div>


          <div className='Button-group'>
            <Button type="button" name="Cancel" appearance="default" onClick={props.onCancel} />
            <Button type="submit" name="Create" appearance="primary"
               onClick={props.handleCreate} 
               onKeyDown={props.handleCreate}
            />
          </div>
          {/* <hr></hr>
          <GenerateButton
            generateAppearance={props.generateAppearance ? props.generateAppearance : "generate"}
            generateText={props.generateText ? props.generateText : "Generate"}
            onClick={props.onGenerate}
          /> */}
        </div>
  )
}

export default ContextualCreateDialog