import React from 'react'
import Twemoji from 'react-twemoji'

const TermsOfService = () => {

    return (
        <div className='Page-container'>
            <div className='Page-xsmall'>
                <br></br>
                <br></br>
                <div className='Page-header'>
                    <div className='Page-title'>
                        <h2 className='flex-grow Text-center'>
                            Terms of Service
                        </h2>
                    </div>
                </div>
                <ol style={{display: "flex", flexDirection: "column", gap: 48}}>
                    <li>
                        <strong>Account Creation and User Responsibilities</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                1.1. To access certain features of the App, you may be required to create an account. You must provide accurate and complete information during the registration process.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                1.2. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                1.3. You agree to use the App in compliance with applicable laws and regulations and refrain from engaging in any unlawful, harmful, or abusive behavior.
                            </li>

                        </ul>
                    </li>
                    <li>
                        <strong>Privacy and Data Collection</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                2.1. We collect and store certain information as described in our <a href="/privacy-policy">Privacy Policy </a>. By using the App, you consent to the collection, use, and storage of your information in accordance with our <a href="/privacy-policy">Privacy Policy </a>.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                2.2 You understand that the App may involve the processing and storage of personal data as outlined in the Privacy Policy.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Intellectual Property Rights</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                3.1. The App and its contents, including but not limited to text, graphics, images, logos, and software, are protected by intellectual property laws and are the property of Bentofy or its licensors.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                3.2. You may not reproduce, modify, distribute, transmit, display, perform, or create derivative works of any portion of the App without our prior written consent.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Limitations of Liability</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                4.1. The App is provided on an &apos;as is&apos; basis, and we make no warranties or representations regarding its availability, functionality, accuracy, or reliability.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                4.2. We shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with your use of the App.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Termination</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                5.1. We reserve the right to suspend or terminate your access to the App at any time, with or without cause, and without prior notice.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                5.2. Upon termination, your right to use the App will cease, and you must immediately uninstall the App and cease all use.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Modifications to the Terms</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                6.1. We may revise these Terms at any time by updating this page. It is your responsibility to review these Terms periodically for any changes.
                            </li>
                            <li style={{listStyleType: "disc"}}>
                                6.2. By continuing to use the App after any revisions to the Terms, you agree to be bound by the updated Terms.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Governing Law and Jurisdiction</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                7.1. These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Contact information</strong>
                        <br></br>
                        <br></br>
                        <ul>
                            <li style={{listStyleType: "disc"}}>
                                8.1. If you have any questions or concerns regarding these Terms or the App, please contact us at hello[at]bentofy.app.
                            </li>
                        </ul>
                    </li>
                </ol>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    )
}

export default TermsOfService