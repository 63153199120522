import React from 'react'

const SkeletonLoader = (props) => {
    const cardCount = props.cardCount;


    const getRandomWidth = () => {
        const min = 40; // Minimum width percentage
        const max = 100; // Maximum width percentage
      
        // Generate a random number between 0 and 1
        const random = Math.random();
      
        // Calculate the width within the specified range
        const width = min + random * (max - min);
      
        return `${width}%`;
      }


    return (
        <div>

            {props.type === "grid" && (
                <div className='Skeleton-loader-grid'>
                    {Array.from({ length: cardCount }, (_, index) => (
                        <div key={index} className='Skeleton-card'>
                            <div className="Skeleton-card-image"></div>
                            <div className="Skeleton-card-content">
                                <div className="Skeleton-card-title"></div>
                                <div className="Skeleton-card-description"></div>
                                <div className='flex'>
                                    <div className="Skeleton-card-button"></div>
                                    <div className="Skeleton-card-button"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {props.type === "list" && (
                <div className='Skeleton-loader-list'>
                    <div className='Skeleton-pills'>
                        {Array.from({length: 12}, (_, index) => (
                            <div className='Skeleton-pill'> </div>
                        ))}
                    </div>
                    {Array.from({ length: cardCount }, (_, index) => {
                        const randomWidth = getRandomWidth();
                        return(
                        <div key={index} className='Skeleton-list'>
                            <div className="Skeleton-card-button"></div>
                            <div className="Skeleton-card-title" style={{width: `${randomWidth}`}}></div>
                        </div>)
                        })}
                </div>
            )}

        </div>
    )
}

export default SkeletonLoader