import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import RecipeCard from '../components/RecipeCard';
import RecipeModal from './RecipeModal';
import IngredientItem from './IngredientItem';
import SearchBar from './SearchBar';
import { X } from 'phosphor-react';
import Button from './Button.tsx';
import { auth } from '../firebase-config';
import { db } from "../firebase-config"
import {
    collection,
    query,
    where,
    onSnapshot,
    deleteDoc,
    doc
} from "firebase/firestore"
import { toast } from 'react-hot-toast'

const UnscheduledSidebar = (props) => {

    const unscheduledRecipeList = useSelector((state) => state.recipe.unscheduledRecipeList)
    
    const [showRecipeModal, setShowRecipeModal] = useState(false)
    const [activeRecipe, setActiveRecipe] = useState([])

    const handleRecipeModalOpen = (recipe) => {
      setShowRecipeModal(true)
      setActiveRecipe(recipe)
    }

    const handleRecipeModalClose = () => {
      setShowRecipeModal(false)
    }

    const [searchTerm, setSearchTerm] = useState("")
    const handleSearch = (event) => {
      setSearchTerm(event.target.value)
    }

    const filteredUnscheduledRecipeList = unscheduledRecipeList.filter((recipe) => 
      recipe.name.toLowerCase().includes(searchTerm.toLowerCase())
    )


    // Show user recipes
    const userId = auth.currentUser && auth.currentUser.uid;
    const [userRecipes, setUserRecipes] = useState([]);
    const recipesCollectionRef = collection(db, "recipes")

    useEffect(() => {
      if (!userId) {
          return
      }

      const q = query(recipesCollectionRef, where("userId", "==", userId));

      const fetchData = onSnapshot(q, (querySnapshot) => {
          setUserRecipes(querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
          })));
      });

      return fetchData;
    }, [userId])

    // Delete recipe
    const removeRecipe = (id) => {
      deleteDoc(doc(db, "recipes", id))
      toast.success(`Recipe successfully deleted`);
    }

    return (
      <>
      {props.visible && 
        <div className='Plan-sidebar'>
            <div className='Page-title'>
              <h3 className="flex-grow">My recipes</h3>
              <Button appearance="icon" iconBefore={<X/>} onClick={props.closeSidebar}/>
            </div>
            <SearchBar placeholder="Search recipes" onChange={handleSearch} appearance="default"/> 
            <ul className='Recipe-card-list'>
                {
                  userRecipes
                  ? 
                  userRecipes.map((recipe, i) => (
                      <RecipeCard
                          key={recipe.id}
                          name={recipe.title}
                          imageUrl={recipe.imageUrl}
                          cost={recipe.cost}
                          time={recipe.time}
                          onClick={() => handleRecipeModalOpen(recipe)}
                          onDelete={() => removeRecipe(recipe.id)}
                          // onEdit={() => showEditModal(recipe)}
                          addToPlan={true}
                      />
                  ))
                  :
                  "No meals found. Add meals from recipe."
                }
            </ul>
            {/* <ul>
              {filteredUnscheduledRecipeList && unscheduledRecipeList.length > 0
              ?
              filteredUnscheduledRecipeList.map((recipe) => {
                  return(
                  <RecipeCard 
                      key={recipe.id}
                      name={recipe.name}
                      imageUrl={recipe.imageUrl}
                      cost={recipe.cost}
                      time={recipe.time}
                      onClick={() => handleRecipeModalOpen(recipe)}
                  />
              )
              })
              :
              "No meals found. Add meals from recipe."
              }
            </ul> */}

            {showRecipeModal && 
              <RecipeModal
                onOk={handleRecipeModalClose}
                onCancel={handleRecipeModalClose}
                name={activeRecipe.name}
                imageUrl={activeRecipe.imageUrl}
                cost={activeRecipe.cost}
                time={activeRecipe.time}
                difficulty={activeRecipe.difficulty}
                cuisine={activeRecipe.cuisine}
                
                // steps={
                //   <ol>
                //       {activeRecipe.steps.map((step, i) => (
                //           <li key={i}>{step}</li>
                //       ))}
                //   </ol>
                // }
              />
            }
        </div>
        }
      </>
  )
}

export default UnscheduledSidebar