import React, { useState } from 'react'
import GroceryListItem from './GroceryListItem'
import Modal from 'antd/es/modal/Modal';
import { useIngredientSubmission } from '../controllers/ingredientSubmission';
import { toast } from 'react-hot-toast';
import Twemoji from 'react-twemoji';
import Button from './Button.tsx';
import { Plus, PlusCircle, ShoppingCart } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';

const SmartIngredientsModal = ({ ingredients, open, onOk, onCancel }) => {
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    const handleIngredientSelect = (ingredient) => {
        setSelectedIngredients((prevSelectedIngredients) => {
            if (prevSelectedIngredients.includes(ingredient)) {
                return prevSelectedIngredients.filter((item) => item !== ingredient);
            } else {
                return [...prevSelectedIngredients, ingredient];
            }
        });
    };

    const { onIngredientSubmit } = useIngredientSubmission()

    const handleAddToGroceryList = (ingredient) => {
        onIngredientSubmit({ title: ingredient.replace(/^-/, '') });

        setSelectedIngredients([...selectedIngredients, ingredient])
        toast.success(`"${ingredient.replace(/^-/, '')}" added to grocery`);
    };

    const handleAddAllToGrocery = () => {
        ingredients.forEach((ingredient) => {
            if (!selectedIngredients.includes(ingredient)) {
                handleAddToGroceryList(ingredient);
            }
        });
    };

    const navigate = useNavigate();

    const navigateToGrocery = () => {
        navigate('/groceries')
    }


    return (
        <Modal
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            width={880}
            footer={null}
            style={{ top: 40 }}
        >
            <div className="Modal-content-wrapper">
                <div className="Modal-content-container">
                    <div className="Page-title"></div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className='flex Center' style={{ flexDirection: "column" }}>
                        <Twemoji className="Twemoji-small">✨</Twemoji>
                        <h2 className='Text-center'> Woah, magic!</h2>
                        <p className='Text-center'>We've made a list of ingredients based on this week's meals so you don't have to 😉 <br></br>Simply tap to add to your grocery list and no worries </p>
                        <br></br>
                        <ul className='Pills'>
                            {ingredients.map((ingredient, index) => (
                                !selectedIngredients.includes(ingredient) && (
                                    <li key={index} className='Pill' onClick={() => handleAddToGroceryList(ingredient)}>
                                        <PlusCircle/>
                                        <p>{ingredient.replace(/^-/, '')}</p>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='Button-group'>
                        <Button appearance='default Button-full' iconBefore={<ShoppingCart />} name="Go to grocery list" onClick={navigateToGrocery} />
                        <Button appearance='primary Button-full' iconBefore={<Plus />} name="Add all to grocery" onClick={handleAddAllToGrocery} />
                    </div>
                    <br></br>
                    <br></br>
                </div>
            </div>

        </Modal>
    )
}

export default SmartIngredientsModal