import { ForkKnife } from 'phosphor-react';
import React from 'react';

import { useTheme } from '../styles/ThemeContext';
import { typography } from '../styles/theme';

const RecipeListItem = ({ recipe, recipeAuthor, onClick, }) => {
    // THEMING
    const { colors } = useTheme();

    const characters = ['R', 'G', 'B', 'Y', 'P', 'O', 'V', 'T'];

    const getRandomCharacter = () => {
        const randomIndex = Math.floor(Math.random() * characters.length);
        return characters[randomIndex];
    };

    const randomCharacter = getRandomCharacter();

    const isValidUrl = string => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(string);
    };

    return (
        <div
            onClick={onClick}
            style={{
                backgroundColor: colors.background,
                overflow: 'hidden',
                margin: 2,
                borderRadius: 12,
                cursor: 'pointer'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 4,
                    paddingLeft: 24,
                    gap: 12,
                    alignItems: 'center',
                }}
            >

                <div style={{ display: 'flex', flexGrow: 1, }}>
                    {isValidUrl(recipe.title) ? (

                        <a href={recipe.title}>
                            <div
                                style={
                                    {
                                        color: colors.div,
                                        divDecorationLine: 'underline',
                                        marginBottom: 8,
                                    }
                                }
                            >
                                {recipe.title}
                            </div>
                        </a>
                    ) : (
                        <h4
                            style={{ color: colors.text }}
                        >
                            {recipe.title}
                        </h4>
                    )}

                    {recipeAuthor && (
                        <small style={{ color: colors.textSubtle }}>
                            {recipeAuthor}
                        </small>
                    )}
                </div>
                {recipe.imageUrl ? (
                    <img
                        style={{ width: 160, height: 80, borderRadius: 8, justifyContent: 'center' }}
                        src={recipe.imageUrl}
                    />
                ) : (
                    <div
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 8,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // backgroundColor: colors[`${randomCharacter}50`],
                            backgroundColor: colors.default,
                        }}
                    >
                        <ForkKnife
                            // color={colors[`${randomCharacter}400`]}
                            color={colors.iconSubtle}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RecipeListItem;
