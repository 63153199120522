import React from 'react'
import { Tag } from 'antd'

const RecipeMiniCard = (props) => {

  let typeColor, color, borderColor;

  switch(props.type) {
    case "Breakfast":
      // GREEN
      typeColor = "#f6ffed";
      color = "#389e0d";
      borderColor = "#b7eb8f";
      break;
    case "Lunch":
      // GOLD
      typeColor = "#fffbe6";
      color = "#d48806";
      borderColor = "#ffe58f";
      break;
    case "Dinner":
      // VOLCANO
      typeColor = "#fff2e8";
      color= "#d4380d";
      borderColor= "#ffbb96";
      break;
    case "Snack":
      // CYAN
      typeColor = "#e6fffb";
      color= "#08979c";
      borderColor= "#87e8de";
      break;
    case "Dessert":
      // MAGENTA
      typeColor = "#fff0f6";
      color= "#c41d7f";
      borderColor= "#ffadd2";
      break;
    case "Others":
      // GEEKBLUE
      typeColor = "#f0f5ff";
      color = "#1d39c4";
      borderColor = "#adc6ff";
      break;
    case "Eat out":
      // PURPLE
      typeColor = "#f9f0ff";
      color = "#531dab";
      borderColor = "#d3adf7";
      break;
    case "Takeaway":
      // LIME
      typeColor = "#fcffe6";
      color = "#7cb305";
      borderColor = "#eaff8f";
      break;
    case "Side dish":
      // CYAN
      typeColor = "#e6fffb";
      color= "#08979c";
      borderColor= "#87e8de";
      break;
    default:
      typeColor = "white";
      color = "#182222";
      borderColor = "rgba(0,0,0,0.16)"
  }

  return (
    <li key={props.id} 
        className="Recipe-mini-card" 
        onClick={props.onClick}
        style={{
            backgroundColor: props.cardColorSmall ? typeColor : "white",
            // border: "1px solid rgba(0,0,0,0.16)",
            // borderColor: borderColor,
            color: "#182222",
            whiteSpace: props.wrap,
            gap: props.gap,
            padding: props.gap
        }}
    >
      <div className='Card-color-container'
        style={{
          flexDirection: props.flexDirection
        }}
      >
        <span className='Card-color'
            style={{
              backgroundColor: color,
              width: props.cardColorSmall ? 6 : 16,
              height: props.cardColorSmall ? 6 : 4,
              margin: props.cardColorSmall ? 2 : "0 0 2px 0",
            }}
          >
        </span>
        <small>{props.name}</small>
          
      </div>
        
        {/* <div>
        {props.type ? <Tag borderless color={typeColor}>{props.type}</Tag> : null}
        </div> */}
        {props.imageUrl ? 
            <img src={props.imageUrl} alt="Recipe thumbnail" className="Recipe-mini-thumbnail" onClick={props.onClick} />
            :
            null
        }     
    </li>
  )
}

export default RecipeMiniCard