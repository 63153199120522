import React, { useState, useEffect } from 'react'
import LoadingPage from './LoadingPage';
import Button from '../components/Button.tsx';
import {signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup, browserPopupRedirectResolver, sendPasswordResetEmail} from 'firebase/auth'
import {auth, provider} from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const Login = () => {

    const [loading, setLoading] = useState(true)
    setTimeout(() => setLoading(false), 500);

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginError, setLoginError] = useState(false);


    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            navigate('/app-home');
            const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
            console.log(user)
            console.log("Successfully logged in to your account!")
            
        }
        catch(error){
            setLoginError(true);
            console.log(error.message);
        }
    }

    const handleGoogleSignIn = async () => {
        try {
            navigate('/app-home');
            const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
            console.log(result)
            console.log("Successfully logged in to your account!")
            const name = result.user.displayName;
            const email = result.user.email;
            const profilePicture = result.user.photoURL;

            localStorage.setItem("name", name)
            localStorage.setItem("email", email)
            localStorage.setItem("profilePicture", profilePicture)
        }
        catch(error){
            setLoginError(true);
            console.log(error.message);
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) 
                {
                    navigate('/app-home');
                }
            console.log("logged in")
        });
      }, []);

    // FORGOT PASSWORD
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("")
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [forgotPasswordError, setForgotPasswordError] = useState(null);

    const handleResetPassword = async (event) => {
        event.preventDefault();

        console.log(forgotPasswordEmail)
        
        try {
            const result = await sendPasswordResetEmail(auth, forgotPasswordEmail);
            setForgotPasswordError(null);
            toast.success(`Reset password link sent to ${result.email}`)
          } catch (err) {
            setForgotPasswordError(err.message);
          }
    }

    return (
        <div>
        {loading ? <LoadingPage/> :
            
            <div className='Auth-container'>
                <img src={require("../images/small-logo.png")} className="Logo-big" alt="Logo"/>
                <br></br>
                {showForgotPassword 
                    ? 
                        <>
                            <h3>Forgot password?</h3>
                            <p className='Text-center'>Enter the email you use for Bentofy <br></br> We'll send you a recovery link to reset your password</p>
                            <br></br>
                            <form>
                                <input 
                                    type="email"
                                    placeholder="Email"
                                    className="Form-input"
                                    value={forgotPasswordEmail}
                                    onChange={(event) => {setForgotPasswordEmail(event.target.value)}}
                                />
                                <Button name="Send reset password link" appearance="primary" onClick={handleResetPassword}/>
                            </form>
                            {/* {forgotPasswordError && <p className="Input-error">Something's wrong. Please try again later.</p>} */}
                            <br></br>
                            <small className="Button-link" onClick={() => setShowForgotPassword(!showForgotPassword)}>Back to sign in</small>
                        </>
                    
                    : 
                    <>
                        <h3>Sign in to your account</h3>
                        <br></br>
                        <form>
                            <input 
                                type="email"
                                placeholder="Email"
                                className="Form-input"
                                onChange={(event) => {setLoginEmail(event.target.value)}}
                            />
                            <input 
                                type="password"
                                placeholder="Password" 
                                className="Form-input"
                                onChange={(event) => {setLoginPassword(event.target.value)}}
                            />
                            <Button name="Sign in" appearance="primary" onClick={handleLogin}/>
                            <small>OR</small>
                            <Button 
                                iconBefore={<img src={require("../images/google-icon.png")} alt="Google icon" style={{width: 16, height: 16}}/>} 
                                name="Continue with Google" appearance='default' onClick={handleGoogleSignIn}
                            />
                            {loginError && <span className="Input-error">Wrong email or password. Try again.</span>}
                            <br></br>
                            <small>Don't have an account? <a href="/register" className="Button-link" >Sign up here</a></small>
                            <small className="Button-link" onClick={() => setShowForgotPassword(!showForgotPassword)}>Forgot password?</small>
                        </form>
                    </>
                }
                
                
                <div className='Image-bottom-left-corner'>
                    <img src={require("../images/3d_illos_burger.png")} alt="illustration" />
                </div>
                <div className='Image-bottom-right-corner'>
                    <img src={require("../images/3d_illos_sushi.png")} alt="illustration" />
                </div>
            </div>
        } 
        </div>
    )
}

export default Login