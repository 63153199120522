import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {

    const navigate = useNavigate();

    const handleNavigateToHome = () => {
        navigate('/')
    }
  return (
    <div className='flex' onClick={handleNavigateToHome} style={{ cursor: "pointer", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
        <small>Made with</small>
        <img src={require("../images/bento-full-logo.png")} className="Logo-medium" style={{ opacity: 0.48 }} alt="illustration" />
    </div>
  )
}

export default Footer