import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from "../firebase-config"
import {
  collection,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  setDoc
} from "firebase/firestore"
import Twemoji from 'react-twemoji';
import { Plus } from 'phosphor-react';
import Button from '../components/Button.tsx';
import LoadingPage from './LoadingPage';
import GroceryListItem from '../components/GroceryListItem';
import EmptyState from '../components/EmptyState';
import { toast } from "react-hot-toast";
import { useIngredientSubmission } from '../controllers/ingredientSubmission';
import SkeletonLoader from '../components/SkeletonLoader';
import { useTheme } from '../styles/ThemeContext.js';



const Groceries = () => {

  // Loader
  const [loading, setLoading] = useState(true);
  const {colors} = useTheme();

  // Quick add
  const frequentGroceriesData = [
    { id: 1, name: "Egg", emoji: "🥚", color: "#FFF3EB" },
    { id: 2, name: "Chicken", emoji: "🍗", color: "#FCDCDB" },
    { id: 3, name: "Tomato", emoji: "🍅", color: "#FFCAD1" },
    { id: 4, name: "Onions", emoji: "🧅", color: "#FFD195" },
    { id: 5, name: "Cheese", emoji: "🧀", color: "#FFF0CD" },
    { id: 6, name: "Vegetables", emoji: "🥦", color: "#D3FFDA" },
    { id: 7, name: "Fish", emoji: "🐟", color: "#DAEFFF" },
    { id: 8, name: "Milk", emoji: "🥛", color: "#BED0FF" },
    { id: 9, name: "Bread", emoji: "🍞", color: "#E2C7BB" },
  ];

  const frequentGroceryList = frequentGroceriesData.map((frequentGroceryItem, index) =>
    <li key={index} className="Pill" style={{ background: frequentGroceryItem.color }}>
      <Twemoji options={{ className: 'twemoji' }}>
        <p>{frequentGroceryItem.emoji}</p>
      </Twemoji>
      <p>{frequentGroceryItem.name}</p>
    </li>
  )


  // SHOW USER INGREDIENTS BASED ON USER ID
  const userId = auth.currentUser && auth.currentUser.uid;
  const [userIngredients, setUserIngredients] = useState([])
  const ingredientsListRef = collection(db, "ingredients")


  useEffect(() => {
    if (!userId) {
      return
    }

    setLoading(true);

    const q = query(ingredientsListRef, where("userId", "==", userId));

    const fetchData = onSnapshot(q, (querySnapshot) => {
      setUserIngredients(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    });

    setLoading(false);
    return fetchData;
  }, [userId])


  const [ingredientsForm, setIngredientsForm] = useState({
    title: "",
    checked: "",
    userId: ""
  })


  // ADD TO GROCERY
  const { onIngredientSubmit, getRecommendedIngredients } = useIngredientSubmission()
  
  const handleAddIngredient = (event) => {
    event.preventDefault();
    onIngredientSubmit(ingredientsForm);

    setIngredientsForm({
      title: "",
      checked: "",
      userId: userId,
      count: "",
    })
  }

  // ADD INGREDIENTS FROM PILLS
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const handleAddToGroceryList = (ingredientTitle) => {
    onIngredientSubmit({ title: ingredientTitle });

    setSelectedIngredients([...selectedIngredients, ingredientTitle])
  };


  // FETCH FREQUENTLY ADDED INGREDIENTS
  const [recommendedIngredients, setRecommendedIngredients] = useState([]);

  useEffect(() => {
    const fetchRecommendedIngredients = async () => {
      const initialRecommendedIngredients = await getRecommendedIngredients(3);
      setRecommendedIngredients(initialRecommendedIngredients);
    };

    fetchRecommendedIngredients(); 
  }, []);


  // CROSS OUT INGREDIENTS  
  useEffect(() => {
    if (!userId) {
      return
    }

    const q = query(ingredientsListRef, where("userId", "==", userId));

    const fetchData = onSnapshot(q, (querySnapshot) => {

      setUserIngredients(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })))
    });

    return fetchData;
  }, [db, userId]);

  
  const handleCheck = (id) => {
    const index = userIngredients.findIndex(ingredient => ingredient.id === id);
    const checkedItem = userIngredients[index];
    const uncheckedItems = userIngredients.filter(ingredient => ingredient.id !== id);
    const updatedItem = { ...checkedItem, checked: !checkedItem.checked };

    const activeIngredientRef = doc(db, 'ingredients', id);
    

    setDoc(activeIngredientRef, updatedItem);
    
    setUserIngredients([
      ...uncheckedItems,
      updatedItem
    ])

  }


  const checkedIngredients = userIngredients.filter(ingredient => ingredient.checked);
  const uncheckedIngredients = userIngredients.filter(ingredient => !ingredient.checked);

  const addIngredientRef = useRef(null);

  const handleFocusToAddIngredient = () => {
    addIngredientRef.current.focus();

    if (window.innerWidth <= 960) {
      const yOffset = -100; // adjust this value as needed
      const y = addIngredientRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      addIngredientRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  

  // Edit ingredient

  const handleEditIngredient = (updatedIngredient) => {
    const updatedIngredients = userIngredients.map((ingredient) => {
      if (ingredient.id === updatedIngredient.id) {
        
        return { ...ingredient, name: updatedIngredient.name };
      }
      return ingredient;
    });

    setUserIngredients(updatedIngredients);

    const activeIngredientRef = doc(db, "ingredients", `${updatedIngredient.id}`);

    console.log(activeIngredientRef)

    console.log(updatedIngredient.name)

    updateDoc(activeIngredientRef, {
      title: updatedIngredient.name, 
    })


    console.log(updatedIngredients)
  };

  // Remove ingredient
  const handleDeleteIngredient = (ingredient) => {
    // console.log(id)
    deleteDoc(doc(db, "ingredients", ingredient.id))
    toast.success(`"${ingredient.title}" removed from the list`);
  }

  return (
    <div>
      
        <div className='Page-container' style={{backgroundColor: colors.background}}>
          <div className="Page-small">

            <div className="Page-header">
              <div className="Page-title">
                <div>
                  <h2 style={{color: colors.text}}>Groceries</h2>
                  {/* <p>Automatically create grocery list based on your planned meals and favourite recipes</p> */}
                </div>
              </div>
            </div>
            {loading ? <SkeletonLoader type={"list"} cardCount={12}/> :
            <div className='Grocery-container'>
              {/* GROCERY AREA */}
              <div className='Grocery-area'>


                {userIngredients.length === 0
                  ?
                  <>
                    <EmptyState
                      imageUrl={require("../images/3d_illos_groceries.png")}
                      title=" Keep Your Grocery List Up-to-Date"
                      description="Out of groceries? Keep your list up-to-date and never forget an ingredient again."
                      primaryButtonIcon={<Plus />}
                      primaryButtonName="Add grocery item"
                      primaryButtonOnClick={handleFocusToAddIngredient}
                    />
                  </>

                  :
                  null
                }
                <br></br>
                <h3 style={{color: colors.text}}>Frequently added</h3>
                <br></br>
                <div className='Pills'>
                  {frequentGroceriesData.map((frequentGroceryItem) => (
                    // Check if the ingredient is already selected
                    !selectedIngredients.includes(frequentGroceryItem.name) && (
                      <li
                        className="Pill"
                        style={{ background: frequentGroceryItem.color }}
                        onClick={() => handleAddToGroceryList(frequentGroceryItem.name)}
                        key={frequentGroceryItem.name}
                      >
                        <Twemoji options={{ className: 'twemoji' }}>
                          <p>{frequentGroceryItem.emoji}</p>
                        </Twemoji>
                        <p>{frequentGroceryItem.name}</p>
                      </li>
                    )
                  ))}
                  {recommendedIngredients.map((ingredient, index) => (
                    <li key={index} className="Pill" style={{ background: "#F4F4F5"  }} onClick={() => handleAddToGroceryList(ingredient.title)}>
                      <Twemoji options={{ className: 'twemoji' }}>
                        <p>{ingredient.emoji}🕓</p>
                      </Twemoji>
                      <p>{ingredient.title}</p>
                    </li>
                  ))}
                </div>
                <br></br>
                <br></br>

                <h3 style={{color: colors.text}}>Grocery list</h3>

                <form onSubmit={handleAddIngredient}
                  className="Grocery-form-add-container"
                >
                  <Button type="submit" appearance="default Button-icon" iconBefore={<Plus/>}/>
                  <input
                    ref={addIngredientRef}
                    type="text"
                    value={ingredientsForm.title}
                    onChange={(event) => setIngredientsForm({ ...ingredientsForm, title: event.target.value })}
                    className="Form-input Form-input-subtle"
                    placeholder='Add to list'
                  />
                </form>

                <ul className="Grocery-list">
                  {uncheckedIngredients.sort((a, b) => b.dateAdded - a.dateAdded).map((ingredient) => (
                    <GroceryListItem
                      key={ingredient.id}
                      name={ingredient.title}
                      onDelete={() => handleDeleteIngredient(ingredient)}
                      // The below doesn't work for some reason
                      // onChange={(event) => setIngredientsForm({...ingredientsForm, title: ingredient.title})}
                      onChange={handleEditIngredient}
                      checkboxOnChange={() => handleCheck(ingredient.id)}
                      checkedStyle={{
                        textDecoration: ingredient.checked ? 'line-through' : 'none', 
                      }}
                      // onSubmit={(ingredient) => handleEditIngredient(ingredient)}
                    />
                  ))}

                  {checkedIngredients.map((ingredient) => (
                    <GroceryListItem
                      key={ingredient.title}
                      name={ingredient.title}
                      onDelete={() => handleDeleteIngredient(ingredient)}
                      onChange={(event) => setIngredientsForm({...ingredientsForm, title: ingredient.title})}
                      
                      checkboxOnChange={() => handleCheck(ingredient.id)}
                      checkedStyle={{
                        textDecoration: 'line-through',
                      }}
                      checked={true}
                    />
                  ))}
                </ul>


                <br></br>
                <br></br>
                <br></br>
                <br></br>

              </div>

            </div>
            }
          </div>
          {/* <FeedbackCollector/> */}
        </div>
      
    </div>
  );
};

export default Groceries;



