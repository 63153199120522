import { CaretRight, CaretDown } from 'phosphor-react';
import React from 'react';
import { useTheme } from '../styles/ThemeContext';

const Expand = ({
    iconBefore,
    name,
    content,
    onClick,
    showExpand,
    extraControls,
}) => {
    // THEMING
    const { colors } = useTheme();

    return (
        <div
            style={
                {
                    backgroundColor: colors.default,
                    borderRadius: 12,
                    //   borderWidth: 0.5,
                    //   borderColor: colors.border,
                    marginBottom: 8,

                }}
        >
            <div onClick={onClick}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 8,
                        padding: 12,
                        borderBottomWidth: showExpand ? 0.5 : 0,
                        borderColor: colors.border,
                        cursor: 'pointer',
                    }}
                >
                    {iconBefore}

                    <h5 style={{ color: colors.text, display: 'flex', flexGrow: 1, margin: 0 }}>
                        {name}
                    </h5>
                    {extraControls}
                    {showExpand ? (
                        <CaretDown size={16} color={colors.icon} />
                    ) : (
                        <CaretRight size={16} color={colors.icon} />
                    )}
                </div>
            </div>
            {content}
        </div>
    );
};

export default Expand;
