import React, { useState, useEffect } from 'react';
import Recipes from '../data/recipes.json'
import LoadingPage from './LoadingPage';
import RecipeModal from '../components/RecipeModal';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { collection, doc, onSnapshot, query, updateDoc, where, } from 'firebase/firestore';
import { db } from "../firebase-config"
import Greetings from '../components/Greetings';
import { toast } from 'react-hot-toast';
import { useRecipeSubmission } from '../controllers/recipeSubmission';
import RecipeFeed from '../components/RecipeFeed';
import Button from '../components/Button.tsx';
import { Modal } from 'antd';
import DownloadMobileModal from '../components/DownloadMobileModal';
import Spacer from '../components/Spacer.js';
import moment from 'moment';
import SectionHeader from '../components/SectionHeader.js';
import TodayMeals from '../components/TodayMeals.js';
import { colors } from '../styles/theme.js';
import { useTheme } from '../styles/ThemeContext.js';
import { useCreateRecipeModal } from '../controllers/useCreateRecipeModal.js';
// import CreateRecipeModal from '../components/CreateRecipeModal.js';
import CreateRecipeModal from '../components/CreateRecipeModal/index.js';
import CuisineTiles from '../components/CuisineTiles.js';
import DownloadMobileBanner from '../components/DownloadMobileBanner.js';



const AppHome = () => {

    // LOADING
    const [loading, setLoading] = useState(true)
    // setTimeout(() => setLoading(false), 1000);

    const { colors } = useTheme();
    const navigate = useNavigate();

    const userId = auth.currentUser && auth.currentUser.uid;

    // SEARCH
    const [searchTerm, setsearchTerm] = useState("")
    const handleSearch = (event) => {
        setsearchTerm(event.target.value)
    }

    const filteredRecipes = Recipes.filter((recipe) =>
        recipe.title.toLowerCase().includes(searchTerm.toLowerCase())
    );


    // OPEN MODAL
    const [showRecipeModal, setShowRecipeModal] = useState(false)
    const [activeRecipe, setActiveRecipe] = useState([]);

    const handleRecipeModalOpen = (recipe) => {
        setShowRecipeModal(true)
        setActiveRecipe(recipe)
        console.log(activeRecipe)
    }

    const handleRecipeModalCancel = () => {
        setShowRecipeModal(false)
    }

    // AUTH CHECK

    const [user, setUser] = useState(undefined);

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userRef = doc(db, "users", currentUser.uid)
                // console.log(userRef)
                // console.log(user.email)
                setLoading(false)
            }
            else {
                navigate("/")
            }
        })
    }, [])


    // UPGRADE MODAL
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    const handleUpgradeModal = () => {
        setShowUpgradeModal(!showUpgradeModal)
        // console.log('bla')
    }


    // TODAY'S MEAL
    const [todayMeals, setTodayMeals] = useState([]);
    const todayDate = new Date();
    const formattedTodayDate = moment(todayDate).format('MMM D');

    const fetchPlannedMeals = () => {

        if (!userId) {
            setTodayMeals([]); // Clear today's meals when there's no user
            return;
        }

        const today = new Date();
        const formattedTodayDate = moment(today).format("YYYY-MM-DD");

        const recipesCollectionRef = collection(db, 'recipes');
        const q = query(
            recipesCollectionRef,
            where('userId', '==', userId),
            where('date', '==', formattedTodayDate)
        );

        const unsubscribe = onSnapshot(q, snapshot => {
            const todayMealsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTodayMeals(todayMealsData);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchPlannedMeals();

        return () => {
            unsubscribe && unsubscribe();
        };
    }, [userId]);

    const handleNavigateToSchedule = () => {
        navigate('/plan');
    };

    const handleOpenRecipeModal = recipe => {
        navigate(`/recipe-detail/${recipe.id}`, { state: { recipe } });
    };





    // RECIPES
    const [recipeForm, setRecipeForm] = useState({
        title: "",
        description: "",
        ingredients: [],
        steps: [],
        type: "",
        userId: "",
        author: "",
        saved: false,
    })

    // HANDLE SCHEDULING

    const [showDatePicker, setShowDatePicker] = useState(false)

    const { onRecipeSubmit } = useRecipeSubmission();

    const handleScheduleRecipe = (recipe) => {
        onRecipeSubmit(recipe);
        setActiveRecipe(recipe)
        const activeRecipeRef = doc(db, "recipes", activeRecipe.id)
        updateDoc(activeRecipeRef, {
            userId: userId,
            date: recipeForm.date
        })

        setShowDatePicker(false)
        toast.success(`Nice one! "${recipe.title}" updated successfully`);

    }

    // DOWNLOAD MOBILE
    const [showDownloadMobileModal, setShowDownloadMobileModal] = useState(false)

    const handleDownloadMobileModal = () => {
        setShowDownloadMobileModal(!showDownloadMobileModal)
    }

    const {
        showCreateRecipeModal,
        setShowCreateRecipeModal,
        showRecipeFromUrlModal,
        setShowRecipeFromUrlModal,
        // handleOpenCreateModal,
        handleOpenCreateRecipeFromUrlModal,
        handleOpenGenerateRecipeModal,
    } = useCreateRecipeModal();

    const handleOpenCreateModal = () => {
        setShowCreateRecipeModal(true);
    }

    // CREATE RECIPE
    const handleCreateRecipe = (recipe) => {

        onRecipeSubmit(recipe);
        // setShowRecipeCreateModal(false)
        // navigate('/recipes')
    }
    return (
        <div>
            {/* {showDownloadMobileModal && 
                <Modal
                    open={showDownloadMobileModal}
                    onCancel={() => setShowDownloadMobileModal(false)}
                    footer={null}
                    width={640}
                    style={{top: 40}}
                >
                    <DownloadMobileModal/>
                </Modal>
            } */}

            {loading ? <LoadingPage /> :
                <div className="Page-container" style={{ backgroundColor: colors.background }}>
                    <div className="Page-small">
                        <div className='Page-header'></div>
                        <Greetings />
                        {/* <Personalisation destination='/plan'/> */}
                        {/* <br></br>
                        <br></br>
                        <div className='Upgrade-banner'>
                            <img style={{width: 320}} src={require("../images/download_mobile_illustration.png")} alt="upgrade illustration" />
                            <div className='Upgrade-banner-content'>
                                <br></br>
                                <h2>Get Bentofy on the go!</h2>
                                <p>
                                    Add Bentofy to your home screen for quick, hassle-free experience – tap now!
                                </p>
                                <br></br>
                                <Button name="Learn more" 
                                    appearance="default"
                                    onClick={handleDownloadMobileModal}
                                />
                            </div>
                        </div>
                        <br></br> */}
                        {/* <RecipeFeed /> */}
                        <br></br>
                        <br></br>
                        <DownloadMobileBanner />
                        <SectionHeader
                            name="🗓 Today's meals"
                            action={formattedTodayDate}
                            onClick={handleNavigateToSchedule}
                        />
                        <TodayMeals
                            userId={userId}
                            todayMeals={todayMeals}
                            handleOpenCreateModal={handleOpenCreateModal}
                            handleOpenRecipeModal={handleOpenRecipeModal}
                        />
                        <Spacer size="lg" />
                        <SectionHeader
                            name="Discover by cuisine"
                        />
                        <CuisineTiles />
                        <Spacer size="xl" />

                        {showRecipeModal &&
                            <RecipeModal
                                onCancel={handleRecipeModalCancel}
                                name={activeRecipe.title}
                                imageUrl={activeRecipe.imageUrl}
                                type={activeRecipe.type}
                                cuisine={activeRecipe.cuisine}
                                diets={activeRecipe.diets}
                                difficulty={activeRecipe.difficulty}
                                cookTime={activeRecipe.cookTime}
                                cost={activeRecipe.cost}
                                linkSource={activeRecipe.linkSource}
                                ingredientsEditorContent={activeRecipe.ingredientsEditorContent}
                                stepsEditorContent={activeRecipe.stepsEditorContent}
                                isSaved={activeRecipe.saved}
                            />
                        }

                        {/* {showCreateRecipeModal && (
                            <CreateRecipeModal
                                open={() => setShowCreateRecipeModal(true)}
                                onOk={() => {
                                    setShowCreateRecipeModal(false); // Close the modal after saving
                                }}
                                onCancel={() => setShowCreateRecipeModal(false)}
                                onSubmit={(recipe) => { handleCreateRecipe(recipe) }}
                            />
                        )} */}

                        {showCreateRecipeModal && (
                            <CreateRecipeModal
                                recipeId={activeRecipe.id}
                                recipeData={activeRecipe}
                                open={() => setShowCreateRecipeModal(true)}
                                onOk={() => {
                                    setShowCreateRecipeModal(false); // Close the modal after saving
                                }}
                                onCancel={() => setShowCreateRecipeModal(false)}
                            />
                        )}


                        {/* <div className='Section-grocery flex'>
                    <div className="Section-content-text">
                        <h3>Set up your groceries</h3>
                        <p>Connect to popular grocery service to easily get the ingredients based on your planned meals</p>
                        <ConnectAppsActions/>
                    </div>
                    <img className='Thumbnail' src={require("../images/grocery-illos.png")}/>
                </div> */}

                        {/* <div className='Upgrade-banner'>
                            <div className='Upgrade-banner-content'>
                                <br></br>
                                <h2>Enjoying Bentofy?</h2>
                                <p>
                                    Show some love by grabbing me a virtual coffee, subscribing for extra perks, or sharing feedback to enhance your experience!
                                </p>
                                <br></br>
                                <div className='flex'>
                                    <a href="https://buy.stripe.com/4gw0439KTf4ygcEcMN" target="_blank" rel="noreferrer">
                                        <Button name="💛 Subscribe now" 
                                            appearance="default"
                                        />
                                    </a>
                                    <a href="https://buy.stripe.com/8wMeYX4qz8GabWoeUW"  target="_blank" rel="noreferrer">
                                        <Button name="☕️ Buy me a coffee" 
                                            appearance="default"
                                        />
                                    </a>                                   
                                </div>
                            </div>
                        </div> */}

                        {/* <UpgradeBanner/> */}

                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                    </div>
                </div>
            }
        </div>
    )
}

export default AppHome