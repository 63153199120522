import React, { useState } from 'react';
import MealCalendar from '../components/MealCalendar';
import Button from '../components/Button.tsx';
import LoadingPage from './LoadingPage';
import { useSelector } from 'react-redux';
import UnscheduledSidebar from '../components/UnscheduledSidebar';
import FeedbackCollector from '../components/FeedbackCollector';
import { toast } from 'react-hot-toast';
import GenerateButton from '../components/GenerateButton';
import { DotsThree, Link, Plus, Star } from 'phosphor-react';
import SkeletonLoader from '../components/SkeletonLoader';
import { useTheme } from '../styles/ThemeContext.js';

const MealPlan = () => {
  const [loading, setLoading] = useState(true);
  const {colors} = useTheme();

  setTimeout(() => setLoading(false), 1000);

  const recipeList = useSelector((state) => state.recipe.recipeList)
  const unscheduledRecipeList = useSelector((state) => state.recipe.unscheduledRecipeList)

  const [planSidebarVisible, setPlanSidebarVisible] = useState(false)

  const handleToggleSidebar = () => {
    setPlanSidebarVisible(!planSidebarVisible)
  }

  const handleGenerate = () => {
    alert('Generateeee')
  }

  return (
    <>
      {loading ? <SkeletonLoader  type={"grid"} cardCount={24}/> :
        <div className='Plan-page-container' style={{background: colors.background}}>
          <div className='Page-container'>
            <div className="Page-wide">
              <div className="Page-header" style={{marginTop: 72}}>
                <div className="Page-title">
                  {/* <div>
                  <h2>Plan</h2>
                  <p>Plan your weekly meals by drag and dropping recipes, food delivery, and dining out options for the week</p>
                </div> */}
                </div>
                {/* <Button appearance="secondary" name="My list" iconBefore={<CalendarPlus/>} onClick={handleToggleSidebar}/> */}
              </div>
              {/* <SearchBar placeholder="Search recipes" onChange={""} appearance="default"/>    */}


              <MealCalendar toggleSidebar={handleToggleSidebar} />
            </div>
          </div>
          {/* {planSidebarVisible && */}
          <UnscheduledSidebar
            visible={planSidebarVisible}
            closeSidebar={handleToggleSidebar}
          />
          {/* } */}

        </div>
      }
    </>
  );

};


export default MealPlan;
