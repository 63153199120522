import React, { useState, useEffect, useContext } from 'react';
import { MegaphoneSimple, EnvelopeSimple, Book } from 'phosphor-react';
import Avatar from '../components/Avatar.tsx';
import LoadingPage from './LoadingPage';
import { collection, doc, addDoc, getDocs, onSnapshot, query, where, updateDoc, } from "firebase/firestore";
import { db } from "../firebase-config"
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Button from '../components/Button.tsx';
import ListItem from '../components/ListItem';
import { Modal, } from 'antd';
import FeedbackCollector from '../components/FeedbackCollector';
import { toast } from 'react-hot-toast';
import UpgradeModal from '../components/UpgradeModal';
import TutorialModal from '../components/OnboardingModal';
import AvatarModal from '../components/AvatarModal';
import { getAuth } from "firebase/auth";
import ThemeContext, { useTheme } from '../styles/ThemeContext.js';

const Preferences = ({ uid }) => {

  // Loader
  const [loading, setLoading] = useState(true)
  setTimeout(() => setLoading(false), 600);


  const [user, setUser] = useState(undefined);
  const [userData, setUserData] = useState([]);
  const auth = getAuth();
  const userAuth = auth.currentUser;

  const navigate = useNavigate();
  const userId = userAuth ? userAuth.uid : null;

  const usersCollectionRef = collection(db, "users");
  const preferencesCollectionRef = collection(db, 'preferences');
  const [preferences, setPreferences] = useState([]);

  // const { theme, setTheme } = useContext(ThemeContext);

  const { theme, toggleTheme, colors } = useTheme();



  // AUTHENTICATION & FETCH PREFERENCES
  useEffect(() => {

    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        navigate('/preferences');
      }
      else {
        navigate("/");
      }
    });


  }, [userId]);


  useEffect(() => {
    const fetchUserData = async () => {
      const usersSnapshot = await getDocs(usersCollectionRef);

      const usersData = usersSnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data()
      }));

      const currentUserData = usersData.find((user) => user.userId === userId);

      setUserData(currentUserData);
    };

    fetchUserData();

  }, [userId])



  // SHOW PROFILE
  // GET USER PROFILE PICTURE

  const [photoURL, setPhotoURL] = useState("https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg")
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")


  useEffect(() => {
    if (userAuth !== null) {
      // The user object has basic properties such as display name, email, etc.
      const displayName = userAuth.displayName;
      const email = userAuth.email;
      // const photoURL = userAuth.photoURL;
      const providerData = userAuth.providerData;
      const photoURL = providerData[0].photoURL;
      const emailVerified = userAuth.emailVerified;
      const uid = userAuth.uid;

      setPhotoURL(photoURL)
      setUsername(displayName)
      setEmail(email)
    }
  }, [userAuth])



  const handleSignOut = () => {
    signOut(auth);
    navigate(0);
  }

  useEffect(() => {
    if (!userId) {
      console.log('Preferences user Id', userId);
      return;
    }

    console.log('Preferences user Id logged in', userId);
    const q = query(usersCollectionRef, where('userId', '==', userId));

    const unsubscribeUser = onSnapshot(q, snapshot => {
      setUserData(
        snapshot.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
    });

    const unsubscribePreferences = onSnapshot(
      preferencesCollectionRef,
      snapshot => {
        setPreferences(
          snapshot.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          })
        );
      }
    );

    return () => {
      unsubscribeUser();
      unsubscribePreferences();
    };
  }, [userId]);


  // AVATAR UPLOAD
  const [showPersonalisation, setShowPersonalisation] = useState(false)
  const handleOpenPreferences = () => {
    setShowPersonalisation(!showPersonalisation)
  }


  // FEEDBACK
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const handleShowFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal)
  }

  const [feedbackForm, setFeedbackForm] = useState({
    feedbackDetail: "",
    feedbackRating: "",
    feedbackEmail: "",
    feedbackSubmittedDate: new Date(),
  })

  const feedbackCollectionRef = collection(db, "feedback")

  const handleSubmitFeedback = () => {
    addDoc(feedbackCollectionRef, feedbackForm)

    setFeedbackForm({
      feedbackDetail: "",
      feedbackRating: "",
      feedbackEmail: "",
      feedbackSubmittedDate: new Date(),
    })

    setShowFeedbackModal(false)
    toast.success("Thank you for submitting your feedback!")
  }




  // UPGRADE
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const handleUpgradeModal = () => {
    setShowUpgradeModal(!showUpgradeModal)
  }


  // TUTORIAL
  const [showTutorialModal, setShowTutorialModal] = useState(false)

  const handleShowTutorialModal = () => {
    setShowTutorialModal(!showTutorialModal)
  }

  // AVATAR CUSTOMISER
  const [showAvatarModal, setShowAvatarModal] = useState(false)

  const handleShowAvatarModal = () => {
    setShowAvatarModal(!showAvatarModal)
  }


  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const handleEmojiSelection = (emoji) => {
    if (selectedEmoji.includes(emoji)) {
      setSelectedEmoji(selectedEmoji.filter((c) => c !== emoji));
    }
    else {
      setSelectedEmoji([...selectedEmoji, emoji]);
    }
  };

  const [avatar, setAvatar] = useState({
    selectedColor: "#F4F5F7",
    selectedEmoji: "🍜",
    avatarUrl: ""
  });


  const handleSaveAvatar = (newAvatar) => {

    setAvatar(newAvatar);

    const userRef = doc(db, "users", userId)

    console.log(avatar.selectedEmoji)
    updateDoc(userRef, {
      userAvatarEmoji: avatar.selectedEmoji,
      userAvatarBackground: avatar.selectedColor,
    }, { merge: true })

    setShowAvatarModal(false);
    toast.success("Looking good there!")
  }

  const navigateToTutorial = () => {
    navigate('/onboarding');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }



  return (
    <div>
      {loading ? <LoadingPage /> :
        <div className='Page-container' style={{ background: colors.background }}>
          <div className="Page-small">
            <div className="Page-header"></div>


            <div className='Preferences-card-list'>
              {/* {preferencesCard}    */}
              <div className='Preferences-card Center'>
                <div className='Preferences-header'>
                  <Avatar
                    url={photoURL}
                    size="xLarge"
                    backgroundColor={userData.userAvatarBackground}
                    emoji={userData.userAvatarEmoji}
                    onClick={handleShowAvatarModal}
                  />
                  <h2 className='Text-center' style={{ color: colors.text }}>
                    {/* {username ? username : (userData && userData[0] ? userData[0].username : "")} */}
                    {userData && userData.length > 0 ? userData[0].username : ''}
                  </h2>

                  <small className='Text-center' style={{ color: colors.textSubtle }}>{email}</small>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>

            {/* <UserPreferences userData={userData[0]}/> */}
            {/* <h5>Account</h5>
            <br></br> */}
            {/* {theme === 'light' ? 
              <Button appearance="default" name="Switch to Dark Theme" onClick={toggleTheme} /> 
            : 
              <Button appearance="default" name="Switch to Light Theme" onClick={toggleTheme} />
            } */}
            <div className='List-container'>

              <ListItem
                key="Email"
                appearance="bordered"
                iconBefore={
                  <span className="List-item-icon" style={{ backgroundColor: colors.backgroundSubtle, color: "#182222" }}>
                    <EnvelopeSimple size={20} color={colors.icon} />
                  </span>
                }
                name="Email"
                value={<p style={{ color: colors.text }}>{email}</p>}
              />
              <ListItem
                key="Tutorials"
                appearance="bordered"
                iconBefore={
                  <span className="List-item-icon" style={{ backgroundColor: colors.backgroundSubtle, color: "#182222" }}>
                    <Book size={20} color={colors.icon} />
                  </span>
                }
                name="Tutorials"
                // onClick={handleShowTutorialModal}
                onClick={navigateToTutorial}
              />
              <a href="https://forms.gle/Yq2QUj4wYocLAsscA" target="_blank" rel='noreferrer'>
                <ListItem
                  key="Feedback"
                  appearance="bordered"
                  iconBefore={
                    <span className="List-item-icon" style={{ backgroundColor: colors.backgroundSubtle, color: "#182222" }}>
                      <MegaphoneSimple size={20} color={colors.icon} />
                    </span>
                  }
                  name="Give feedback"
                // onClick={handleShowFeedbackModal}
                />
              </a>

            </div>

            {showFeedbackModal &&
              <Modal
                open={showFeedbackModal}
                onCancel={() => setShowFeedbackModal(false)}
                footer={null}
                width={800}
                style={{ top: 40 }}
              >
                <FeedbackCollector

                  feedbackRatingOnChange={(event) => setFeedbackForm({ ...feedbackForm, feedbackRating: event })}
                  feedbackDetailOnChange={(event) => setFeedbackForm({ ...feedbackForm, feedbackDetail: event.target.value })}
                  feedbackEmailOnChange={(event) => setFeedbackForm({ ...feedbackForm, feedbackEmail: event.target.value })}
                  onSubmit={handleSubmitFeedback}
                  onCancel={() => setShowFeedbackModal(false)}
                />

              </Modal>
            }


            {showUpgradeModal &&
              <Modal
                open={showUpgradeModal}
                onCancel={() => setShowUpgradeModal(false)}
                footer={null}
                width={640}
                style={{ top: 40 }}
              >
                <UpgradeModal />
              </Modal>
            }

            {showTutorialModal &&
              <Modal
                open={showTutorialModal}
                onCancel={() => setShowTutorialModal(false)}
                footer={null}
                width={800}
                style={{ top: 40 }}
              >
                <TutorialModal
                  handleDone={() => setShowTutorialModal(false)}
                />
              </Modal>
            }

            {showAvatarModal &&
              <Modal
                open={showAvatarModal}
                onCancel={() => setShowAvatarModal(false)}
                footer={null}
                width={480}
                style={{ top: 40 }}
              >
                <AvatarModal
                  handleDone={() => setShowAvatarModal(false)}
                  onClick={handleShowAvatarModal}
                  onCancel={() => setShowAvatarModal(false)}
                  initialColor={avatar.selectedColor}
                  initialEmoji={avatar.selectedEmoji}
                  onSave={handleSaveAvatar}
                />
              </Modal>
            }

            <br></br>
            <Button appearance="delete Button-full" name="Sign out" onClick={handleSignOut} />

            <br></br>
            <br></br>
            <br></br>

            <div className='Upgrade-banner'>
              <div className='Upgrade-banner-content'>
                <br></br>
                <h2>Enjoying Bentofy?</h2>
                <p>
                  Show some love by grabbing me a virtual coffee, subscribing for extra perks, or sharing feedback to enhance your experience!
                </p>
                <br></br>
                <div className='flex'>
                  <a href="https://buy.stripe.com/4gw0439KTf4ygcEcMN" target="_blank" rel="noreferrer">
                    <Button name="💛 Subscribe now"
                      appearance="default"
                    />
                  </a>
                </div>
                <a href="https://buy.stripe.com/8wMeYX4qz8GabWoeUW" target="_blank" rel="noreferrer">
                  <Button name="☕️ Buy me a coffee"
                    appearance="default"
                  />
                </a>
              </div>
            </div>
          </div>


          {/* <h2>Sync across devices</h2>
            <p>Always know what's on the table everyone, everytime, everywhere </p>
            <br></br>
            <div className="Device-card-list">
              {devicesCard}
            </div> */}


          {/* <UpgradeBanner /> */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      }
    </div>
  );
};

export default Preferences;
