import React, { useState } from 'react'
import {
  CalendarBlank,
  CalendarCheck,
  CalendarPlus,
  CalendarX,
  DotsThree,
  DotsThreeVertical,
  Heart,
  Star,
  Pencil,
  Trash,
} from 'phosphor-react'
// import Button from './Button';
import { db } from '../firebase-config'
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore'
import { Popover, Tag, Button, Tooltip } from 'antd'
import { useRecipeSave } from '../controllers/saveRecipe'
import { ReactComponent as HeartFilled } from '../images/Heart-filled.svg'
import { ReactComponent as StarFilled } from '../images/Star-filled.svg'
import Avatar from './Avatar.tsx'
import { useTheme } from '../styles/ThemeContext.js'
import WeekDatePicker from './WeekDatePicker.js'
import { toast } from 'react-hot-toast'
import DeleteModal from './DeleteModal.js'
import { getAuth } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

const RecipeCard = ({ recipe,
  imageUrl,
  type,
  onClick,
  onEdit,
  onRemoveSchedule,
  onDelete,
  showSchedule,
  handleUnsaveRecipe,
  handleSaveRecipe,
  showIsSaved,
  savedByCurrentUser,
  showMoreMenu,
  style }) => {
  const [isAddedToPlan, setIsAddedToPlan] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const { colors } = useTheme();

  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();

  const handleImageLoaded = () => {
    setImageLoaded(true)
  }

  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDelete = async (recipe) => {
    try {
      // Check if the user is the owner of the recipe
      const recipeSnapshot = await getDoc(doc(db, 'recipes', recipe.id));
      const recipeData = recipeSnapshot.data();

      if (recipeData.userId === user.uid) {
        setShowDeleteModal(true);
      } else {
        // Show an error message if the user is not the owner
        toast.error("You can only delete your own recipes.")
      }
    } catch (error) {
      console.error('Error deleting recipe:', error);
      toast.error("Error deleting recipe:")
    }
  };

  const confirmDelete = async (id) => {
    // Delete the recipe
    await deleteDoc(doc(db, 'recipes', id));
    toast.success(`Recipe successfully deleted`)
    // navigate('/app-home');
  };

  const [moreMenu, setMoreMenu] = useState(false)

  const handleMore = () => {
    setMoreMenu(!moreMenu)
  }

  const handleUnschedule = recipe => {
    const recipeRef = doc(db, 'recipes', recipe.id);
    updateDoc(recipeRef, {
      date: '',
    });
    toast.success(`${recipe.title} is removed from schedule!`)
  };

  const moreMenuContent = (
    <>
      <li
        className="Dropdown-menu-item"
        onKeyDown={onEdit}
        onClick={onEdit}
      >
        <Pencil size={24} />
        <p>Edit</p>
      </li>
      <li
        className="Dropdown-menu-item"
        onKeyDown={handleUnschedule}
        onClick={() => handleUnschedule(recipe)}
      >
        <CalendarX size={24} />
        <p>Unschedule</p>
      </li>
      <li
        className="Dropdown-menu-item"
        onKeyDown={() => handleDelete(recipe)}
        onClick={() => handleDelete(recipe)}
      >
        <Trash size={24} className="Destructive" />
        <p className="Destructive">Delete</p>
      </li>
    </>
  )


  let typeColor

  switch (type) {
    case 'Breakfast':
      typeColor = 'green'
      break
    case 'Lunch':
      typeColor = 'gold'
      break
    case 'Dinner':
      typeColor = 'volcano'
      break
    case 'Snack':
      typeColor = 'cyan'
      break
    case 'Dessert':
      typeColor = 'magenta'
      break
    case 'Others':
      typeColor = 'geekblue'
      break
    case 'Eat out':
      typeColor = 'purple'
      break
    case 'Takeaway':
      typeColor = 'lime'
      break
    case 'Side dish':
      typeColor = 'cyan'
      break
    default:
      typeColor = 'green'
  }

  const [isSaved, setIsSaved] = useState(savedByCurrentUser)

  const handleSaveClick = async () => {
    if (isSaved) {
      await handleUnsaveRecipe(recipe)
    } else {
      await handleSaveRecipe(recipe)
    }
    setIsSaved(!isSaved)
  }

  return (
    <li key={recipe.id} className="Recipe-card" style={{ background: colors.background }}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Recipe thumbnail"
          className="Recipe-thumbnail"
          onClick={onClick}
          onLoad={handleImageLoaded}
        />
      ) : (
        <img
          src={require('../images/food-illos.png')}
          alt="Recipe thumbnail"
          className="Recipe-thumbnail"
          onClick={onClick}
        />
      )}
      <div className="Recipe-card-content">
        <div className="flex Recipe-card-header">
          <h4 className="Truncate" onClick={onClick} style={{ color: colors.text }}>
            {recipe.title}
          </h4>
          {showMoreMenu ? (
            <Popover
              content={moreMenuContent}
              trigger="click"
              open={moreMenu}
              onOpenChange={handleMore}
            >
              <Button
                className="Dropdown-button"
                type="subtle"
                icon={<DotsThree size={24} />}
                shape="circle"
              />
            </Popover>
          ) : null}


        </div>

        {type ? (
          <div>
            <Tag color={typeColor}>{type ? type : 'none'}</Tag>
          </div>
        ) : null}
        <div className="flex Recipe-author" style={{ alignItems: 'center' }}>
          {recipe.authorPhoto ? (
            <Avatar size="small" url={recipe.authorPhoto} />
          ) : null}
          {recipe.author ? (
            <div>
              <small>{recipe.author}</small>
            </div>
          ) : null}
        </div>
        <div className="flex Recipe-card-bottom-actions" style={{ gap: 4 }}>
          {showSchedule &&
            <WeekDatePicker
              recipeId={recipe.id}
              recipeData={recipe}
            />
          }
          {showIsSaved ? (
            isSaved ? (
              <Button
                appearance="icon"
                icon={
                  <StarFilled
                    // className="Heart-filled"

                    style={{ fill: '#dc2626', height: 24, width: 24 }}
                  />
                  // <Star size={24} color={colors.Y400} />
                }
                onClick={handleSaveClick}
                style={{ border: 0, height: 40, width: 40 }}
              />
            ) : (
              <Button
                type="subtle"
                icon={
                  <Star size={24} color={colors.icon} />
                }
                onClick={handleSaveClick}
                style={{ border: 0, height: 40, width: 40 }}
              />

            )
          ) : null}
          <div className="flex-grow"></div>
        </div>
      </div>
      <DeleteModal
        open={showDeleteModal}
        handleOk={() => confirmDelete(recipe.id)}
        handleCancel={() => setShowDeleteModal(false)}
      />
    </li>
  )
}

export default RecipeCard           
