import React, { FC, ChangeEvent } from 'react';

interface CheckboxProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ onChange, checked }) => {
  return (
    <label className="Checkbox">
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span className="Checkmark"></span>
    </label>
  );
};

export default Checkbox;