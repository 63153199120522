import { ListNumbers } from 'phosphor-react';
import React, { useState } from 'react';
import parse from 'html-react-parser';

import { useTheme } from '../styles/ThemeContext';
import Expand from './Expand';

const RecipeDetailSteps = ({ handleEdit, recipe, user }) => {
    const { colors } = useTheme();
    const [showStepsExpand, setShowStepsExpand] = useState(true);

    const handleStepsExpand = () => {
        setShowStepsExpand(!showStepsExpand);
    };
    return (
        <Expand
            iconBefore={<ListNumbers size={16} color={colors.icon} />}
            onClick={handleStepsExpand}
            name="Steps"
            showExpand={showStepsExpand}
            content={
                <div>
                    {showStepsExpand ? (
                        <div
                            style={{ padding: 16, gap: 16, }}
                            onClick={handleEdit}
                        >
                            {recipe.steps ? (
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                                    {recipe.steps.map((step, index) => (
                                        <div
                                            key={step.key}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 8,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    borderRadius: 24,
                                                    backgroundColor: colors.backgroundInverse,
                                                    height: 24,
                                                    width: 24,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <small
                                                    style={
                                                        { color: colors.textInverse }
                                                    }
                                                >{`${step.key}`}</small>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <div style={{ color: colors.text }}>
                                                    {step.title}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {recipe.stepsEditorContent ? (
                                recipe.stepsEditorContent.startsWith('<') ? (
                                    // <div style={{ color: colors.text }}>
                                    //     {parse(recipe.stepsEditorContent)}
                                    // </div>
                                    null
                                ) : (
                                    //   <HTMLdiv
                                    //     value={recipe.stepsEditorContent}
                                    //     stylesheet={{
                                    //       ...contentStyles,
                                    //       li: {
                                    //         ...contentStyles.p,
                                    //         color: colors.text,
                                    //         lineHeight: 24,
                                    //       },
                                    //       em: { ...contentStyles.p, color: colors.text },
                                    //       p: { ...contentStyles.p, color: colors.text },
                                    //       '.wprm-recipe-instruction-text': { color: 'red' },
                                    //     }}
                                    //   />
                                    <p style={{ color: colors.text }}>
                                        {recipe.stepsEditorContent}
                                    </p>

                                )
                            ) : (
                                <p style={{ color: colors.textSubtle }}>
                                    {user && user.uid === recipe.userId ? 'Add ' : 'No '}
                                    steps or instructions
                                </p>
                            )}
                        </div>
                    ) : null}
                </div>
            }
        />
    );
};

export default RecipeDetailSteps;
