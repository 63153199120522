import {
    Info,
    Knife,
    Clock,
    CookingPot,
    LinkSimpleHorizontal,
    CurrencyCircleDollar,
} from 'phosphor-react';
import React, { useState } from 'react';
import parse from 'html-react-parser';

import Expand from './Expand';
import { useTheme } from '../styles/ThemeContext';

const RecipeDetailInfo = ({ recipe, handleEdit, user }) => {
    const { colors } = useTheme();
    const [showInfoExpand, setShowInfoExpand] = useState(true);
    const handleInfoExpand = () => {
        setShowInfoExpand(!showInfoExpand);
    };
    return (
        <Expand
            iconBefore={<Info size={16} color={colors.icon} />}
            onClick={handleInfoExpand}
            name="Details"
            showExpand={showInfoExpand}
            content={
                <div>
                    {showInfoExpand ? (
                        <div style={{ padding: 16 }} onClick={handleEdit}>
                            {recipe.description ? (

                                <p style={{ color: colors.text }}>
                                    {parse(recipe.description)}
                                </p>
                            ) : (
                                <p style={{ color: colors.textSubtle }}>
                                    {user && user.uid === recipe.userId ? 'Add ' : 'No '}
                                    description
                                </p>
                            )}
                            <div style={{ paddingTop: 8 }}>
                                {recipe.difficulty ? (
                                    <div style={styles.metadata}>
                                        <Knife size={20} color={colors.iconSubtle} />
                                        <p style={{ color: colors.text }}>
                                            {' '}
                                            Difficulty: {recipe.difficulty}
                                        </p>
                                    </div>
                                ) : null}
                                {recipe.cookTime ? (
                                    <div style={styles.metadata}>
                                        <Clock size={20} color={colors.iconSubtle} />
                                        <p style={{ color: colors.text }}>
                                            {' '}
                                            {recipe.cookTime}
                                        </p>
                                    </div>
                                ) : null}
                                {recipe.cuisine ? (
                                    <div style={styles.metadata}>
                                        <CookingPot size={20} color={colors.iconSubtle} />
                                        <p style={{ color: colors.text }}>
                                            {' '}
                                            {recipe.cuisine}
                                        </p>
                                    </div>
                                ) : null}
                                {recipe.linkSource ? (
                                    <div style={{ flexDirection: 'column' }}>
                                        <div
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 8,
                                                padding: 4,
                                            }}
                                        >
                                            <LinkSimpleHorizontal
                                                size={20}
                                                color={colors.iconSubtle}
                                            />
                                            <div
                                                style={{ color: colors.textSubtle }}
                                            >
                                                Source:
                                            </div>
                                        </div>

                                        <a href={recipe.linkSource}>
                                            <p
                                                style={
                                                    {
                                                        color: colors.link,
                                                        textDecorationLine: 'underline',
                                                        fontWeight: 500,
                                                    }}
                                            >
                                                {recipe.linkSource}
                                            </p>
                                        </a>
                                    </div>
                                ) : null}

                                {recipe.cost ? (
                                    <div style={styles.metadata}>
                                        <CurrencyCircleDollar size={20} color={colors.iconSubtle} />
                                        <p style={{ color: colors.text }}>
                                            {' '}
                                            Est. $ {recipe.cost} / servings
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            }
        />
    );
};

export default RecipeDetailInfo;

const styles = {
    metadata: {
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        padding: 8,
        paddingHorizontal: 2,
    }
};
