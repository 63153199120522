import React from 'react'
import { useTheme } from '../styles/ThemeContext';
import { elevations, grid, typography } from '../styles/theme';

const CuisineTile = ({ icon, name, color, onClick }) => {
    const { colors } = useTheme();
    return (
        <div
            key={name}
            style={{
                backgroundColor: color,
                borderRadius: 8,
                gap: 4,
                margin: 2,
                display: 'flex',
                flexGrow: 1,
                width: '24%',
                flexDirection: 'row',
                overflow: 'hidden',
                cursor: 'pointer'
            }}
            onClick={onClick}
        >

            <div style={{ padding: 8, paddingVertical: 16, flexGrow: 1 }}>
                <h3 style={{ color: '#000000' }}>{name}</h3>
            </div>
            <div style={{
                backgroundColor: color,
                height: 56,
                width: 56,
                overflow: 'hidden',
            }}>
                <p style={{ fontSize: 64, right: -4, bottom: -4, color: colors.text }}>{icon}</p>
            </div>
        </div>
    )
}

export default CuisineTile