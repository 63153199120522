import { useNavigate } from 'react-router-dom';
import React from 'react';

import Avatar from './Avatar.tsx';

import { typography } from '../styles/theme';
import { useTheme } from '../styles/ThemeContext';

const AvatarMetadata = ({ userId, name, image, emoji, background, size }) => {
    const { colors } = useTheme();
    const navigate = useNavigate();
    const handleOpenProfile = () => {
        navigate('/profile', {
            userId,
            name,
            image,
            emoji,
            background,
        });
        console.log(userId);
    };
    return (
        <div
        // onClick={handleOpenProfile}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingVertical: 4,
                    gap: 6,
                    alignItems: 'center',
                }}
            >
                {image ? (
                    <Avatar url={image} size={size} />
                ) : (
                    <Avatar emoji={emoji} color={background} size={size} />
                )}
                <small style={{ color: colors.text }}>{name}</small>
            </div>
        </div>
    );
};

export default AvatarMetadata;
