import {
    collection,
    getDocs,
    setDoc,
    doc,
    getDoc,
    serverTimestamp,
    deleteDoc,
} from "firebase/firestore"
import { toast } from "react-hot-toast";

export const fetchSavedRecipes = async (db, userId) => {
    try {
        const savedRecipesSnapshot = await getDocs(collection(db, 'users', userId, 'savedRecipes'));
        const savedRecipeIds = savedRecipesSnapshot.docs.map((doc) => doc.id);

        const savedRecipeDocs = await Promise.all(
            savedRecipeIds.map((recipeId) => getDoc(doc(collection(db, 'recipes'), recipeId)))
        );

        return savedRecipeDocs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            savedByCurrentUser: true,
        }));

    } catch (error) {
        console.log('Error fetching saved recipes', error);
        return [];
    }
};

export const handleSaveRecipe = async (db, userId, recipe) => {
    try {
        const savedRecipesRef = collection(db, 'users', userId, 'savedRecipes');

        await setDoc(doc(savedRecipesRef, recipe.id), {
            recipeRef: doc(collection(db, 'recipes'), recipe.id),
            savedAt: serverTimestamp(),
            savedBy: userId,
            savedByCurrentUser: true
        });

        console.log('Recipe saved successfully');
        toast.success(`${recipe.title} saved successfully in recipes!`);
        return true;
    } catch (error) {
        console.log('Error saving recipe', error);
        return false;
    }
};

export const handleUnsaveRecipe = async (db, userId, recipe) => {
    try {
        const savedRecipesRef = collection(db, 'users', userId, 'savedRecipes');
        await deleteDoc(doc(savedRecipesRef, recipe.id));
        toast.success(`${recipe.title} removed from saved recipes`);
        return true;
    } catch (error) {
        console.log('Error unsaving recipe', error);
        return false;
    }
};