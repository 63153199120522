import React, { useState, useEffect, useRef } from 'react'
import { db } from "../firebase-config"
import {
    collection,
    doc,
    updateDoc,
    deleteDoc,
    onSnapshot,
    query,
    where,
    orderBy,
} from "firebase/firestore"
import Button from '../components/Button.tsx'
import RecipeCard from '../components/RecipeCard'
import { NotePencil, Plus, X } from 'phosphor-react'
import RecipeModal from '../components/RecipeModal'
import SearchBar from '../components/SearchBar'
// import CreateRecipeModal from '../components/CreateRecipeModal'
import CreateRecipeModal from '../components/CreateRecipeModal/index.js'
import { toast } from 'react-hot-toast'
import { useAuthValue } from '../context/AuthContext'
import moment from 'moment'
import { auth } from '../firebase-config';
import { useRecipeSubmission } from '../controllers/recipeSubmission'
import EmptyState from '../components/EmptyState'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSavedRecipes, handleSaveRecipe, handleUnsaveRecipe } from '../controllers/saveRecipe';
import SkeletonLoader from '../components/SkeletonLoader'
import { useTheme } from '../styles/ThemeContext.js'
import { useCreateRecipeModal } from '../controllers/useCreateRecipeModal.js'
import { getAuth } from 'firebase/auth'



const Recipe = () => {

    // LOADING
    const [loading, setLoading] = useState(true)
    const { recipeId } = useParams();
    const [isGenerating, setIsGenerating] = useState(false);

    const { colors } = useTheme();

    // USERS
    const { currentUser } = useAuthValue();
    const user = getAuth().currentUser;

    const { onRecipeSubmit } = useRecipeSubmission();
    const navigate = useNavigate();

    // RECIPES
    const [recipes, setRecipes] = useState([])
    const [recipeForm, setRecipeForm] = useState({
        title: "",
        description: "",
        ingredients: [],
        ingredientsEditorContent: "",
        steps: [],
        stepsEditorContent: "",
        type: "",
        userId: "",
        cuisine: "",
        diets: "",
        difficulty: "",
        cookTime: "",
        cost: "",
        linkSource: "",
        author: "",
        authorPhoto: "",
    })


    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const [userRecipes, setUserRecipes] = useState([]);
    const recipesCollectionRef = collection(db, "recipes");
    const [activeRecipe, setActiveRecipe] = useState();


    // SEARCH
    const [searchTerm, setSearchTerm] = useState("")
    const [loadingSearch, setLoadingSearch] = useState(true)

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
    }

    // SHOW USER CREATED RECIPES BASED ON USER ID
    useEffect(() => {
        if (!userId) {
            return;
        }

        const q = query(recipesCollectionRef,
            where("userId", "==", userId),
            where("saved", "==", true),
            where("title", ">=", searchTerm),
            where("title", "<=", searchTerm + '\uf8ff'),
            // orderBy("date", "desc"),
            orderBy("title", "desc"),

        );

        const fetchData = onSnapshot(q, (querySnapshot) => {
            setLoading(true);
            const userRecipes = querySnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                .sort((a, b) => b.date - a.date);
            setUserRecipes(userRecipes);
            setLoadingSearch(false);

            if (recipeId) {
                const recipeDataFromPath = userRecipes.find(r => r.id === recipeId);

                if (!recipeDataFromPath) {
                    return;
                }

                setActiveRecipe(recipeDataFromPath);
                setShowRecipeModal(true);
            }

            setLoading(false);
        });

        return fetchData;

    }, [searchTerm, userId])


    // SHOW SAVED RECIPES BASED ON USER ID
    const [userSavedRecipes, setUserSavedRecipes] = useState([])

    useEffect(() => {
        if (!userId) {
            return;
        }
        setLoading(true);
        const fetchSavedRecipesData = async () => {
            if (userId) {
                const savedRecipes = await fetchSavedRecipes(db, userId);
                setUserSavedRecipes(savedRecipes);
            }
        };

        if (recipeId) {
            const recipeDataFromPath = userRecipes.find(r => r.id === recipeId);

            if (!recipeDataFromPath) {
                return;
            }

            setActiveRecipe(recipeDataFromPath);
            setShowRecipeModal(true);
        }
        fetchSavedRecipesData();
        setLoading(false);
        setLoadingSearch(false);
    }, [userId]);

    // SWITCH TABS
    const [activeTab, setActiveTab] = useState('createdTab');

    const openTab = (tabId) => {
        setActiveTab(tabId);
    };




    // CREATE RECIPE
    const {
        showCreateRecipeModal,
        setShowCreateRecipeModal,
        showRecipeFromUrlModal,
        setShowRecipeFromUrlModal,
        // handleOpenCreateModal,
        handleOpenCreateRecipeFromUrlModal,
        handleOpenGenerateRecipeModal,
    } = useCreateRecipeModal();

    const handleOpenCreateModal = () => {
        setShowCreateRecipeModal(true);
    }

    // EDIT MODAL
    const [showEditModal, setShowEditModal] = useState(false)

    const handleEdit = (recipe) => {
        if (!auth.currentUser) {
            navigate('/account-modal');
        } else {
            if (user && user.uid === recipe.userId) {
                setActiveRecipe(recipe)
                setShowEditModal(true);
            } else {
                // Alert.alert('Error', 'You can only edit your own recipes.');
            }
        }
    };

    // HANDLE SCHEDULE RECIPE
    const [showDatePicker, setShowDatePicker] = useState(false)

    const handleScheduleRecipe = (recipe) => {

        setActiveRecipe(recipe)
        const activeRecipeRef = doc(db, "recipes", activeRecipe.id)
        updateDoc(activeRecipeRef, {
            userId: userId,
            date: recipeForm.date
        })

        setShowDatePicker(false)
        toast.success(`Nice one! "${recipe.title}" updated successfully`);
    }

    // VIEW RECIPE DETAIL
    const [showRecipeModal, setShowRecipeModal] = useState(false)

    const [recipeLink, setRecipeLink] = useState("");

    const handleOpenRecipeModal = (recipe) => {
        setShowRecipeModal(true)
        setActiveRecipe(recipe);
        navigate(`/recipes/${recipe.id}`)
        const currentUrl = window.location.href;
        setRecipeLink(currentUrl)
    }

    useEffect(() => {
        onSnapshot(recipesCollectionRef, snapshot => {
            setRecipes(snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    viewing: false,
                    ...doc.data()
                }
            }))
        })
    }, [])

    const handleRecipeModalCancel = () => {
        setShowRecipeModal(false)
        console.log('Cancel')
        navigate('/recipes')
    }

    // DELETE RECIPE
    const removeRecipe = (id) => {
        if (userId) {
            const savedRecipesRef = collection(db, 'users', userId, 'savedRecipes');
            deleteDoc(doc(db, "recipes", id));
            deleteDoc(doc(savedRecipesRef, id));
            toast.success(`Recipe successfully deleted`);
            setShowRecipeModal(false);
            navigate('/recipes');
        }
    }


    // COPY RECIPE LINK
    const handleCopyLink = (recipe) => {
        navigator.clipboard.writeText(recipeLink)
            .then(() => {
                console.log(recipeLink)
                toast.success('Link copied')
            })
            .catch((error) => {
                console.error('Failed to copy link to clipboard', error);
            })
    }

    // SAVE RECIPE
    const handleSaveRecipeClick = async (recipe) => {
        if (userId) {
            const success = await handleSaveRecipe(db, userId, recipe);
            if (success) {
                setUserRecipes((prevRecipes) =>
                    prevRecipes.map((prevRecipe) =>
                        prevRecipe.id === recipe.id ? { ...prevRecipe, savedByCurrentUser: true } : prevRecipe
                    )
                );
            }
        }
    };

    const handleUnsaveRecipeClick = async (recipe) => {
        if (userId) {
            const success = await handleUnsaveRecipe(db, userId, recipe);
            if (success) {
                setUserRecipes((prevRecipes) =>
                    prevRecipes.map((prevRecipe) =>
                        prevRecipe.id === recipe.id ? { ...prevRecipe, savedByCurrentUser: false } : prevRecipe
                    )
                );
            }
        }
    };


    return (
        <div>
            {loading ? <SkeletonLoader cardCount={15} /> :
                <div className="Page-container" style={{ backgroundColor: colors.background }}>
                    <div className="Page-small">
                        <div className="Page-header">
                            <div className='Page-title'>
                                <h2 className="flex-grow" style={{ color: colors.text }}>
                                    Recipes
                                </h2>
                                <Button
                                    appearance="primary"
                                    name="New"
                                    iconBefore={<Plus />}
                                    onClick={handleOpenCreateModal}
                                />
                            </div>

                            <SearchBar
                                placeholder="Search recipes"
                                value={searchTerm}
                                onChange={handleSearch}
                                appearance="default"
                            />
                        </div>

                        <div className="Tabs-controls">
                            <div
                                className={activeTab === 'createdTab' ? 'Tab Tab-active' : 'Tab'}
                                onClick={() => openTab('createdTab')}
                                style={{ backgroundColor: colors.background, color: colors.text }}
                            >
                                My creation
                            </div>
                            <div
                                className={activeTab === 'savedTab' ? 'Tab Tab-active' : 'Tab'}
                                onClick={() => openTab('savedTab')}
                                style={{ backgroundColor: colors.background, color: colors.text }}
                            >
                                Saved recipes
                            </div>
                        </div>

                        <div className="tab-content">
                            {activeTab === 'createdTab' && (
                                <div className="tab" id="createdTab">
                                    {/* SHOW CREATED RECIPES */}
                                    {userRecipes.length === 0
                                        ?
                                        <EmptyState
                                            imageUrl={require("../images/3d_illos_cook-fried-rice.png")}
                                            title="Ready to unleash your inner chef?"
                                            description="Use this space to create something unique to your and your loved ones"
                                            primaryButtonIcon={<NotePencil />}
                                            primaryButtonName="New recipe"
                                            primaryButtonOnClick={handleOpenCreateModal}
                                        />
                                        :
                                        <>
                                            {loadingSearch
                                                ?
                                                (<p> Loading </p>)
                                                :
                                                userRecipes.length > 0
                                                    ?
                                                    (<ul className='Recipe-card-list Recipe-grid' >
                                                        {userRecipes.sort((a, b) => a.dateAdded - b.dateAdded).map((recipe, event, index) => (
                                                            <RecipeCard
                                                                key={recipe.id}
                                                                recipe={recipe}
                                                                imageUrl={recipe.imageUrl}
                                                                type={recipe.type}
                                                                onClick={() => handleOpenRecipeModal(recipe)}
                                                                showSchedule={true}
                                                                showIsSaved={true}
                                                                handleUnsaveRecipe={() => handleUnsaveRecipeClick(recipe)}
                                                                handleSaveRecipe={() => handleSaveRecipeClick(recipe)}
                                                                showMoreMenu={true}
                                                                savedByCurrentUser={recipe.savedByCurrentUser}
                                                                onEdit={() => handleEdit(recipe)}
                                                            />
                                                        ))}
                                                    </ul>)
                                                    :
                                                    (<p>No results found</p>
                                                    )
                                            }
                                        </>
                                    }
                                </div>
                            )}

                            {activeTab === 'savedTab' && (
                                <div className="tab" id="savedTab">
                                    {/* SAVED RECIPES */}
                                    {userSavedRecipes.length === 0
                                        ?
                                        <EmptyState
                                            imageUrl={require("../images/3d_illos_cook-fried-rice.png")}
                                            title="Ready to unleash your inner chef?"
                                            description="Use this space to create something unique to your and your loved ones"
                                            primaryButtonIcon={<NotePencil />}
                                            primaryButtonName="New recipe"
                                            primaryButtonOnClick={handleOpenCreateModal}
                                        />
                                        :
                                        <>
                                            {loadingSearch
                                                ?
                                                (<p> Loading </p>)
                                                :
                                                userSavedRecipes.length > 0
                                                    ?
                                                    (<ul className='Recipe-card-list Recipe-grid' >
                                                        {userSavedRecipes.sort((a, b) => a.dateAdded - b.dateAdded).map((recipe, event, i) => (
                                                            <RecipeCard
                                                                key={recipe.id}
                                                                recipe={recipe}
                                                                imageUrl={recipe.imageUrl}
                                                                type={recipe.type}
                                                                onClick={() => handleOpenRecipeModal(recipe)}
                                                                showSchedule={true}
                                                                showIsSaved={true}
                                                                handleUnsaveRecipe={() => handleUnsaveRecipeClick(recipe)}
                                                                handleSaveRecipe={() => handleSaveRecipeClick(recipe)}
                                                                showMoreMenu={false}
                                                                onEdit={() => handleEdit(recipe)}
                                                                savedByCurrentUser={recipe.savedByCurrentUser}
                                                                style={{ width: "calc((100% - 24px)/3) !important;" }}
                                                            />
                                                        ))}
                                                    </ul>)
                                                    :
                                                    (<p>No results found</p>
                                                    )
                                            }
                                        </>
                                    }
                                </div>
                            )}
                        </div>


                        <br></br>

                        {showRecipeModal ?
                            <RecipeModal
                                onOk={handleRecipeModalCancel}
                                onEdit={(recipe) => { setShowEditModal(activeRecipe) }}
                                onCancel={handleRecipeModalCancel}
                                onCopyLink={(recipe) => handleCopyLink(activeRecipe)}
                                onSmartIngredients={false}
                                onDelete={() => removeRecipe(activeRecipe.id)}
                                name={activeRecipe.title}
                                imageUrl={activeRecipe.imageUrl}
                                description={activeRecipe.description}
                                author={activeRecipe.author}
                                authorPhoto={activeRecipe.authorPhoto}
                                type={activeRecipe.type}
                                typeColor="volcano"
                                cuisine={activeRecipe.cuisine}
                                diets={activeRecipe.diets}
                                difficulty={activeRecipe.difficulty}
                                cookTime={activeRecipe.cookTime}
                                cost={activeRecipe.cost}
                                linkSource={activeRecipe.linkSource}
                                date={activeRecipe.date}
                                ingredientsEditorContent={activeRecipe.ingredientsEditorContent}
                                stepsEditorContent={activeRecipe.stepsEditorContent}
                                isSaved={activeRecipe.saved}
                            />

                            : null
                        }

                        {showEditModal && (
                            <CreateRecipeModal
                                recipeId={activeRecipe.id}
                                recipeData={activeRecipe}
                                isEditing={true}
                                open={() => setShowEditModal(true)}
                                onOk={() => {
                                    setShowEditModal(false); // Close the modal after saving
                                }}
                                onSubmit={updatedRecipeData => setActiveRecipe(updatedRecipeData)}
                                onCancel={() => setShowEditModal(false)}
                            />
                        )}

                        {showCreateRecipeModal && (
                            <CreateRecipeModal
                                recipe={activeRecipe}
                                open={() => setShowCreateRecipeModal(true)}
                                onOk={() => {
                                    setShowCreateRecipeModal(false); // Close the modal after saving
                                }}
                                onCancel={() => setShowCreateRecipeModal(false)}
                            />
                        )}
                    </div>
                </div>
            }
            <br></br>
            <br></br>
        </div>
    )
}

export default Recipe