import React from 'react';
import { useNavigate } from 'react-router-dom';
import CuisineTile from './CuisineTile';
import { cuisineList } from '../data/cuisineData';

const CuisineTiles = () => {

    const handleOnPress = cuisine => {
        navigate('/recipe-collection', { state: { cuisine } });
    };

    const navigate = useNavigate();

    const renderCuisineItem = (cuisine, i) => {
        return (
            <CuisineTile
                key={i}
                icon={cuisine.icon}
                name={cuisine.name}
                color={cuisine.color}
                onClick={() => handleOnPress(cuisine)}
            />
        );
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                padding: 8,
                paddingTop: 4,
            }}
        >
            {cuisineList.map(renderCuisineItem)}
        </div>
    );
};

export default CuisineTiles;
