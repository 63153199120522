import React from 'react'
import ListItem from '../components/ListItem'
import { CheckCircle } from 'phosphor-react'
import Button from '../components/Button.tsx'

const Pricing = () => {

    const plans = [
        {
            "title": "Individual",
            "pricePerMonth": 7.99,
            "pricePerYear": 69.99,
            "pricePerMonthPaidYearly": 5.80, //69.99 divided by 12
            "savingsIfPaidYearly": 27,
            "features": ["Create and store recipes", "Meal planning and organisation in calendar", "Manage grocery list", "Data sync across multiple devices"]
        },
        {
            "title": "Duo",
            "pricePerMonth": 11.99,
            "pricePerYear": 99.99,
            "pricePerMonthPaidYearly": 8.33, //99.99 divided by 12
            "savingsIfPaidYearly": 30,
            "features": ["Everything in individual plan", "2 accounts for a couple under one roof", "Combine taste and preferences", "Generate meal plan automatically "]
        },
        {
            "title": "Family",
            "pricePerMonth": 17.99,
            "pricePerYear": 149.99,
            "pricePerMonthPaidYearly": 12.50, //149.99 divided by 12
            "savingsIfPaidYearly": 30,
            "features": ["Everything in individual plan", "Up to 6 accounts for family members", "Shared meal planning across members", "Highly catered meal plan"]
        },
    ]

    const handleGetStarted = () => {
        console.log('Get started')
    }
    return (
        <div className='Page-container'>
            <div className='Page-small'>
                <br></br>
                <br></br>
                <div className='Page-header'>
                    <div className='Page-title'>
                        <h2 className='flex-grow Text-center'>Pricing</h2>
                    </div>
                </div>
                <h4 className='flex-grow Text-center' style={{ lineHeight: "28px" }}>Organise all your meals in one place.  <br></br> Get Bentofy Premium free for 1 month. </h4>
                <br></br>
                <br></br>
                <div className='Card-group'>
                    {plans.map((plan, i) => (
                        <div key={i} className='Card' style={{alignItems: "center"}}>
                            <h2 style={{ marginBottom: 8, fontWeight: 800 }}>{plan.title}</h2>
                            <div className='Tag' style={{ background: "#047857", color: "#FFF", padding: "4px 12px", borderRadius: 24, marginBottom: 8 }}> 1 month free</div>
                            
                            <div className='flex align-center'>
                                <h4 style={{ marginTop: 0 }}>AU$ {plan.pricePerMonthPaidYearly} per user / month</h4>
                                <div className='Tag' style={{ background: "#fcd34d", color: "#422006", padding: "2px 4px", fontWeight: 800, borderRadius: 24 }}> -{plan.savingsIfPaidYearly}%</div>
                            </div>
                            <ul className='List-container flex-grow'>
                                {plan.features.map((feature, i) => (
                                    <ListItem
                                        key={i}
                                        appearance="bordered"
                                        iconBefore={
                                            <span className="List-item-icon" style={{ borderRadius: 24, backgroundColor: "#E7FFF1", color: "#255F3D" }}>
                                                <CheckCircle size={20} />
                                            </span>
                                        }
                                        name={<p>{feature}</p>}
                                    />
                                ))}
                            </ul>
                            <Button appearance='primary Button-full' name="Get started" onClick={handleGetStarted} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Pricing