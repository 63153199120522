import { doc, updateDoc } from 'firebase/firestore';
import {
    CalendarBlank,
    CookingPot,
    CurrencyCircleDollar,
    ForkKnife,
    ImageSquare,
    Knife,
    LockKeyOpen,
    ListDashes,
    ListNumbers,
    Timer,
    Users,
    Lock,
    Trash,
} from 'phosphor-react';
import Modal from 'antd/es/modal/Modal'
import React, { useContext, useState } from 'react';
// import RecipeIngredients from './RecipeIngredients';
// import RecipeSteps from './RecipeSteps';
import Suggestion from './Suggestion.js';
import Button from '../Button.tsx'
import { useRecipeSubmission } from '../../controllers/recipeSubmission';
import { Select, InputNumber } from 'antd'
import { auth, db } from '../../firebase-config';
import { useTheme } from '../../styles/ThemeContext';
import RichTextEditor from '../RichTextEditor';
import moment from 'moment';

const RECIPE_TYPE_LIST = [
    {
        value: 'Breakfast',
        label: 'Breakfast',
    },
    {
        value: 'Lunch',
        label: 'Lunch',
    },
    {
        value: 'Dinner',
        label: 'Dinner',
    },
    {
        value: 'Snack',
        label: 'Snack',
    },
    {
        value: 'Side dish',
        label: 'Side dish',
    },
    {
        value: 'Dessert',
        label: 'Dessert',
    },
    {
        value: 'Eat out',
        label: 'Eat out',
    },
    {
        value: 'Takeaway',
        label: 'Takeaway',
    },
    {
        value: 'Others',
        label: 'Others',
    },
];
const CUISINE_TYPE_LIST = [
    {
        value: 'Japanese',
        label: '🍱 Japanese',
    },
    {
        value: 'Italian',
        label: '🍕 Italian',
    },
    {
        value: 'Chinese',
        label: '🍜 Chinese',
    },
    {
        value: 'Korean',
        label: '🍲 Korean',
    },
    {
        value: 'Spanish',
        label: '🥘 Spanish',
    },
    {
        value: 'Mexican',
        label: '🌮 Mexican',
    },
    {
        value: 'Indian',
        label: '🍛 Indian',
    },
    {
        value: 'Thai',
        label: '🍌 Thai',
    },
    {
        value: 'French',
        label: '🥐 French',
    },
    {
        value: 'American',
        label: '🍔 American',
    },
    {
        value: 'Others',
        label: 'Others',
    },
];
const DIETARY_TYPE_LIST = [
    {
        value: 'None',
        label: 'None',
    },
    {
        value: 'Vegetarian',
        label: '🥦 Vegetarian',
    },
    {
        value: 'Vegan',
        label: '🌿 Vegan',
    },
    {
        value: 'Gluten-free',
        label: '🌾 Gluten-free',
    },
    {
        value: 'Pescetarian',
        label: '🐟 Pescetarian',
    },
];
const DIFFICULTY_LIST = [
    {
        value: 'Easy',
        label: '🍴 Easy',
    },
    {
        value: 'Medium',
        label: '⏲ Medium',
    },
    {
        value: 'Hard',
        label: '🔪 Hard',
    },
];
const COOK_TIME_LIST = [
    {
        value: 'Less than 15 mins',
        label: 'Less than 15 mins',
    },
    {
        value: '15 - 30 mins',
        label: '15 - 30 mins',
    },
    {
        value: '30 mins - 1 hour',
        label: '30 mins - 1 hour',
    },
    {
        value: '1 - 2 hours',
        label: '1 - 2 hours',
    },
    {
        value: 'More than 2 hours',
        label: 'More than 2 hours',
    },
];

const CreateRecipeModal = ({
    open,
    isEditing,
    recipeId,
    recipeData,
    onSubmit,
    onCancel,
    recipeDate,
    onDelete,
    editMode,
    handleCreate,
}) => {
    // THEMING
    const { colors } = useTheme();

    const selectWidth = "100%";

    const [recipe, setRecipe] = useState(
        recipeData || {
            title: '',
            type: '',
            description: '',
            imageUrl: '',
            ingredients: '',
            ingredientsEditorContent: '',
            steps: '',
            stepsEditorContent: '',
            cuisine: '',
            diets: '',
            difficulty: '',
            cost: '',
            cookTime: '',
        }
    );

    const [ingredients, setIngredients] = useState(recipeData?.ingredients || []);
    const [steps, setSteps] = useState(recipeData?.steps || []);

    // const { userId } = useContext(AuthContext);
    const userId = auth.currentUser && auth.currentUser.uid;

    // CREATE OR EDIT
    const { onRecipeSubmit } = useRecipeSubmission();

    // ACCESS
    const [access, setAccess] = useState(recipeData?.access || 'private');

    // DATES
    const formattedDate = recipeDate
        ? moment(recipeDate).format("YYYY-MM-DD")
        : recipe.date
            ? recipe.date
            : moment(new Date()).format("YYYY-MM-DD");

    const handleSave = async (event) => {


        event.preventDefault();
        const recipeWithUserId = {
            ...recipe,
            userId,
            date: formattedDate,
            access,
            steps,
            ingredients,
        };

        console.log(recipeWithUserId);

        try {
            if (isEditing) {
                // Edit mode
                const recipeRef = doc(db, 'recipes', recipeId);
                await updateDoc(recipeRef, recipeWithUserId);
            } else {
                // Create mode
                await onRecipeSubmit(recipeWithUserId);
            }

            onSubmit && onSubmit(recipeWithUserId);
            onCancel();
        } catch (error) {
            console.error('Error saving recipe', error);
        }
    };

    // const [suggestedImages, setSuggestedImages] = useState([]);
    //   useEffect(() => {
    //     const fetchSuggestions = async () => {
    //       const suggestions = await fetchImageSuggestions(recipe.title);
    //       setSuggestedImages(suggestions);
    //     };
    //     fetchSuggestions();
    //   }, [recipe.title]);

    // EXPAND / COLLAPSE
    const [showDescriptionEditor, setShowDescriptionEditor] = useState(false);
    const [showIngredientsEditor, setShowIngredientsEditor] = useState(false);
    const [showStepsEditor, setShowStepsEditor] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false)


    const handleShowDescriptionEditor = () => {
        setShowDescriptionEditor(!showDescriptionEditor)
    }

    const handleShowIngredientsEditor = () => {
        setShowIngredientsEditor(!showIngredientsEditor)
    }

    const handleShowStepsEditor = () => {
        setShowStepsEditor(!showStepsEditor)
    }

    const handleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    // ACCESS


    return (

        <Modal
            title={
                <div className='Button-group'>
                    {/* <Button appearance="icon" iconBefore={<Trash />} onClick={onDelete} /> */}
                    {/* <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 8,
                            backgroundColor: colors.default,
                            padding: 12,
                            borderRadius: 24,
                            paddingHorizontal: 16,
                            cursor: 'pointer'
                        }}
                        onClick={() =>
                            setAccess(prevAccess =>
                                prevAccess === 'private' ? 'public' : 'private'
                            )
                        }
                    >
                        <Lock size={24} color={colors.iconSubtle} />
                        <p
                            style={
                                { color: colors.textSubtle, margin: 0, marginRight: 8, flexGrow: 1 }
                            }
                        >
                            Make public
                        </p>
                        <input
                            type="checkbox"
                            checked={access === 'public'}
                            value={access === 'public'}
                            onChange={() =>
                                setAccess(prevAccess =>
                                    prevAccess === 'private' ? 'public' : 'private'
                                )
                            }
                        />
                    </div> */}
                    <Button type="button" name="Cancel" appearance="default" onClick={onCancel} />
                    <Button type="submit" name={isEditing ? "Save" : "Create"} appearance="primary" onClick={handleSave} />
                </div>
            }
            open={open}
            onCancel={onCancel}
            width={880}
            footer={null}
            maskTransitionName=""
            style={{ top: 24, }}
        >
            <div className='Modal-content-wrapper' >
                <img
                    className="Recipe-cover"
                    style={{ width: '100%', height: recipe.imageUrl ? 240 : 0 }}
                    src={recipe.imageUrl ? recipe.imageUrl : undefined}
                />
                <div className="Modal-content-container">
                    <form className='Form' onSubmit={handleSave} style={{ display: 'flex', flexDirection: 'column' }}>
                        {isEditing ? (

                            <input
                                // ref={recipeTitle}
                                autoFocus
                                type="text"
                                placeholder="New meal or recipe"
                                className='Form-input-title'
                                value={recipe.title}
                                onChange={event => {
                                    setRecipe(prevRecipe => ({ ...prevRecipe, title: event.target.value }));
                                }}
                            />
                        ) : (
                            // <Suggestion setRecipe={setRecipe} />
                            <div className='Form-input-container'>
                                {/* <label htmlFor="name">Recipe name</label> */}
                                <input
                                    // ref={recipeTitle}
                                    autoFocus
                                    type="text"
                                    placeholder="New meal or recipe"
                                    className='Form-input-title'
                                    value={recipe.title}
                                    onChange={event => {
                                        setRecipe(prevRecipe => ({ ...prevRecipe, title: event.target.value }));
                                    }}
                                />
                            </div>
                        )}


                        {showDescriptionEditor ?
                            <div className='flex' style={{ flexDirection: "column", marginTop: 1 }}>
                                <RichTextEditor
                                    value={recipe.description}
                                    placeholder="Share a little more about this dish. What or who inspired you to cook it? What makes it special to you? What's your favourite way to eat it?"
                                    onChange={text => setRecipe({ ...recipe, description: text })}
                                />
                                <Button appearance="subtle Button-full" name="Cancel" onClick={handleShowDescriptionEditor} />
                            </div>
                            :
                            <p onClick={handleShowDescriptionEditor} className='Editor-toggle-button'>
                                Share a little more about this dish by adding a description
                            </p>
                        }
                        <div className='Form-input-container'>
                            <label><ImageSquare />Cover image</label>
                            <input
                                type="text"
                                placeholder='Paste image url here'
                                className='Form-input'
                                value={recipe.imageUrl}
                                onChange={event => setRecipe({ ...recipe, imageUrl: event.target.value })}
                            />
                        </div>

                        <h3 style={{ marginBottom: 8 }}>Ingredients</h3>
                        <div className='flex' style={{ flexDirection: "column", marginTop: 1 }}>
                            <RichTextEditor
                                value={recipe.ingredientsEditorContent}
                                placeholder="Type ingredients"
                                onChange={text => setRecipe({ ...recipe, ingredientsEditorContent: text })}
                            />
                        </div>

                        <h3 style={{ marginBottom: 8 }}>Steps</h3>
                        <div className='flex' style={{ flexDirection: "column", marginTop: 1 }}>
                            <RichTextEditor
                                value={recipe.stepsEditorContent}
                                placeholder="Type steps"
                                onChange={text => setRecipe({ ...recipe, stepsEditorContent: text })}
                            />
                        </div>


                        <div>


                            <div className='Recipe-section-tabs-container' >
                                <div className='Recipe-section-tabs'>
                                    <label><ForkKnife size={24} color={colors.iconSubtle} /> Type</label>
                                    <Select
                                        defaultValue={"Dinner"}
                                        placeholder={"Select type"}
                                        size={"large"}
                                        style={{ width: selectWidth }}
                                        value={recipe.type}
                                        onChange={value =>
                                            setRecipe(prevRecipe => ({ ...prevRecipe, type: value }))
                                        }
                                        options={RECIPE_TYPE_LIST}
                                    />
                                </div>
                                <div className='Recipe-section-tabs'>
                                    <label><CalendarBlank /> Plan for</label>
                                    <input type="date"
                                        value={recipe.date}
                                        onChange={event =>
                                            setRecipe(prevRecipe => ({ ...prevRecipe, date: event.target.value }))
                                        }
                                    />
                                </div>
                                <div className='Recipe-section-tabs'>
                                    <label><CookingPot size={24} color={colors.iconSubtle} /> Cuisine</label>
                                    <Select
                                        defaultValue={"None"}
                                        placeholder={"Select cuisine"}
                                        size={"large"}
                                        style={{ width: selectWidth }}
                                        value={recipe.cuisine}
                                        onChange={value =>
                                            setRecipe(prevRecipe => ({ ...prevRecipe, cuisine: value }))
                                        }
                                        options={CUISINE_TYPE_LIST}
                                    />
                                </div>
                            </div>
                            <div className='Recipe-section-tabs-container' >
                                <div className='Recipe-section-tabs'>
                                    <label><Users size={24} color={colors.iconSubtle} /> Dietary</label>
                                    <Select
                                        defaultValue={"None"}
                                        placeholder={"Select diet"}
                                        size={"large"}
                                        style={{ width: selectWidth }}
                                        value={recipe.diets}
                                        onChange={value =>
                                            setRecipe(prevRecipe => ({ ...prevRecipe, diets: value }))
                                        }
                                        options={DIETARY_TYPE_LIST}
                                    />
                                </div>
                                <div className='Recipe-section-tabs'>
                                    <label><Knife size={24} color={colors.iconSubtle} /> Difficulty</label>
                                    <Select
                                        defaultValue={"None"}
                                        placeholder={"Select difficulty"}
                                        size={"large"}
                                        style={{ width: selectWidth }}
                                        value={recipe.difficulty}
                                        onChange={value =>
                                            setRecipe(prevRecipe => ({ ...prevRecipe, difficulty: value }))
                                        }
                                        options={DIFFICULTY_LIST}
                                    />
                                </div>
                                <div className='Recipe-section-tabs'>
                                    <label><Timer size={24} color={colors.iconSubtle} /> Cook time</label>
                                    <Select
                                        defaultValue={"None"}
                                        placeholder={"Select cook time"}
                                        size={"large"}
                                        style={{ width: selectWidth }}
                                        value={recipe.cookTime}
                                        onChange={value =>
                                            setRecipe(prevRecipe => ({ ...prevRecipe, cookTime: value }))
                                        }
                                        options={COOK_TIME_LIST}
                                    />
                                </div>

                            </div>
                            <div className='Recipe-section-tabs'>
                                <label><CurrencyCircleDollar />Cost</label>
                                <InputNumber
                                    defaultValue={0}
                                    placeholder={"Estimated cost"}
                                    size={"large"}
                                    style={{ width: selectWidth }}
                                    value={recipe.cost}
                                    onChange={value =>
                                        setRecipe(prevRecipe => ({ ...prevRecipe, cost: value }))
                                    }
                                    prefix="~ $"
                                />
                            </div>
                        </div>


                    </form>
                </div>
            </div >

            <div>

                <div style={{ padding: 16, marginBottom: 60 }}>



                    {/* <RecipeIngredients
                        ingredients={ingredients}
                        setIngredients={setIngredients}
                    /> */}
                    {/* <div
              style={{ flexDirection: 'row', gap: 8, alignItems: 'flex-start' }}
            >
              <ListDashes size={24} color={colors.iconSubtle} />
              <TextInput
                placeholder="Ingredients"
                value={recipe.ingredientsEditorContent}
                onChangeText={text =>
                  setRecipe({ ...recipe, ingredientsEditorContent: text })
                }
                placeholderTextColor={colors.textSubtle}
                style={[
                  typography.p,
                  styles.input,
                  {
                    flexGrow: 1,
                    minHeight: 80,
                    borderColor: colors.border,
                    color: colors.text,
                    paddingBottom: 8,
                  },
                ]}
                multiline
              />
            </div> */}

                    {/* <RecipeSteps steps={steps} setSteps={setSteps} /> */}

                    {/* <div
              style={{ flexDirection: 'row', gap: 8, alignItems: 'flex-start' }}
            >
              <ListNumbers size={24} color={colors.iconSubtle} />
              <TextInput
                placeholder="Steps"
                value={recipe.stepsEditorContent}
                onChangeText={text =>
                  setRecipe({ ...recipe, stepsEditorContent: text })
                }
                placeholderTextColor={colors.textSubtle}
                style={[
                  typography.p,
                  styles.input,
                  {
                    flexGrow: 1,
                    minHeight: 80,
                    borderColor: colors.border,
                    color: colors.text,
                    paddingBottom: 8,
                  },
                ]}
                multiline
              />
            </div> */}




                </div>
            </div>
        </Modal >
    );
};

export default CreateRecipeModal;

const pickerSelectStyles = {
    inputIOS: {
        fontSize: 16,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 8,
        paddingRight: 32,
        marginTop: 4,
        marginBottom: 8,
    },
    inputAndroid: {
        fontSize: 16,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 8,
        paddingRight: 32,
        marginTop: 4,
        marginBottom: 8,
    },
    viewContainer: {
        flexGrow: 1,
    },
    iconContainer: {
        top: 12,
        right: 12,
    },
};
