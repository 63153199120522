import React from 'react'
import Twemoji from 'react-twemoji'

const PrivacyPolicy = () => {

    const emojiHeader = (headerEmoji, headerText) => {
        return (
            <div className='flex'>
                <Twemoji className="Twemoji-small">{headerEmoji}</Twemoji>
                <h3 style={{ marginBottom: 8, fontWeight: 700 }}>{headerText}</h3>
            </div>
        )
    }
    return (
        <div className='Page-container'>
            <div className='Page-xsmall'>
                <br></br>
                <br></br>
                <div className='Page-header'>
                    <div className='Page-title'>
                        <h2 className='flex-grow Text-center'>
                            Privacy Policy
                        </h2>
                    </div>
                </div>
                {emojiHeader("💿", "Data")}
                <p>Bentofy collects and stores the information provided upon sign up. This include standard logs like IP address and browser information. We also store avatars and user submitted content such as recipes, ingredients, grocery list, and preferences. None of this information is sold or shared with third parties. </p>
                <br></br>
                {emojiHeader("💳", "Billing information")}
                <p>All billing information is stored by Stripe. Bentofy stores cryptographic hashes of Stripe subscription and your Stripe account ID. Bentofy does not store any credit card information or addresses on file. For personalised payouts not using Stripe, additional contact information may be stored</p>
                <br></br>
                {emojiHeader("🔑", "Passwords")}
                <p>Bentofy stores a cryptographic hash of your password using Google Cloud authentication. We do not store passwords in plain text.</p>
                <br></br>
                {emojiHeader("🐾", "Tracking")}
                <p>Bentofy doesn&apos;t sell user data, nor do we use ad targetting or tracking.</p>
                <br></br>   
                {emojiHeader("🗑", "Account deletion")}
                <p>Currently there&apos;s no account deletion, not by design, I just haven&apos;t got to it yet. If you want to delete your account, contact me and I will do that on my best effort when done manually. Removal of accounts includes removal of user info (email, passwords, etc,), avatar, submitted content and related media, Stripe metadata </p>
                <br></br>
                {emojiHeader("📍", "Location of data")}
                <p>Main servers are located in Singapore. Elements of the site may be cached by edge servers around the world</p>
                <br></br>
                {emojiHeader("🙏", "Guarantees")}
                <p>Bentofy makes no guarantees regarding uptime, payouts, accuracy of monetary transfers, refunds, or timeliness. I&apos;m just running this by myself and very much in startup phase</p>
                <br></br>
                {emojiHeader("📥", "Point of contact")}
                <p>If you need to contact Bentofy for any reason, ping me at hello@bentofy.app</p>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    )
}

export default PrivacyPolicy