import React from 'react'
import { MagnifyingGlass } from 'phosphor-react'

const SearchBar = (props) => {
  return (
    <div className="Search-bar">
        <MagnifyingGlass size={20}/>
        <input type="text" 
          placeholder={props.placeholder} 
          value={props.value}
          onChange={props.onChange} 
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          className={`Search-input Search-input-${props.appearance}`} 
        />
    </div>
  )
}

export default SearchBar