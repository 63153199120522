import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createBrowserRouter,
  Routes,
  Route,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Root from "./routes/root";

import Home from './routes/Home';
import MealPlan from "./routes/MealPlan";
import Groceries from './routes/Groceries';
import Insights from './routes/Insights';
import Preferences from './routes/Preferences';
import ErrorPage from './routes/ErrorPage';
import AppHome from './routes/AppHome';
import Onboarding from './routes/Onboarding';
import Login from './routes/Login';
import MainNavigation from './components/MainNavigation';
import Register from './routes/Register';
import Recipe from './routes/Recipe';
import BottomNavigation from './components/BottomNavigation.tsx';
import VerifyEmail from './routes/VerifyEmail';
import PrivateRoute from './routes/PrivateRoute';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';
import Search from './routes/Search';
import Pricing from './routes/Pricing';
import About from './routes/About';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsOfService from './routes/TermsOfService';
import RecipeDetail from './routes/RecipeDetail.js';
import { useTheme } from './styles/ThemeContext.js';
import RecipeCollection from './components/RecipeCollection.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


const AppRouter = () => {

  const [currentUser, setCurrentUser] = useState(null)
  const { colors } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      // navigate('/app-home');
    })
  }, [])

  return (
    <div>
      <MainNavigation style={{ backgroundColor: colors.background }} />
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/app-home" element={<AppHome />} />
        <Route path="/app-home/:recipeId" element={<AppHome />} />
        <Route path="/recipes" element={<Recipe />} />
        <Route path="/recipes/:recipeId" element={<Recipe />} />
        <Route path="/recipe-detail/:id" element={<RecipeDetail />} />
        <Route path="/recipe-collection" element={<RecipeCollection />} />
        <Route path="/plan" element={<MealPlan />} />
        <Route path="/plan/:recipeId" element={<MealPlan />} />
        <Route path="/groceries" element={<Groceries />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/preferences" element={<Preferences />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/search" element={<Search />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
      <BottomNavigation />
    </div>
  );
};

export default AppRouter;

