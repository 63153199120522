import React, { createContext, useState, useContext } from 'react';
import { colors as themeColors } from './theme';

export const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light'); // Set initial theme to 'light'

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const colors = {
    ...(theme === 'dark' ? themeColors.dark : themeColors.light),
    tab: {
      activeTintColor: theme === 'dark' ? '#FFFFFF' : '#000000',
      inactiveTintColor: theme === 'dark' ? '#a1a1aa' : '#999999',
      activeBackgroundColor: theme === 'dark' ? '#000000' : '#FFFFFF',
      inactiveBackgroundColor: theme === 'dark' ? '#222222' : '#EEEEEE',
    },
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, colors }}>
      {children}
    </ThemeContext.Provider>
  );
};