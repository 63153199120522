import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/functions';
import firebaseConfig from '../firebase-config';

const Search = () => {
    const [searchQuery, setSearchQuery] = useState('');
  const [matchingRecipes, setMatchingRecipes] = useState([]);

  // Initialize the Firebase app and functions
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const functions = firebase.functions();
  const searchRecipes = functions.httpsCallable('searchRecipes');

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    // Call the searchRecipes function with the search query
    searchRecipes({ query: searchQuery })
      .then((result) => {
        const matchingRecipes = result.data;
        setMatchingRecipes(matchingRecipes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className='Focused-task-container'>
        <p>Search</p>
        <div>
        <form onSubmit={handleSearchSubmit}>
            <input type="text" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
            <button type="submit">Search</button>
        </form>
        {matchingRecipes.map((recipe) => (
            <div key={recipe.id}>
            <h2>{recipe.name}</h2>
            <p>{recipe.description}</p>
            <ul>
                {recipe.ingredients.map((ingredient) => (
                <li key={ingredient}>{ingredient}</li>
                ))}
            </ul>
            <ol>
                {recipe.steps.map((step) => (
                <li key={step}>{step}</li>
                ))}
            </ol>
            </div>
        ))}
        </div>
    </div>
    
  )
}

export default Search




