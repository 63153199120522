import { Check, UploadSimple } from 'phosphor-react'
import React, {useState} from 'react'
import Twemoji from 'react-twemoji'
import Avatar from './Avatar.tsx'
import Button from './Button.tsx'

const AvatarModal = ({ onSave, initialEmoji, initialColor } ) => {

    const foodEmojis = ["🥚", "🍟", "🌶", "🍊", "🧀", "🍔", "🐟", "🍣", "🍱", "🍜", "🌮", "🥗", "🍕", "🥦", "🍆", "🍑"]
    const backgroundColors = ["#F4F5F7", "#FCDCDB", "#FFCAD1", "#FFD195", "#FFF0CD", "#D3FFDA", "#DAEFFF", "#BED0FF"]


    const [selectedColor, setSelectedColor] = useState(initialColor || "#000000");

    const handleColorChange = (event) => {
        setSelectedColor(event.target.value);
    }

    const [selectedEmoji, setSelectedEmoji] = useState(initialEmoji || "😀");
    const handleEmojiChange = (event) => {
        setSelectedEmoji(event.target.value);
    }

    const handleSaveAvatar = () => {
        onSave({ selectedColor, selectedEmoji });
    }


    return (
        <div className='Modal-container'>
            <h2>Customise avatar</h2>
            <Avatar
                size="xLarge"
                backgroundColor={selectedColor}
                emoji={selectedEmoji}
            />
            <h5>Emoji</h5>
            <ul className='Avatar-emoji-container'>
                {foodEmojis.map((emoji, i) => (
                    <li key={i} className={selectedEmoji === emoji ? "Avatar-emoji-select-active" : "Avatar-emoji-select"} >
                         <input 
                            type="radio" 
                            name="emoji" 
                            value={emoji} 
                            checked={selectedEmoji === emoji} 
                            onChange={handleEmojiChange}
                        />
                        <Twemoji className="Twemoji-small">{emoji}</Twemoji>
                    </li>
                ))}
            </ul>
            <h5>Color</h5>
            <ul className='Avatar-color-container'>
                {backgroundColors.map((color, i) => (
                    <li key={i} className={selectedColor === color ? "Avatar-color-select-active" : "Avatar-color-select"} 
                        style={{background: color}}
                        // onClick={props.onColorSelect}
                    >
                        <input 
                            type="radio" 
                            name="color" 
                            value={color} 
                            checked={selectedColor === color} 
                            onChange={handleColorChange}
                        />
                        {selectedColor === color ? <Check size={24}/> : null}
                    </li>
                ))}
            </ul>
            <br></br>
            <Button
                name="Upload image"
                iconBefore={<UploadSimple size={20}/>}
                appearance="default Button-full"
                // onClick={props.onUploadAvatar}
            />
            <br></br>
            <br></br>
            <div className='Button-group' style={{width: '100%'}}>
                {/* <Button
                    name="Cancel"
                    appearance="default Button-full"
                    // onClick={props.onCancel}

                /> */}
                <Button
                    name="Save"
                    appearance="primary Button-full"
                    // onClick={props.onSave}
                    onClick={handleSaveAvatar}
                />
            </div>
            
        </div>
    )
}

export default AvatarModal