import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = (props) => {
  const [content, setContent] = useState('');

  const handleChange = (value) => {
    setContent(value);
  };

  return (
    <ReactQuill 
      value={props.value} 
      placeholder={props.placeholder} 
      onChange={props.onChange}
    />
  );
};

export default RichTextEditor;
