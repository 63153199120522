import React, { useState } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import LoadingPage from './LoadingPage';
import { ScrollContainer, batch, FadeIn, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn, } from "react-scroll-motion";
import Carousel from '../components/Carousel';
import { ReactComponent as AppStoreButton } from '../images/download-on-the-app-store.svg';
import CustomerCarousel from '../components/CustomerCarousel';
import FeatureGrid from '../components/FeatureGrid';


const Home = () => {
  const [loading, setLoading] = useState(true)
  setTimeout(() => setLoading(false), 300);

  const navigate = useNavigate();

  const navigateToAppHome = () => {
    navigate('/app-home');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateToRecipes = () => {
    navigate('/recipes');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateToPlan = () => {
    navigate('/plan');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateToGroceries = () => {
    navigate('/groceries');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateToInsights = () => {
    navigate('/insights');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateToPreferences = () => {
    navigate('/preferences');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateToRegister = () => {
    navigate('/register');
  }

  const navigateToLogin = () => {
    navigate('/login');
  }

  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn(0.9, 1.0));
  const FadeUp = batch(FadeIn(), Sticky(), MoveIn(0, 100), ZoomIn(0.5, 1.0), MoveOut(0, -200));

  const Spin = (cycle) =>
  ({
    in: {
      style: {
        // `p` is number (0~1)
        // When just before this page appear, `p` will be 0
        // When this page filled your screen, `p` will be 1
        transform: (p) => `rotate(${p * 360 * cycle}deg)`,
      },
    },
    out: {
      style: {
        // `p` is number (0~1)
        // When this page filled your screen, `p` will be 0
        // When just after this page disappear, `p` will be 1
        transform: (p) => `rotate(${p * 360 * cycle}deg)`,
      },
    },
  });

  return (
    <>
      {loading ? <LoadingPage /> :
        <ScrollContainer style={{ width: "100%" }}>


          {/* <ScrollPage>
            <Animator animation={batch(Fade(), StickyIn(), FadeIn(), MoveIn(0,700), ZoomIn(0.9,1.0))}>
              <img src={require("../images/product-shot_main-schedule_empty.png")} alt="illustration" className='Section-hero-image' />
            </Animator>
            <Animator animation={batch(Fade(), StickyIn(), FadeIn(), MoveIn(0,900), ZoomIn(0.9,1.0))}>
              <img src={require("../images/product-shot_recipe-card_1.png")} alt="illustration" className='Section-hero-image'/>
            </Animator>
            <Animator animation={batch(Fade(), StickyIn(), FadeIn(), MoveIn(0,1000), ZoomIn(0.9,1.0))}>
              <img src={require("../images/product-shot_recipe-card_2.png")} alt="illustration" className='Section-hero-image'/>
            </Animator>
          </ScrollPage> */}


          <div className='Page-container'>
            <div className="Page-medium">
              <br></br>
              <br></br>

              <div className='Section-hero'>
                <div className='Section-hero-content'>
                  <br></br>
                  <h5>Introducing</h5>
                  <br></br>
                  <img src={require("../images/bento-full-logo.png")} className="Logo-full" alt="illustration" />
                  <br></br>
                  <br></br>
                  <h1>Organise your meals together in one place</h1>
                  <h3>Bentofy helps you and your loved ones plan, organise, and manage your meals in a quick and easy way </h3>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                    <a href="https://apps.apple.com/us/app/bentofy/id6470709729?platform=iphone">
                      <AppStoreButton />
                    </a>
                    <a href="https://www.producthunt.com/posts/bentofy?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-bentofy" target="_blank" rel="noopener noreferrer">
                      <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=476660&theme=light" alt="Bentofy - Organise all your meals in one place | Product Hunt"
                        style={{ width: '186px', height: '40px' }} width="250" height="54" />
                    </a>
                  </div>
                  {/* <div className='Button-group'>
                    
                    <Button name="Sign up" appearance="primary" onClick={navigateToRegister} iconAfter={<CaretRight />} />
                    <Button name="Sign in" appearance="default" onClick={navigateToLogin} iconAfter={<CaretRight />} />
                    
                  </div> */}
                  <br></br>
                  <br></br>
                  <br></br>
                </div>

                <Carousel />
              </div>

              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <div style={{ borderRadius: 40, overflow: "hidden" }}>
                <img src={require("../images/product-shot_bentofy.jpeg")} alt="illustration" className='Section-hero-image' />
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <div className="Section Column-reverse">
                <div className='Section-content-text'>
                  <br></br>
                  <br></br>
                  {/* <div className='Section-emoji'
                    style={{ background: "#FCD34D" }}
                  >
                    <Twemoji>🍳</Twemoji>
                  </div> */}

                  <img src={require("../images/3d_illos_cook-fried-rice.png")} alt="illustration" style={{ width: 200 }} />
                  <h5>Discover</h5>
                  <h2>Save your recipes in one place</h2>
                  <p style={{ fontSize: 16 }}>Create, save, and access your recipes from anywhere in one place.</p>
                  <br></br>
                  {/* <Button name="Discover recipes" appearance="default" onClick={navigateToRecipes} iconAfter={<CaretRight />} /> */}
                </div>
                <div className='Section-content-image'>
                  <img src={require("../images/product-shot_recipes.jpeg")} alt="illustration" />
                </div>
              </div>




              <br></br>

              <div className='Section'>

                <div className='Section-content-image'>
                  <img src={require("../images/product-shot_schedule.jpeg")} alt="illustration" />
                </div>

                <div className='Section-content-text'>
                  <br></br>
                  <br></br>
                  {/* <div className='Section-emoji'
                    style={{ background: "#D44F21" }}
                  >
                    <Twemoji>🗓</Twemoji>
                  </div> */}
                  <img src={require("../images/3d_illos_schedule.png")} alt="illustration" style={{ width: 200 }} />
                  <h5>Organise</h5>
                  <h2>Weekly meal planning made easy</h2>
                  <p>Plan ahead to save time and money by cooking more at home.</p>
                  <br></br>
                  {/* <Button name="Start planning" appearance="default" onClick={navigateToPlan} iconAfter={<CaretRight />} /> */}
                </div>
              </div>

              <br></br>

              <div className='Section Column-reverse'>
                <div className='Section-content-text'>
                  <br></br>
                  <br></br>
                  {/* <div className='Section-emoji'
                    style={{ background: "#268378" }}
                  >
                    <Twemoji>🧺</Twemoji>
                  </div> */}
                  <img src={require("../images/3d_illos_groceries.png")} alt="illustration" style={{ width: 240 }} />
                  <h5>TRACK</h5>
                  <h2>Groceries at your fingertips</h2>
                  <p>Track your groceries and automatically add from your weekly plan.</p>
                  <br></br>
                  {/* <Button name="Create list" appearance="default" onClick={navigateToGroceries} iconAfter={<CaretRight />} /> */}
                </div>
                <div className='Section-content-image'>
                  <img src={require("../images/product-shot_groceries.jpeg")} alt="illustration" />
                </div>
              </div>

              <br></br>
              <FeatureGrid />
              {/* 
              <div className='Section'>
                <div className='Section-content-image'>
                  <img src={require("../images/product-shot_preferences.jpg")} alt="illustration" />
                </div>
                <div className='Section-content-text'>
                  <br></br>
                  <br></br>
                  <div className='Section-emoji'
                    style={{ background: "#3F3F46" }}
                  >
                    <Twemoji>🥂</Twemoji>
                  </div>
                  <h5>Personalise</h5>
                  <h2>Taste personal, taste together</h2>
                  <p>Our AI powered algorithm takes into account not just your taste, preferences, and allergies, but also the weather, season, time of the day, and special occassions to help you discover new recipe</p>
                  <br></br>
                </div>

              </div> */}
              <br></br>
              <br></br>

              <div className='Center'>
                <small>Copyright © 2024 by <a href="https://www.jamesrotanson.com/" >James Rotanson</a> · ☕️ Buy me a <a href="https://www.buymeacoffee.com/jamesrotanson">coffee</a> ! </small>
              </div>

              <br></br>

            </div>
          </div>
        </ScrollContainer>
      }
    </>
  );
};

export default Home;
