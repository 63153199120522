import React from 'react'
import { useTheme } from '../styles/ThemeContext';
import EmptyState from './EmptyState';
import { Plus } from 'phosphor-react'
import RecipeListItem from './RecipeListItem';
import Button from '../components/Button.tsx';
import { CalendarBlank } from 'phosphor-react';

const TodayMeals = ({ userId, todayMeals, handleOpenRecipeModal, handleOpenCreateModal, handleNavigateToSchedule }) => {
    const { colors } = useTheme();

    return (
        <div>

            {userId ? (
                <div
                    style={{
                        backgroundColor: colors.default,
                        margin: 8,
                        padding: 8,
                        borderRadius: 24,
                    }}
                >

                    {todayMeals.length > 0 ? (
                        <div>
                            {todayMeals.map((recipe, index) => (
                                <RecipeListItem
                                    key={recipe.id}
                                    recipe={recipe}
                                    onClick={() => handleOpenRecipeModal(recipe)}
                                />
                            ))}
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', }}>
                                <Button
                                    name="Add meal"
                                    appearance="subtle"
                                    iconBefore={<Plus size={16} color={colors.icon} />}
                                    onClick={handleOpenCreateModal}
                                    style={{ width: '100%' }}
                                />

                            </div>
                        </div>
                    ) : (
                        <div style={{ alignItems: 'center', paddingTop: 16 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: 320, height: 200, }} src={require("../images/3d_illos_sushi.png")} alt="Empty today meal illustration" />
                            </div>
                            <EmptyState
                                title="Nothing planned for today"
                                description="Cooking at home saves you up to 75% compared to eating out"
                                primaryButtonIcon={
                                    <Plus size={16} color={colors.iconInverse} />
                                }
                                primaryButtonName="Add meal"
                                primaryButtonOnClick={handleOpenCreateModal}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: colors.default,
                        margin: 8,
                        padding: 8,
                        borderRadius: 24,
                        alignItems: 'center',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: 320, height: 200, }} src={require("../images/3d_illos_sushi.png")} alt="Empty today meal illustration" />
                    </div>
                    <EmptyState
                        title="Nothing planned for today"
                        description="Cooking at home can save you up to 75% compared to eating out"
                        primaryButtonIcon={
                            <CalendarBlank size={16} color={colors.iconInverse} />
                        }
                        primaryButtonName="Start planning"
                        primaryButtonOnPress={handleNavigateToSchedule}
                    />
                </div>
            )}
        </div>
    )
}

export default TodayMeals