import React, {useState, useEffect} from 'react';
import {House, CookingPot, CalendarBlank, ShoppingCart, ChartPie, FadersHorizontal} from 'phosphor-react';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import {auth} from '../firebase-config';
import {NavLink, useNavigate } from 'react-router-dom';
import { useTheme } from '../styles/ThemeContext';

const BottomNavigation: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const {colors} = useTheme();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
      else {
        // console.log("not logged in")
      }
    });

    return () => unsubscribe();

  }, []);

  return (
    <>
        {user ? 
            <nav  >
                <ul className='Bottom-nav-container' style={{backgroundColor: colors.background}}>
                    <NavLink className="Bottom-nav-tab" to='/app-home' style={{backgroundColor: colors.background}}><House style={{color: colors.icon}}/><p style={{color: colors.text}}>Home</p></NavLink>
                    <NavLink className="Bottom-nav-tab" to='/recipes' style={{backgroundColor: colors.background}}><CookingPot style={{color: colors.icon}}/><p style={{color: colors.text}}>Recipes</p></NavLink>
                    <NavLink className="Bottom-nav-tab" to='/plan' style={{backgroundColor: colors.background}}><CalendarBlank style={{color: colors.icon}}/><p style={{color: colors.text}}>Schedule</p></NavLink>
                    <NavLink className="Bottom-nav-tab" to='/groceries' style={{backgroundColor: colors.background}}><ShoppingCart style={{color: colors.icon}}/><p style={{color: colors.text}}>Grocery</p></NavLink>
                    {/* <NavLink exact className="Bottom-nav-tab" activeClassName='is-active' to='/insights'><ChartPie/><p>Insights</p></NavLink> */}
                    <NavLink className="Bottom-nav-tab" to='/preferences' style={{backgroundColor: colors.background}}><FadersHorizontal style={{color: colors.icon}}/><p style={{color: colors.text}}>Preferences</p></NavLink>
                </ul>
            </nav>
            : null
        }
    </>
  );
};

export default BottomNavigation;
