import { useState, useEffect } from 'react';
import { auth } from '../firebase-config';
import { db } from "../firebase-config"
import {
    collection,
    addDoc,
    query,
    where,
    getDocs,
    setDoc,
    doc,
    orderBy,
    limit
} from "firebase/firestore"
import { toast } from "react-hot-toast";

export const useIngredientSubmission = () => {
    const userId = auth.currentUser && auth.currentUser.uid;
    const ingredientsCollectionRef = collection(db, "ingredients");

    // const [loading, setLoading] = useState(false)
    const mergeIngredients = async (title) => {

        if (!title) {
            return null;
        }

        const existingIngredientQuery = query(ingredientsCollectionRef, where("title", "==", title), where("userId", "==", userId));
        const querySnapshot = await getDocs(existingIngredientQuery);
        const existingIngredients = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        return existingIngredients.length > 0 ? existingIngredients[0] : null
    }

    const onIngredientSubmit = async ({
        title,
        checked,
        count
        
    }) => {

        const existingIngredients = await mergeIngredients(title);

        if (existingIngredients){
            const updatedIngredient = {
                ...existingIngredients,
                checked: checked !== undefined ? checked : existingIngredients.checked,
                count: (existingIngredients.count || 0 ) + 1
            };

            await setDoc(doc(db, "ingredients", existingIngredients.id), updatedIngredient)

            console.log(`"${title}" count: ${updatedIngredient.count}`);

            toast.success(`"${title}" is already in the basket`);
        } else {
            const newIngredient = {
                title: title || "",
                checked: checked || "",
                userId,
                count: 1,
                dateAdded: new Date().getTime(),
                isNewItem: true,
            };

            await addDoc(ingredientsCollectionRef, newIngredient)

            // console.log(`"${title}" count: ${newIngredient.count}`);
        }
    };

    const getRecommendedIngredients = async (limitCount) => {
        const recommendedIngredientsQuery = query(
          ingredientsCollectionRef,
          where("userId", "==", userId),
          orderBy("count", "desc"),
          limit(limitCount)
        );

    
        const querySnapshot = await getDocs(recommendedIngredientsQuery);
        const recommendedIngredients = querySnapshot.docs.map(doc => doc.data());

        console.log(recommendedIngredients)
    
        return recommendedIngredients;
      };

    return {
        onIngredientSubmit,
        mergeIngredients,
        getRecommendedIngredients
        // , loading
    }
}