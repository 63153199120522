import React, {useState, useEffect} from 'react';
import {AuthProvider} from './context/AuthContext'
import { Provider } from 'react-redux';
import './App.css'
import AppRouter from './AppRouter';
import "@fontsource/inter";
import "@fontsource/inter/700.css"
import "@fontsource/inter/500.css"
import { createStore } from 'redux';
import rootReducer from './reducers';
import { Toaster } from 'react-hot-toast';
import {auth} from './firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import './fonts/fonts.css';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from './styles/ThemeContext';
import { colors } from './styles/theme';



const store = createStore(rootReducer)

const App = () => {

  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      // navigate('/app-home');
     })
  }, [])

  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    document.title = "Bentofy - Organise your meals in one place"
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
      <ThemeProvider>
      <div className='App-container' style={{background: colors.background}}>
        <AppRouter/>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            className: "Toaster",
            iconTheme: {
              primary: '#009b3c',
              secondary: '#FFFAEE',
            },
          }}
        />
      </div>
      </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;

