export const baseUnit = 8;

export const spacing = {
  xs: baseUnit / 2, //4
  sm: baseUnit, //8
  md: baseUnit * 2, //16
  lg: baseUnit * 3, //24
  xl: baseUnit * 4, //32
};

export const grid = {
  container: {
    paddingHorizontal: spacing.sm,
  },
  row: {
    flexDirection: 'row',
    marginHorizontal: -spacing.sm,
  },
  col: {
    paddingHorizontal: spacing.sm,
    flex: 1,
  },
};

export const colors = {
  light: {
    primary: '#FF0000',
    secondary: '#00FF00',
    default: '#f5f5f4',
    background: '#FFFFFF',
    backgroundSubtle: '#FFFFFF',
    backgroundInverse: '#1c1917',
    backgroundInverseSubtle: '#292524',
    text: '#000000',
    textInverse: '#f5f5f4',
    textSubtle: '#a1a1aa',
    icon: '#44403c',
    iconInverse: '#FFFFFF',
    iconSubtle: '#a8a29e',
    iconActive: 'blue',
    inputBackground: '#f5f5f4',
    inputBorder: '#d6d3d1',
    border: '#e7e5e4',
    success: '#48BB78',
    error: 'red',
    link: '#3b82f6',
    N50: '#fafaf9',
    N100: '#f5f5f4',
    N200: '#e7e5e4',
    N300: '#d6d3d1',
    N400: '#a8a29e',
    N500: '#78716c',
    N600: '#57534e',
    N700: '#44403c',
    N800: '#292524',
    N900: '#1c1917',
    R50: '#FFEBEB',
    R100: '#FFC2C2',
    R200: '#FF9B9B',
    R300: '#FF7171',
    R400: '#FF4C4C',
    R500: '#FF0000',
    R600: '#E60000',
    R700: '#CC0000',
    R800: '#B30000',
    R900: '#990000',
    O50: '#FFF7ED',
    O100: '#FFEDD5',
    O200: '#FFE1B2',
    O300: '#FFD08A',
    O400: '#FFC163',
    O500: '#DD6B20',
    O600: '#BF4D00',
    O700: '#9E3F00',
    O800: '#7D3100',
    O900: '#642400',
    Y50: '#FFFFF0',
    Y100: '#FEFCBF',
    Y200: '#FAF089',
    Y300: '#F6E05E',
    Y400: '#ECC94B',
    Y500: '#D69E2E',
    Y600: '#B7791F',
    Y700: '#975A16',
    Y800: '#744210',
    Y900: '#5F370E',
    G50: '#F0FFF4',
    G100: '#C6F6D5',
    G200: '#9AE6B4',
    G300: '#68D391',
    G400: '#48BB78',
    G500: '#38A169',
    G600: '#2F855A',
    G700: '#276749',
    G800: '#22543D',
    G900: '#1C4532',
    T50: '#E6FFFA',
    T100: '#B2F5EA',
    T200: '#81E6D9',
    T300: '#4FD1C5',
    T400: '#38B2AC',
    T500: '#319795',
    T600: '#2C7A7B',
    T700: '#285E61',
    T800: '#234E52',
    T900: '#1D4044',
    B50: '#ebf8ff',
    B100: '#bee3f8',
    B200: '#90cdf4',
    B300: '#63b3ed',
    B400: '#4299e1',
    B500: '#3182ce',
    B600: '#2b6cb0',
    B700: '#2c5282',
    B800: '#2a4365',
    B900: '#1A365D',
    V50: '#F5F3FF',
    V100: '#EDE9FE',
    V200: '#DDD6FE',
    V300: '#C4B5FD',
    V400: '#A78BFA',
    V500: '#9F7AEA',
    V600: '#805AD5',
    V700: '#6B46C1',
    V800: '#553C9A',
    V900: '#44337A',
    P50: '#FDF2F8',
    P100: '#FCE7F3',
    P200: '#FBCFE8',
    P300: '#F9A8D4',
    P400: '#F472B6',
    P500: '#ED64A6',
    P600: '#D53F8C',
    P700: '#B83280',
    P800: '#97266D',
    P900: '#702459',
  },
  dark: {
    primary: '#FFAAAA',
    secondary: '#AAFFAA',
    default: '#27272a',
    background: '#000000',
    backgroundSubtle: '#27272a',
    backgroundInverse: '#F4F4F5',
    backgroundInverseSubtle: '#fafafa',
    text: '#FFFFFF',
    textInverse: '#000000',
    textSubtle: '#71717a',
    icon: '#FFFFFF',
    iconInverse: '#000000',
    iconSubtle: '#71717a',
    iconActive: 'red',
    inputBackground: '#27272a',
    inputBorder: '#3f3f46',
    border: '#3f3f46',
    success: '#38A169',
    error: 'red',
    link: '#60a5fa',
    N50: '#1c1917',
    N100: '#292524',
    N200: '#44403c',
    N300: '#44403c',
    N400: '#57534e',
    N500: '#78716c',
    N600: '#d6d3d1',
    N700: '#e7e5e4',
    N800: '#f3f4f6',
    N900: '#f9fafb',
    R50: '#990000',
    R100: '#B30000',
    R200: '#CC0000',
    R300: '#E60000',
    R400: '#FF0000',
    R500: '#FF4C4C',
    R600: '#FF7171',
    R700: '#FF9B9B',
    R800: '#FFC2C2',
    R900: '#FFEBEB',
    O50: '#642400',
    O100: '#7D3100',
    O200: '#9E3F00',
    O300: '#BF4D00',
    O400: '#DD6B20',
    O500: '#FFC163',
    O600: '#FFD08A',
    O700: '#FFE1B2',
    O800: '#FFEDD5',
    O900: '#FFF7ED',
    Y50: '#5F370E',
    Y100: '#744210',
    Y200: '#975A16',
    Y300: '#B7791F',
    Y400: '#D69E2E',
    Y500: '#FAF089',
    Y600: '#F6E05E',
    Y700: '#FEFCBF',
    Y800: '#FFFFF0',
    Y900: '#FFFFF0',
    G50: '#1C4532',
    G100: '#22543D',
    G200: '#276749',
    G300: '#2F855A',
    G400: '#38A169',
    G500: '#48BB78',
    G600: '#68D391',
    G700: '#9AE6B4',
    G800: '#C6F6D5',
    G900: '#F0FFF4',
    T50: '#1D4044',
    T100: '#234E52',
    T200: '#285E61',
    T300: '#2C7A7B',
    T400: '#319795',
    T500: '#38B2AC',
    T600: '#4FD1C5',
    T700: '#81E6D9',
    T800: '#B2F5EA',
    T900: '#E6FFFA',
    B50: '#1A365D',
    B100: '#2A4365',
    B200: '#2C5282',
    B300: '#2B6CB0',
    B400: '#3182CE',
    B500: '#4299E1',
    B600: '#63B3ED',
    B700: '#90CDF4',
    B800: '#BEE3F8',
    B900: '#EBF8FF',
    V50: '#44337A',
    V100: '#553C9A',
    V200: '#6B46C1',
    V300: '#805AD5',
    V400: '#9F7AEA',
    V500: '#A78BFA',
    V600: '#C4B5FD',
    V700: '#DDD6FE',
    V800: '#EDE9FE',
    V900: '#F5F3FF',
    P50: '#702459',
    P100: '#97266D',
    P200: '#B83280',
    P300: '#D53F8C',
    P400: '#ED64A6',
    P500: '#F472B6',
    P600: '#F9A8D4',
    P700: '#FBCFE8',
    P800: '#FCE7F3',
    P900: '#FDF2F8',
  },
};

export const typography = {
  h1: {
    // fontFamily: 'Plus-Jakarta-Sans-Bold',
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: 40,
  },
  h2: {
    // fontFamily: 'Plus-Jakarta-Sans-Bold',
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: 36,
  },
  h3: {
    // fontFamily: 'Plus-Jakarta-Sans-Bold',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 32,
    letterSpacing: -1,
  },
  h4: {
    // fontFamily: 'Plus-Jakarta-Sans-Bold',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
    letterSpacing: -1,
  },
  h5: {
    // fontFamily: 'Plus-Jakarta-Sans-Bold',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 24,
    letterSpacing: -0.5,
  },
  h6: {
    // fontFamily: 'Plus-Jakarta-Sans-Bold',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 20,
    letterSpacing: -0.4,
  },
  p: {
    // fontFamily: 'Plus-Jakarta-Sans-Medium',
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: -0.4,
  },
  small: {
    // fontFamily: 'Plus-Jakarta-Sans-Medium',
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.4,
  },
};

export const elevations = {
  z100: {
    shadowColor: '#57534e',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    elevation: 4,
  },
  z200: {
    shadowColor: '#57534e',
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    elevation: 6,
  },
  z300: {
    shadowColor: '#57534e',
    shadowOpacity: 0.3,
    shadowRadius: 8,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    elevation: 8,
  },
  // Add more elevation levels as needed
};
