import React, { useState, useEffect } from 'react'
import { auth } from '../firebase-config';
import { db } from "../firebase-config";
import { collection, onSnapshot, query, where } from 'firebase/firestore';

export const useFetchUserProfile = () => {
    const userId = auth.currentUser && auth.currentUser.uid;
    const usersCollectionRef = collection(db, "users");
    const [userData, setUserData] = useState(null)

    // const onFetchUserProfile = () => {
    //     console.log(userId)
    // } 

    const onFetchUserProfile = ({ userAvatarEmoji, userAvatarBackground }) => {

        

        const q = query(usersCollectionRef, where("userId", "==", userId))

        const userRefData = onSnapshot(q, (snapshot) => {
            setUserData(snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }))

            console.log(userData[0].userAvatarEmoji.randomEmoji)
            console.log(userData[0].userAvatarBackground.randomBackgroundColour)

            
        })

        const userAvatar = {
            userAvatarEmoji: userData[0].userAvatarEmoji.randomEmoji,
            userAvatarBackground: userData[0].userAvatarBackground.randomBackgroundColour
            // userAvatarEmoji: userAvatarEmoji ?? "",
            // userAvatarBackground: userAvatarBackground ?? ""
        }

        console.log(userAvatar)

        // return userAvatar;
        return {userRefData, userAvatar};

        
    }


    return {
        onFetchUserProfile
    }

}
