import React from 'react'
import { useTheme } from '../styles/ThemeContext';
import { grid, typography } from '../styles/theme';
import Spacer from './Spacer';
import { CaretRight } from 'phosphor-react'

const SectionHeader = ({ name, action, onClick }) => {
    const { colors } = useTheme();
    return (
        <div className='Section-header'>
            <h3 style={{ flexGrow: 1, color: colors.text }}>{name}</h3>
            {action &&
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignItems: 'center', gap: 8, flexWrap: 'wrap', cursor: 'pointer' }} onClick={onClick}>
                    <h4 style={{ color: colors.textSubtle, marginTop: 0 }}>{action}</h4>
                    <CaretRight size={16} color={colors.iconSubtle} />
                </div>
            }
        </div>
    )
}

export default SectionHeader