import { CheckCircle, Sparkle } from 'phosphor-react'
import React from 'react'
import Button from './Button.tsx'
import ListItem from './ListItem'
import { useNavigate } from 'react-router-dom'

const UpgradeModal = (props) => {

    const navigate = useNavigate();

    const navigateToPricing = () => {
        navigate("/pricing")
    }
  return (
    <div>
        <div className='Modal-cover'>
            
            <img src={require("../images/bento_illos.png")} alt="pro illustration"/>
        </div>
        <div className='Modal-container'>
        <span className="List-item-icon" style={{backgroundColor: "#E7FFF1", color: "#255F3D"}}>
            <Sparkle size={20} />
        </span>
        <h5>Upgrade to</h5>
        <h2>Bento Premium</h2>
        <p>Bento is free to download and use (with a few limitations) so that you can try it out. Upgrade to the Premium version to access the following features:</p>
        <br></br>
        <div className='List-container'>
            <ListItem
                appearance="bordered"
                iconBefore={
                <span className="List-item-icon" style={{borderRadius: 24, backgroundColor: "#E7FFF1", color: "#255F3D"}}>
                    <CheckCircle size={20} />
                </span>
                }
                name="Unlimited recipes"
            />
            <ListItem
                appearance="bordered"
                iconBefore={
                <span className="List-item-icon" style={{borderRadius: 24, backgroundColor: "#E7FFF1", color: "#255F3D"}}>
                    <CheckCircle size={20} />
                </span>
                }
                name="Cloud backup of your data"
            />
            <ListItem
                appearance="bordered"
                iconBefore={
                <span className="List-item-icon" style={{borderRadius: 24, backgroundColor: "#E7FFF1", color: "#255F3D"}}>
                    <CheckCircle size={20} />
                </span>
                }
                name="Personalised recommendations"
            />
            <ListItem
                appearance="bordered"
                iconBefore={
                <span className="List-item-icon" style={{borderRadius: 24, backgroundColor: "#E7FFF1", color: "#255F3D"}}>
                    <CheckCircle size={20} />
                </span>
                }
                name="Share account with others"
            />
            <ListItem
                appearance="bordered"
                iconBefore={
                <span className="List-item-icon" style={{borderRadius: 24, backgroundColor: "#E7FFF1", color: "#255F3D"}}>
                    <CheckCircle size={20} />
                </span>
                }
                name="Sync data across multiple devices"
            />
            
        </div>
        <br></br>
        <br></br>
        <Button appearance="primary Button-full" name="View plans and pricing" onClick={navigateToPricing}/>
        <br></br>
        <small>Cancel anytime. <a>Terms and conditions</a> and <a>Privacy policy</a></small>
        </div>
    </div>

  )
}

export default UpgradeModal