import React from 'react'
import Button from './Button.tsx'
import { useTheme } from '../styles/ThemeContext.js'

const EmptyState = (props) => {
  const { colors } = useTheme();
  return (
    <div className='Empty-state-container'>
      {/* <img src={props.imageUrl} alt="Empty state thumbnail" className="Empty-state-illustration"/> */}
      <br></br>
      <div className='Empty-state-content'>
        {props.imageUrl
          ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={props.imageUrl} alt="Empty state thumbnail" className="Empty-state-illustration" />
          </div>
          : null
        }
        <h3 style={{ color: colors.text }}>{props.title}</h3>
        <small style={{ color: colors.text }}>{props.description}</small>
        <br></br>
        <div className='Button-group'>
          <Button
            appearance="primary"
            iconBefore={props.primaryButtonIcon}
            name={props.primaryButtonName}
            onClick={props.primaryButtonOnClick}
          />
          {props.secondaryButtonName ? <Button appearance="default" name={props.secondaryButtonName} onClick={props.secondaryButtonOnClick} /> : null}

        </div>
      </div>
    </div>
  )
}

export default EmptyState