import React, { useState, useEffect, useRef } from 'react';

const Carousel = ({ images }) => {

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);


  const delay = 2000;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const slides = [
    {
      illustration: "3d_illos_bento.png",
      color: "#1B1918",
      day: "Mo"
    },
    {
      illustration: "3d_illos_salad.png",
      color: "#268378",
      day: "Tu"
    },
    {
      illustration: "3d_illos_milk.png",
      color: "#5A97FF",
      day: "We"
    },
    {
      illustration: "3d_illos_pasta.png",
      color: "#FFDA1A",
      day: "Th"
    },
    {
      illustration: "3d_illos_sushi.png",
      color: "#FFA194",
      day: "Fr"
    },
    {
      illustration: "3d_illos_burger.png",
      color: "#D44F21",
      day: "Sa"
    },
    {
      illustration: "3d_illos_pancake.png",
      color: "#7553D6",
      day: "Su"
    },

  ]

  return (
    <div className="Carousel-slideshow"
      style={{ backgroundColor: slides[index].color, transition: 'background-color 1s ease' }}
    >
      <div className="Carousel-slideshow-slider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {slides.map((slide, idx) => (
          <div
            className={`Carousel-slide ${index === idx ? 'fade-in' : 'fade-out'}`}
            key={idx}
          // style={{ backgroundColor: slide.color }}
          >
            <img src={require(`../images/${slide.illustration}`)} alt="illustration" style={{ opacity: 1 }} />
          </div>
        ))}
      </div>

      <div className="Carousel-slideshow-dots">
        {slides.map((_, idx) => (
          <div key={idx}
            className={`Carousel-slideshow-dot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          >
            {_.day}
          </div>
        ))}
      </div>

    </div>
  );
};

export default Carousel;