export const cuisineList = [
    { name: 'Japanese', icon: '🍱', color: '#fee2e2' },
    { name: 'Italian', icon: '🍕', color: '#fed7aa' },
    { name: 'Chinese', icon: '🍜', color: '#fde68a' },
    { name: 'Indian', icon: '🍛', color: '#d9f99d' },
    { name: 'Mexican', icon: '🌮', color: '#bbf7d0' },
    { name: 'French', icon: '🥐', color: '#93c5fd' },
    { name: 'Spanish', icon: '🥘', color: '#f5d0fe' },
    { name: 'American', icon: '🍔', color: '#bae6fd' },
    { name: 'Thai', icon: '🌶️', color: '#ffedd5' },
    { name: 'Korean', icon: '🍚', color: '#ddd6fe' },
    // { name: 'Greek', icon: '🇬🇷' },
    // { name: 'Lebanese', icon: '🇱🇧' },
    // { name: 'Turkish', icon: '🇹🇷' },
    // { name: 'Vietnamese', icon: '🇻🇳' },
    // { name: 'Brazilian', icon: '🇧🇷' },
    // { name: 'Mediterranean', icon: '🥗' },
    // { name: 'British', icon: '🇬🇧' },
    // { name: 'German', icon: '🥨' },
    // { name: 'Russian', icon: '🇷🇺' },
    // { name: 'African', icon: '🌍' },
    // { name: 'Moroccan', icon: '🇲🇦' },
    // { name: 'Portuguese', icon: '🇵🇹' },
    // { name: 'Swedish', icon: '🇸🇪' },
    // { name: 'Indonesian', icon: '🇮🇩' },
    // { name: 'Peruvian', icon: '🇵🇪' },
    // { name: 'Australian', icon: '🇦🇺' },
    // { name: 'Cuban', icon: '🇨🇺' },
    // { name: 'Egyptian', icon: '🇪🇬' },
];

