import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../firebase-config"
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore"
import '../App.css';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";
import RecipeModal from "./RecipeModal";
import CreateRecipeModal from "./CreateRecipeModal/index.js";
import IngredientItem from "./IngredientItem";
import { toast } from "react-hot-toast";
import ListItem from "./ListItem";
import Button from "./Button.tsx";
import { CalendarPlus, DiceThree, Plus, Scan, Sparkle, X } from "phosphor-react";
import * as moment from 'moment'
import RecipeCard from "./RecipeCard";
import RecipeMiniCard from "./RecipeMiniCard";
import { ref, uploadBytes } from "firebase/storage";
import { auth } from '../firebase-config';
import { useRecipeSubmission } from '../controllers/recipeSubmission'
import { Configuration, OpenAIApi } from "openai";
import GenerateButton from "./GenerateButton";
import ContextualCreateRecipe from "./ContextualCreateRecipe";
import EmptyState from "./EmptyState";
import SmartIngredientsModal from "./SmartIngredientsModal";
import parse from 'html-react-parser';
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../styles/ThemeContext.js";


const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const configuration = new Configuration({
  apiKey: API_KEY,
})

const openai = new OpenAIApi(configuration)


const MealCalendar = (props) => {
  const [events, setEvents] = useState(
    {
      title: "",
      date: "",
      description: "",
      extendedProps: {
        type: "",
        imageUrl: "",
      },
      ingredients: [],
      ingredientsEditorContent: "",
      steps: [],
      stepsEditorContent: "",
      imageUrl: "",
      type: "",
      tags: [],
      cuisine: "",
      diets: "",
      difficulty: "",
      cookTime: "",
      cost: "",
      linkSource: "",

    }
  )

  const { colors } = useTheme();

  const navigate = useNavigate();
  const { recipeId } = useParams();

  // SHOW USER RECIPES BASED ON USER ID
  const userId = auth.currentUser && auth.currentUser.uid;
  const recipesCollectionRef = collection(db, "recipes")

  const [recipeForm, setRecipeForm] = useState({
    title: "",
    description: "",
    ingredients: [],
    ingredientsEditorContent: "",
    steps: [],
    stepsEditorContent: "",
    date: "",
    imageUrl: "",
    tags: [],
    type: "",
    cuisine: "",
    diets: "",
    difficulty: "",
    cookTime: "",
    cost: "",
    linkSource: "",
  })

  useEffect(() => {
    if (!userId) {
      return
    }

    const q = query(recipesCollectionRef, where("userId", "==", userId));

    const fetchData = onSnapshot(q, (querySnapshot) => {
      setEvents(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    });

    return fetchData;

  }, [userId])


  const renderEventContent = (recipe) => {
    return (
      <RecipeCard
        recipe={recipe.event}
        imageUrl={recipe.event.extendedProps.imageUrl}
        onClick={() => handleOpenRecipeModal(recipe)}
        // onClick={() => handleOpenRecipeModal(recipe)}
        // onClick={() => showEditModal(recipe)}
        showMoreMenu={true}
        onDelete={() => handleDelete(recipe)}
        onEdit={() => showEditModal(recipe)}
        onRemoveSchedule={() => removeDate(recipe)}
        type={recipe.event.extendedProps.type}
        showIsSaved={false}
        showSchedule={false}
        moreMenu={true}
      />
    )
  }


  const renderMiniEventContent = (recipe) => {
    return (
      <RecipeMiniCard
        name={recipe.event.title}
        onClick={() => showEditModal(recipe)}
        type={recipe.event.extendedProps.type}
        cardColorSmall={true}
      />
    )
  }

  const renderMiniEventContentWithImage = (recipe) => {
    return (
      <RecipeMiniCard
        name={recipe.event.title}
        imageUrl={recipe.event.extendedProps.imageUrl}
        onClick={() => showEditModal(recipe)}
        wrap={"pre-line"}
        flexDirection={"column"}
        gap={6}
        type={recipe.event.extendedProps.type}
        cardColorSmall={false}
      />
    )
  }



  const calendarRef = useRef(null);

  // Navigate to next or prev week via swipe
  const [opacity, setOpacity] = useState(1);
  let xDown = null;
  let yDown = null;

  const handleTouchStart = (evt) => {
    const firstTouch = evt.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        // swipe left
        calendarRef.current.getApi().next();
        setOpacity(0.5)
      } else {
        // swipe right
        calendarRef.current.getApi().prev();
        setOpacity(0.5)
      }
    }

    xDown = null;
    yDown = null;
  };

  const handleTouchEnd = () => {
    setOpacity(1);
  };



  // Clicking at the full calendar recipe should open the recipe modal
  const handleDateClick = (recipe) => {
    setShowModal(true);
    setActiveRecipe(recipe)
  };




  // VIEW RECIPE DETAIL
  const [showModal, setShowModal] = useState(false);
  const [activeRecipe, setActiveRecipe] = useState([]);
  const [showRecipeModal, setShowRecipeModal] = useState(false);

  const handleOpenRecipeModal = (recipe) => {
    setShowRecipeModal(true);
    setActiveRecipe(recipe);
    // navigate(`/plan/${activeRecipe.event.id}`);
  }

  const handleCancelRecipe = () => {
    setShowRecipeModal(false)
  }

  //  Delete recipe
  const handleDelete = (recipe) => {
    deleteDoc(doc(db, "recipes", recipe.event.id))
    toast.success(`${recipe.event.title} successfully deleted`);
  }

  const handleDeleteFromModal = (recipe) => {
    deleteDoc(doc(db, "recipes", activeRecipe.event.id))
    setShowRecipeModal(false)
    setShowCreateRecipeModal(false)
    toast.success(`"${activeRecipe.event.title}" successfully deleted`);
  }


  // CREATE RECIPE
  const [showCreateRecipeModal, setShowCreateRecipeModal] = useState(false)
  const [showRecipeContextualCreate, setShowRecipeContextualCreate] = useState(false)

  const handleOpenRecipeCreateModal = (event) => {
    setShowCreateRecipeModal(true)

    const date = event.start;
    const formattedDate = moment(date).format("YYYY-MM-DD")

    setRecipeForm({
      // ...recipeForm,
      title: "",
      description: "",
      ingredients: [],
      ingredientsEditorContent: "",
      steps: [],
      stepsEditorContent: "",
      imageUrl: "",
      date: formattedDate,
      tags: [],
      type: "",
      cuisine: "",
      diets: "",
      difficulty: "",
      cookTime: "",
      cost: "",
      linkSource: "",

    })

    setEditMode(false)
  }


  // AI FEATURES

  // GENERATE RECIPE
  const [isGenerating, setIsGenerating] = useState(false);


  const handleGenerateSingleRecipe = (event) => {
    async function generateSingleRecipe() {
      try {
        setIsGenerating(true);
        const prompt = "a%20food";
        const response = await fetch(`https://us-central1-menuboard-2528d.cloudfunctions.net/generateSingleRecipe?prompt=${prompt}`);
        const date = event.start;
        const formattedDate = moment(date).format("YYYY-MM-DD");
        console.log(formattedDate)
        console.log(response)

        if (!response.ok) {
          throw new Error("Generate response was not ok");
        }
        const data = await response.json();
        console.log(data);
        console.log(data.recipeName[0]);
        // console.log(data.recipeImageUrl)

        setRecipeForm({
          ...recipeForm,
          title: data.recipeName[0],
          desc: "",
          ingredients: [],
          ingredientsEditorContent: "",
          steps: [],
          stepsEditorContent: "",
          date: formattedDate,
          // imageUrl: data.recipeImageUrl,
          imageUrl: "",
          type: "",
          cuisine: "",
          diets: "",
          difficulty: "",
          cookTime: "",
          cost: "",
          linkSource: "",
        })

        onRecipeSubmit(recipeForm);

        setIsGenerating(false);
      }
      catch (error) {
        console.log(error);
      }
    }

    generateSingleRecipe();
  }

  // GENERATE WHOLE WEEK
  const [weeklyRecipes, setWeeklyRecipes] = useState([]);
  const [isGeneratingWeeklyRecipes, setIsGeneratingWeeklyRecipes] = useState(false)
  const [weeklyRecipesImages, setWeeklyRecipesImages] = useState([]);



  const generateRecipeEvents = (weeklyRecipes, weeklyRecipesImages) => {
    const currentView = calendarRef.current.getApi().view;
    const startOfWeekDate = currentView.activeStart;
    const currentWeek = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeekDate);
      date.setDate(startOfWeekDate.getDate() + i);
      currentWeek.push(date);
    }

    const events = currentWeek.map((date, index) => ({
      id: index,
      title: weeklyRecipes[index],
      start: date.toISOString(),
      end: date.toISOString(),
      // imageUrl: weeklyRecipesImages[index],
    }));
    return events;
  };



  const handleGenerateWeeklyRecipe = async () => {
    const generateWeeklyRecipe = async () => {
      try {
        setIsGeneratingWeeklyRecipes(true);

        // Set prompt based on user preferences
        const prompt = "a%20food";

        // Fetch API from Google Cloud Functions
        const response = await fetch(`https://us-central1-menuboard-2528d.cloudfunctions.net/generateWeeklyRecipes?prompt=${prompt}`);

        if (!response.ok) {
          throw new Error("Generate response was not ok");
        }

        // Set weekly recipes based on generated data
        const data = await response.json();
        const weeklyRecipesResponse = data.weeklyRecipesNames;
        setWeeklyRecipes(weeklyRecipesResponse)


        // Generate a list of images based on the recipe
        // const imageResponse = await openai.createImage({
        //   prompt: `A close up recipe photo of ${weeklyRecipesResponse}`,
        //   // prompt: `A high quality, studio photographic image of food`, 
        //   n: 7,
        //   size: "256x256",
        // });

        // const weeklyRecipesImagesResponse = imageResponse.data.data.map(imageData => imageData.url.trim())
        // console.log(weeklyRecipesImagesResponse)
        // setWeeklyRecipesImages(weeklyRecipesImagesResponse)


        // Get current week
        const currentView = calendarRef.current.getApi().view;
        const startOfWeekDate = currentView.activeStart;
        const currentWeek = [];
        for (let i = 0; i < 7; i++) {
          const date = new Date(startOfWeekDate);
          date.setDate(startOfWeekDate.getDate() + i);
          currentWeek.push(date);
        }

        const recipeEvents = generateRecipeEvents(weeklyRecipesResponse,
          // weeklyRecipesImagesResponse
        );
        setEvents(recipeEvents);

        // For every event, create a recipe
        recipeEvents.forEach(event => {
          onRecipeSubmit({
            title: event.title,
            date: event.start,
            imageUrl: event.imageUrl,
          });
        });


        setIsGeneratingWeeklyRecipes(false);

        console.log(currentWeek);

      }
      catch (error) {
        console.error('Error generating weekly recipes:', error);
        setIsGeneratingWeeklyRecipes(false);
      }

    }

    generateWeeklyRecipe();
  }

  // SMART INGREDIENTS
  const [ingredients, setIngredients] = useState([]);
  const [showSmartIngredientsModal, setShowSmartIngredientsModal] = useState(false)
  const [isGeneratingIngredients, setIsGeneratingIngredients] = useState(false)

  const handleSmartGroceries = async () => {
    const generateConsolidatedIngredients = async () => {
      const events = calendarRef.current.getApi().getEvents();
      const currentView = calendarRef.current.getApi().view;
      const startOfWeekDate = currentView.activeStart;
      const endOfWeekDate = currentView.activeEnd;
      try {
        setIsGeneratingIngredients(true)


        const recipesForWeek = events
          .filter((event) => {
            const eventStart = event.start;
            const eventEnd = event.end;

            return (
              eventStart >= startOfWeekDate && eventEnd <= endOfWeekDate
            );
          })
          .map((event) => event.title);

        const combinedRecipes = recipesForWeek.join('\n\n');
        console.log(combinedRecipes)

        const response = await fetch(`https://us-central1-menuboard-2528d.cloudfunctions.net/generateIngredients?prompt=${combinedRecipes}`);
        if (!response.ok) {
          throw new Error("Generate response was not ok");
        }

        const data = await response.json();
        const ingredientsResponse = data.ingredientsResponse;


        // const ingredientsResponse = response.data.choices[0].text.trim().split('\n');

        console.log(ingredientsResponse)

        // Extract and process the ingredient list from completionText
        // const processedIngredients = processIngredients(ingredientsResponse);

        setIngredients(ingredientsResponse);
        setShowSmartIngredientsModal(true)
        setIsGeneratingIngredients(false)

      } catch (error) {
        console.error('Error generating consolidated ingredients:', error);
      }
    };

    generateConsolidatedIngredients();
  };



  // Fetch image from Unsplash
  const [image, setImage] = useState('');

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(
  //       `https://api.unsplash.com/photos/random?page=1&query=food&client_id=YOUR-KEY-HERE`
  //     );
  //     const data = await response.json();
  //     setImage(data.urls.regular);
  //   };
  //   fetchImage();
  // }, []);


  // Use custom hook (reusable create recipe function)
  const { onRecipeSubmit } = useRecipeSubmission()

  const handleCreateRecipe = (event) => {
    onRecipeSubmit(recipeForm)

    setRecipeForm({
      title: "",
      description: "",
      ingredients: [],
      ingredientsEditorContent: "",
      steps: [],
      stepsEditorContent: "",
      date: "",
      imageUrl: image,
      tags: [],
      type: "",
      cuisine: "",
      diets: "",
      difficulty: "",
      cookTime: "",
      cost: "",
      linkSource: "",

    })

    console.log(recipeForm)

    setShowCreateRecipeModal(false)
    setShowRecipeContextualCreate(false)
  }

  // Handle image
  const [imageUpload, setImageUpload] = useState(null)

  const handleImage = (event) => {
    console.log(event.target.files[0])
    if (event.target.files[0]) {
      setImageUpload(event.target.files[0])
    }
  }

  const handleUploadImage = async (recipe) => {
    try {
      setActiveRecipe(recipe)
      // const storageRef = storage.ref();
      console.log(activeRecipe)
      // const fileRef = storageRef.child(`recipeImages/${recipeId}`);
    }

    catch (error) {
      console.log(error.message)
    }
    // const uploadTask = storage.ref(`images/${image.name}`).put(image);
    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {
    //     // progress function
    //   },
    //   (error) => {
    //     // error function
    //     console.log(error);
    //   },
    //   () => {
    //     // complete function
    //     storage
    //       .ref("images")
    //       .child(image.name)
    //       .getDownloadURL()
    //       .then((url) => {
    //         console.log(url);
    //       });
    //   }
    // );
    // console.log('bla')

    // if (imageUpload == null) return;
    // const imageRef = ref(storage, `images/${imageUpload.name}`)
    // uploadBytes(imageRef, imageUpload).then(() => {
    //   alert("Image uploaded")
    // })
  }


  // HANDLE TAGS
  const handleTags = (event, i) => {
    // const tagsArray = [...recipeForm.tags]

    // tagsArray[i] = event

    setRecipeForm({
      ...recipeForm,
      tags: event
    })

    console.log(recipeForm)
    // console.log(tagsArray)
  }


  // HANDLE INGREDIENTS
  const handleIngredient = (event, i) => {
    const ingredientsArray = [...recipeForm.ingredients]

    ingredientsArray[i] = event.target.value

    setRecipeForm({
      ...recipeForm,
      ingredients: ingredientsArray
    })
  }

  const handleIngredientCount = () => {
    setRecipeForm({
      ...recipeForm,
      ingredients: [...recipeForm.ingredients, ""]
    })
  }

  const handleIngredientDelete = (i) => {
    const ingredientsArray = [...recipeForm.ingredients];
    ingredientsArray.splice(i, 1);
    setRecipeForm({
      ...recipeForm,
      ingredients: ingredientsArray
    })
  }

  const handleStep = (event, i) => {
    const stepsArray = [...recipeForm.steps]

    stepsArray[i] = event.target.value

    setRecipeForm({
      ...recipeForm,
      steps: stepsArray
    })
  }

  const handleStepCount = () => {
    setRecipeForm({
      ...recipeForm,
      steps: [...recipeForm.steps, ""]
    })
  }

  const handleStepDelete = (i) => {
    const stepsArray = [...recipeForm.steps];
    stepsArray.splice(i, 1);
    setRecipeForm({
      ...recipeForm,
      steps: stepsArray
    })

  }



  // EDIT RECIPE
  const [editMode, setEditMode] = useState(false)
  const [moreMenu, setMoreMenu] = useState(false)


  const showEditModal = (recipe) => {
    setShowRecipeModal(false)
    setShowCreateRecipeModal(true)
    setEditMode(true)

    // Close popover
    setMoreMenu(false);


    const date = new Date(recipe.event.start);
    const formattedDate = moment(date).format("YYYY-MM-DD");

    setActiveRecipe(recipe)

    setRecipeForm({
      ...recipeForm,
      title: recipe.event.title,
      imageUrl: recipe.event.extendedProps.imageUrl,
      description: recipe.event.extendedProps.description,
      ingredients: recipe.event.extendedProps.ingredients,
      ingredientsEditorContent: recipe.event.extendedProps.ingredientsEditorContent,
      steps: recipe.event.extendedProps.steps,
      stepsEditorContent: recipe.event.extendedProps.stepsEditorContent,
      date: formattedDate,
      type: recipe.event.extendedProps.type,
      cuisine: recipe.event.extendedProps.cuisine,
      diets: recipe.event.extendedProps.diets,
      difficulty: recipe.event.extendedProps.difficulty,
      cookTime: recipe.event.extendedProps.cookTime,
      cost: recipe.event.extendedProps.cost,
      linkSource: recipe.event.extendedProps.linkSource,

    })
  }


  const handleEditRecipe = (recipe) => {
    const activeRecipeRef = doc(db, "recipes", activeRecipe.event.id)

    updateDoc(activeRecipeRef, {
      // ...recipeForm,
      title: recipeForm.title,
      description: recipeForm.description,
      imageUrl: recipeForm.imageUrl,
      ingredients: recipeForm.ingredients,
      ingredientsEditorContent: recipeForm.ingredientsEditorContent,
      steps: recipeForm.steps,
      stepsEditorContent: recipeForm.stepsEditorContent,
      date: recipeForm.date,
      tags: recipeForm.tags,
      type: recipeForm.type,
      cuisine: recipeForm.cuisine,
      diets: recipeForm.diets,
      difficulty: recipeForm.difficulty,
      cookTime: recipeForm.cookTime,
      cost: recipeForm.cost,
      linkSource: recipeForm.linkSource,

    })

    setShowCreateRecipeModal(false)
    setEditMode(false)
    toast.success(`Nice one! "${recipeForm.title}" updated successfully`);
  }


  // HANDLE DATES 
  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    const formattedDate = moment(date).format("YYYY-MM-DD")
    const activeRecipeRef = doc(db, "recipes", activeRecipe.event.id)

    updateDoc(activeRecipeRef, {
      date: formattedDate.toString(),
    })
  }

  const handleEventDragged = (recipe) => {
    const date = new Date(recipe.event.start);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const activeRecipeRef = doc(db, 'recipes', recipe.event.id)

    updateDoc(activeRecipeRef, {
      date: formattedDate
    })
  }

  const removeDate = (recipe) => {
    setActiveRecipe(recipe);
    const activeRecipeRef = doc(db, "recipes", activeRecipe.event.id)
    updateDoc(activeRecipeRef, {
      date: '',
    })
    toast.success(`Meal successfully removed from schedule!`);
  }

  // HANDLE LINKS
  const handleLinkChange = async (event) => {
    const url = event.target.value;
    const response = await fetch(url);
    const htmlContent = await response.text();

    const parsedContent = parse(htmlContent);
    console.log(parsedContent)
    setRecipeForm({ ...recipeForm, linkSource: event.target.value })
  }


  // CREATE DISCOVERABILITY
  const renderContextualCreate = (event) => {
    return (
      <div className="flex">
        <small>{event.dayNumberText}</small>
        <ContextualCreateRecipe
          generateAppearance={isGenerating ? "generating" : "generate"}
          generateText={isGenerating ? "Generating..." : "Generate"}
          // onGenerate={(event) => generateRecipe(event)}
          recipeNameValue={recipeForm.title}
          recipeNameOnChange={(event) => setRecipeForm({ ...recipeForm, title: event.target.value })}
          recipeDateValue={moment(event.date).format("YYYY-MM-DD")}
          recipeDateOnChange={(event) => setRecipeForm({ ...recipeForm, date: moment(event.date).format("YYYY-MM-DD") })}
          handleCreate={() => {
            handleCreateRecipe()
          }}
          onClick={() => setShowCreateRecipeModal(false)}
        />
        <small></small>
      </div>
    );
  };

  const renderEventEmptyState = (event) => {
    return (
      <div>
        <EmptyState
          imageUrl={require("../images/character-recipes.png")}
          title="No meals scheduled for today"
          description="Add a recipe directly or schedule from the recipe page"
          primaryButtonIcon={<Plus />}
          primaryButtonName="Add recipe"
          primaryButtonOnClick={() => setShowCreateRecipeModal(true)}
        />
      </div>
    )
  }


  // Handle add to grocery ingredients list
  const [ingredientsList, setIngredientsList] = useState({
    name: "",
  });

  const ingredientsCollectionRef = collection(db, "ingredients")

  const handleAddToIngredientsList = (ingredient) => {
    console.log('add')
    addDoc(ingredientsCollectionRef, ingredientsList)
    console.log(ingredient)
    setIngredientsList({
      // ...ingredient,
      name: ingredient,
    })

    toast.success("Ingredient added to grocery list!");
  }



  return (
    <div >
      {/* RECIPE DETAIL */}
      {showRecipeModal &&
        <RecipeModal
          recipe={activeRecipe.event}
          onCancel={handleCancelRecipe}
          onEdit={(recipe) => { showEditModal(activeRecipe) }}
          onDelete={(recipe) => handleDeleteFromModal(recipe)}
          onCopyLink={false}
          onSmartIngredients={false}
          name={activeRecipe.event.title}
          recipeDescriptionValue={recipeForm.description}
          recipeDescriptionOnChange={(event) => setRecipeForm({ ...recipeForm, description: event })}
          recipeDateValue={moment(activeRecipe.event.start).format("YYYY-MM-DD")}
          recipeDateOnChange={(event) => handleDateChange(event)}
          recipeTypeValue={recipeForm.type}
          recipeTypeOnChange={(event) => setRecipeForm({ ...recipeForm, type: event })}
          recipeCuisineValue={recipeForm.cuisine}
          recipeCuisineOnChange={(event) => setRecipeForm({ ...recipeForm, cuisine: event })}
          recipeDietsValue={recipeForm.diets}
          recipeDietsOnChange={(event) => setRecipeForm({ ...recipeForm, diets: event })}
          recipeDifficultyValue={recipeForm.difficulty}
          recipeDifficultyOnChange={(event) => setRecipeForm({ ...recipeForm, difficulty: event })}
          recipeIngredientsEditorValue={recipeForm.ingredientsEditorContent}
          recipeIngredientsEditorOnChange={(event) => setRecipeForm({ ...recipeForm, ingredientsEditorContent: event })}
          recipeStepsEditorValue={recipeForm.stepsEditorContent}
          recipeStepsEditorOnChange={(event) => setRecipeForm({ ...recipeForm, stepsEditorContent: event })}
          recipeCookTimeValue={recipeForm.cookTime}
          recipeCookTimeOnChange={(event) => setRecipeForm({ ...recipeForm, cookTime: event })}
          recipeCostValue={recipeForm.cost}
          recipeCostOnChange={(event) => setRecipeForm({ ...recipeForm, cost: event })}
          recipeLinkSourceValue={recipeForm.linkSource}
          recipeLinkSourceOnChange={(event) => setRecipeForm({ ...recipeForm, linkSource: event.target.value })}
          imageUrl={activeRecipe.event.extendedProps.imageUrl}
        // ingredients={
        //   <ul>
        //     {
        //       activeRecipe.event.extendedProps.ingredients.map((ingredient, i) => (
        //         <IngredientItem key={i}
        //           ingredientName={ingredient}
        //           onClick={() => handleAddToIngredientsList(ingredient)}
        //         />
        //       ))}
        //   </ul>
        // }
        // steps={
        //   <ol>
        //     {
        //       activeRecipe.event.extendedProps.steps.map((step, i) => (
        //         <ListItem
        //           index={i}
        //           appearance="subtle align-top"
        //           componentBefore={
        //             <div className='Bullet-number'>
        //               <p>{i + 1}</p>
        //             </div>

        //           }
        //           component={
        //             <div
        //               autoFocus
        //               type="text"
        //               placeholder=''
        //               className='Form-input-subtle'
        //               value={step}
        //               onChange={(event) => handleStep(event, i)}
        //             >
        //               {step}
        //             </div>
        //           }
        //         />
        //       ))}
        //   </ol>
        // }
        />
      }

      {showCreateRecipeModal &&
        <CreateRecipeModal
          recipeId={activeRecipe.id}
          recipeData={activeRecipe}
          open={() => setShowCreateRecipeModal(true)}
          onOk={() => {
            setShowCreateRecipeModal(false); // Close the modal after saving
          }}
          onCancel={() => setShowCreateRecipeModal(false)}
        />
      }

      {showSmartIngredientsModal &&
        <SmartIngredientsModal
          ingredients={ingredients}
          open={() => setShowSmartIngredientsModal(true)}
          onOk={() => setShowSmartIngredientsModal(false)}
          onCancel={() => setShowSmartIngredientsModal(false)}
        />
      }



      <div className="Floating-action-bar-container">
        <div className='Floating-action-bar'>
          {window.innerWidth < 960 ? null :
            <>

            </>
          }
          {/* <GenerateButton
            generateIcon={<DiceThree />}
            generateAppearance={isGenerating ? "generating" : "generate"}
            generateText={isGenerating ? "Generating..." : "Random"}
            onClick={handleGenerateSingleRecipe}
          /> */}
          <GenerateButton
            generateIcon={<Sparkle />}
            generateAppearance={isGeneratingWeeklyRecipes ? "generating" : "generate"}
            generateText={isGeneratingWeeklyRecipes ? "Generating..." : "Generate"}
            onClick={handleGenerateWeeklyRecipe}
          />
          <GenerateButton
            generateIcon={<Scan />}
            generateAppearance={isGeneratingIngredients ? "generating" : "generate"}
            generateText={isGeneratingIngredients ? "Scanning..." : "Ingredients"}
            onClick={handleSmartGroceries} />
        </div>
      </div>


      {/* {isGenerating ? <LoadingGeneration/> : null} */}
      <div
        className="Calendar-container"
        // onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        // onTouchEnd={handleTouchEnd}
        style={{ height: "100%", background: colors.background }}
      >
        <FullCalendar
          ref={calendarRef}

          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          // initialView={window.innerWidth < 960 ? "dayGridFourDay" : "dayGridWeek"}
          initialView={"dayGridWeek"}
          views={{
            dayGridFourDay: {
              type: 'dayGrid',
              duration: { days: 4 },
              buttonText: '4 days'
            },
            dayGridWeek: {
              type: 'dayGrid',
              buttonText: `Week`,
            },
            dayGridMonth: {
              type: 'dayGrid',
              buttonText: `Month`
            },
            listDay: {
              type: 'listDay',
              buttonText: `Day`,
            }
          }}
          noEventsContent={renderEventEmptyState}
          events={events}
          eventContent={(event) => {
            if (event.view.type === 'dayGridMonth' && window.innerWidth < 960) {
              return renderMiniEventContent
            }
            else if (event.view.type === 'dayGridWeek' && window.innerWidth < 960) {
              return renderMiniEventContentWithImage
            }
            else {
              return renderEventContent
            }
          }}
          eventDrop={(recipe) => handleEventDragged(recipe)}
          dateClick={handleDateClick}
          windowResize={(view) => {
            if (view.calendar.view.type === "dayGridWeek" && window.innerWidth < 960) {
              view.calendar.changeView("dayGridFourDay")
            }
            else if (view.calendar.view.type === "dayGridFourDay" && window.innerWidth >= 960) {
              view.calendar.changeView("dayGridWeek")
            }
          }}
          headerToolbar={{
            right: "listDay,dayGridWeek,dayGridMonth",
            left: "title",
            center: "prev,today,next"
          }}
          firstDay={0}
          aspectRatio={1.32}
          editable={true}
          selectable={true}
          select={(event) => handleOpenRecipeCreateModal(event)}
          longPressDelay={100}
          eventLongPressDelay={100}
          selectLongPressDelay={100}
          dayCellContent={renderContextualCreate}
          customButtons={{
            toggleSidebarButton: {
              text: 'My recipes',
              click: props.toggleSidebar,
            },
            // generateRecipeButton: {
            //   text: 'Generate plan',
            //   click: function () { generateRecipe()}
            // },
            copyToNextWeek: {
              text: 'Duplicate week',
              click: function () { alert('This feature is not available yet') }
            }
          }}
        />
      </div>
    </div>

  );
};

export default MealCalendar;