import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom'
import { useTheme } from '../styles/ThemeContext';
import Button from '../components/Button.tsx';
import { CaretLeft, Pencil, Trash, Copy, CalendarPlus } from 'phosphor-react';
import Spacer from '../components/Spacer.js';
import RecipeDetailInfo from '../components/RecipeDetailInfo.js';
import { getAuth } from 'firebase/auth';
import { auth, db } from '../firebase-config.js';
import AvatarMetadata from '../components/AvatarMetadata.js';
import RecipeDetailIngredients from '../components/RecipeDetailIngredients.js';
import RecipeDetailSteps from '../components/RecipeDetailSteps.js';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import CreateRecipeModal from '../components/CreateRecipeModal/index.js';
import { toast } from 'react-hot-toast';
import { Modal } from 'antd';
import DeleteModal from '../components/DeleteModal.js';
import WeekDatePicker from '../components/WeekDatePicker.js';
import DownloadMobileBanner from '../components/DownloadMobileBanner.js';

const RecipeDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  // USER
  const user = getAuth().currentUser;
  const [showEditRecipeModal, setShowEditRecipeModal] = useState(false);

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);

  // EDIT
  const handleEdit = () => {
    if (!auth.currentUser) {
      navigate('/account-modal');
    } else {
      if (user && user.uid === recipe.userId) {
        setShowEditRecipeModal(true);
      } else {
        // Alert.alert('Error', 'You can only edit your own recipes.');
      }
    }
  };

  // DELETE
  const handleDelete = async () => {
    try {
      // Check if the user is the owner of the recipe
      const recipeSnapshot = await getDoc(doc(db, 'recipes', id));
      const recipeData = recipeSnapshot.data();

      if (recipeData.userId === user.uid) {
        setShowDeleteModal(true);
      } else {
        // Show an error message if the user is not the owner
        toast.error("You can only delete your own recipes.")
      }
    } catch (error) {
      console.error('Error deleting recipe:', error);
      toast.error("Error deleting recipe:")
    }
  };

  const confirmDelete = async () => {
    // Delete the recipe
    await deleteDoc(doc(db, 'recipes', id));
    toast.success(`Recipe successfully deleted`)
    navigate('/app-home');
  };

  // COPY LINK
  const copyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      toast.success("Copied to clipboard!");
    }).catch((error) => {
      console.error('Could not copy text: ', error);
      toast.error("Error copying recipe link to clipboard.");
    });
  }

  useEffect(() => {
    const fetchRecipe = async () => {
      const docRef = doc(db, "recipes", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setRecipe(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
      }
    };

    fetchRecipe();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }



  return (
    <div className='Page-container' style={{ background: colors.background }}>
      <div className="Page-small" >
        <div className="Page-header">
          <div className="Page-title">

          </div>

        </div>
        <DownloadMobileBanner />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <Button appearance='icon' iconBefore={<CaretLeft size={24} color={colors.icon} />}
              onClick={() => navigate('/app-home')} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <Button
              iconBefore={<Copy size={24} color={colors.icon} />}
              appearance="icon"
              onClick={copyLink}
            />
            <WeekDatePicker
              recipeId={id}
              recipeData={recipe}
            />
            {user && user.uid === recipe.userId && (
              <Button appearance='icon' iconBefore={<Pencil size={24} color={colors.icon} />}
                onClick={handleEdit} />
            )}

            {user && user.uid === recipe.userId && (
              <Button appearance='icon' iconBefore={<Trash size={24} color={colors.R700} />}
                onClick={handleDelete} />
            )}

          </div>
        </div>



        <Spacer size="lg" />
        {recipe.imageUrl && (
          <img src={recipe.imageUrl} alt={recipe.title} style={{ borderRadius: 24, height: 400 }} />
        )}
        <Spacer size="lg" />
        {recipe.author ? (
          <div>
            <AvatarMetadata
              userId={recipe.userId}
              name={recipe.author}
              size="small"
              image={recipe.authorPhoto}
              emoji={recipe.userAvatarEmoji}
              background={recipe.userAvatarBackground}
            />
            <Spacer size="sm" />
          </div>
        ) : null}
        <h1 style={{ color: colors.text }}>{recipe.title}</h1>
        <Spacer size="lg" />
        <RecipeDetailInfo
          recipe={recipe}
          handleEdit={handleEdit}
          user={user}
        />
        <RecipeDetailIngredients
          recipe={recipe}
          handleEdit={handleEdit}
          user={user}
        />
        <RecipeDetailSteps
          recipe={recipe}
          handleEdit={handleEdit}
          user={user}
        />
      </div>



      {/* EDIT */}
      {showEditRecipeModal && (
        <CreateRecipeModal
          open
          isEditing={true}
          recipeId={id}
          recipeData={recipe}
          onSubmit={updatedRecipeData => setRecipe(updatedRecipeData)}
          onCancel={() => setShowEditRecipeModal(false)}

        />
      )}

      {/* DELETE */}
      <DeleteModal
        open={showDeleteModal}
        handleOk={confirmDelete}
        handleCancel={() => setShowDeleteModal(false)}
      />
    </div>
  )
}


export default RecipeDetail