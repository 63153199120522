import React from 'react'
import { Calendar, CookingPot, ShoppingCart, CloudArrowDown, Play, Books, Lightbulb, ArrowsClockwise, Star } from 'phosphor-react';

const features = [
    { icon: <Calendar />, title: 'Weekly Meal Planner', description: 'Plan your meals for the week effortlessly' },
    { icon: <CookingPot />, title: 'Recipe Organizer', description: 'Store and organize all your favorite recipes' },
    { icon: <ShoppingCart />, title: 'Shopping List', description: 'Create and manage your shopping list' },
    { icon: <CloudArrowDown />, title: 'Import From Websites', description: 'Automatically import recipes from website links and urls' },
    { icon: <Play />, title: 'Playback Mode', description: 'Play instructions step by step while your hands are busy cooking ' },
    { icon: <Lightbulb />, title: 'Smart Suggestions', description: 'Get recommendations based on seasonal ingredients and grocery list' },
    { icon: <ArrowsClockwise />, title: 'Automatic Sync', description: 'Ensure your recipes are across all your devices, everywhere.' },
    { icon: <Star />, title: 'Quick Save', description: 'Save your favourite recipes' },
    { icon: <Books />, title: 'Recipe Collections', description: 'Create recipe collections to categorise your recipes with ease' },
    // Add more features as needed
];

const FeatureGrid = () => {
    return (
        <div style={styles.darkContainer}>
            <div style={styles.headerContainer}>
                <h2 style={{ textColor: '#FFFFFF' }}>Features</h2>
                <p>Bentofy is simple at first glance, but it's powerful to help you plan meals like a pro</p>
            </div>
            <div style={styles.grid}>
                {features.map((feature, index) => (
                    <div style={styles.card} key={index}>
                        <div style={styles.iconContainer}>
                            <div style={styles.icon}>{feature.icon}</div>
                        </div>
                        <h3 style={styles.title}>{feature.title}</h3>
                        <p style={styles.description}>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

const styles = {
    darkContainer: {
        backgroundColor: 'rgba(0,0,0,0.02)',
        padding: 8,
        borderRadius: 48,
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))', // Responsive layout
        gap: '16px',
        padding: '16px',

    },
    headerContainer: {
        textAlign: 'center',
        padding: 8,
        paddingBottom: 24,
        paddingTop: 48,
    },
    card: {
        // border: '1px solid #ccc',
        borderRadius: '16px',
        padding: '16px',
        // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        width: '100%', // Fill the width of the parent container
        boxSizing: 'border-box', // Ensure padding and border are included in width
    },
    icon: {
        fontSize: '24px',
        color: '#FFFFFF',
        marginTop: '4px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#18181b',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        padding: 8,
    },
    title: {
        fontSize: '18px',
        margin: '8px 0',
        paddingTop: '8px',
    },
    description: {
        fontSize: '14px',
        color: '#666',
    },
}

export default FeatureGrid