import React from 'react'
import Twemoji from 'react-twemoji'

const About = () => {
    const emojiHeader = (headerEmoji, headerText) => {
        return (
            <div className='flex' style={{marginBottom: 16}}>
                <Twemoji className="Twemoji-small">{headerEmoji}</Twemoji>
                <h3 style={{ marginBottom: 8, fontWeight: 700 }}>{headerText}</h3>
            </div>
        )
    }

  return (
    <div className='Page-container'>
        <div className='Page-xsmall'>
            <br></br>
            <br></br>
            <div className='Page-header'>
                <div className='Page-title'>
                    <h2 className='flex-grow Text-center'>
                        Make everyday cooking easy and fun
                    </h2>
                </div>
            </div>
            {/* <img src={require("../images/3d_illos_bento.png")} alt="hero image"/> */}
            <h4 className='flex-grow Text-center' style={{lineHeight: "28px"}}>
                Bentofy is a personal project by James Rotanson.
                <br></br>I am on a quest to make everyday cooking easy and fun with the ultimate, all-in-one meal companion app for home cooks and busy professionals.
            </h4>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {emojiHeader("🌏", "Back story")}
            <p>
                I started creating Bentofy in February 2023 after returning from my trip to my hometown in Medan, Indonesia. 
                While my family and I love cooking, my mum's chronic health condition prevents her from eating a variety of ingredients commonly found in my hometown. 
                On the other hand, my wife is so used to the clean and healthy Japanese cuisine that it feels really heavy to take on the hearty and oily flavors of Indonesian food. 
                <br></br>
                <br></br>
                This is when I realized a problem: <strong>It's hard to think about what to eat and cook to satisfy everyone's tastes and preferences</strong>, 
                let alone manage the meals and grocery lists on top of our busy lives.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {emojiHeader("🤔", "The idea")}
            <p>
                The idea is to make <strong> the ultimate place to manage all your meals in one place </strong>. 
                We’ve all done this with post it notes or whiteboards on the fridge to some degree. 
                Some have even used digital tools Pinterest, Instagram, Notion, and task management apps to handle recipes, meal planning, and grocery tracking.
                The problem is, it’s painful to switch to separate tools all the time while also manually inputting recipes and ingredients from different sources. 
                Our lives are already so busy and there are so many decisions we have to make. 
                The last thing you want to think about after a long day at work is deciding what to have for dinner. 
                <br></br>
                <br></br>
                <strong>What if there’s a better way? </strong>
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {emojiHeader("✨", "Enter Bentofy")}
            <p>
                This is where Bentofy comes in. 
                With Bentofy you can <strong>store your recipes</strong> and <strong>plan your meals</strong> based on those recipes into a <strong>beautiful, visual calendar</strong>. 
                From there, you can <strong>automatically get a list of ingredients</strong> based on what you have planned so you <strong>don’t have to manually input</strong> this into the grocery list. 
                Plus, you can also <strong>discover what people around you are cooking</strong> and get inspired for your next week’s plan. 
                It’s super simple and easy to use. 
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {emojiHeader("🍎", "Benefits of planning")}
            <p>
                But why even plan in the first place? 
                The Japanese have done this for a long time with a method called “Kondate” (menu programming). 
                By planning your meals in advance, you can be more conscious about the taste, variety, nutrition, cost, and sustainability of what you’re putting inside your body. 
                This hopefully helps to reduce the time and stress thinking about what to cook on the day and impulsive grocery purchases 
                (especially in this time of rising prices everywhere). To summarise the benefits of meal planning:
            </p>
            <ul style={{paddingInlineStart: 24}}>
                <li style={{listStyleType: "disc"}}>
                   <p> <strong>Time and stress management.</strong> Reduce stress thinking of what to eat on the day.</p>
                </li>
                <li style={{listStyleType: "disc"}}>
                   <p> <strong>Healthier choice.</strong> Helps to make healthier choices and maintain a balanced, nutritious diet. </p>
                </li>
                <li style={{listStyleType: "disc"}}>
                   <p> <strong>Cost-effectiveness.</strong> Reduce food waste and impulsive grocery purchases.</p>
                </li>
                <li style={{listStyleType: "disc"}}>
                   <p> <strong>Variety and exploration.</strong> Helps you to explore different recipes, cuisines, and flavours to make sure you don’t get stuck in repetitive food routine. </p>
                </li>         
            </ul>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {emojiHeader("😉", "Fun fact")}
            <p>
                The name Bentofy comes from the Japanese word "bento" and Chinese term "biàndāng" (便當), which means "convenience”, which is what I want this app to provide. 
                A visual symbol of organised meal, a bento literally puts different ingredients and dishes into distinct boxes. 
                Lastly, extending it to a verb “Bentofy” to make it easier to put it in a sentence like an action “Let’s bentofy it”. 
                And that’s the origin of Bentofy: to make it easy to organise and plan your meals. 
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {emojiHeader("🦉", "Support")}
            <p>Have questions? Need support?  Send email to: <a href="mailto:hello@bentofy.app">hello[at]bentofy.app</a></p>
            <p>
                Bentofy is still in the startup stage and not everything might not work as expected. 
                This is exactly why I'd love to hear your feedback on how to improve the experience for you and every home cooks in the world. 🙏
                </p>
            <br></br>
            <div className='flex'>
                <a href="/privacy-policy"> Privacy policy</a>
                <p> · </p>
                <a href="/terms-of-service"> Terms of Service</a>
            </div>
        </div>
    </div>
  )
}

export default About