import React, { useState } from 'react';
import { Popover } from 'antd';
import { CalendarCheck, CalendarPlus } from 'phosphor-react';
import { useTheme } from '../styles/ThemeContext';
import Button from '../components/Button.tsx';
import moment from 'moment';
import { auth, db } from '../firebase-config';
import { doc, updateDoc } from 'firebase/firestore';
import { useRecipeSubmission } from '../controllers/recipeSubmission';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const WeekDatePicker = ({ recipeId, recipeData }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { colors } = useTheme();
    const { onRecipeSubmit } = useRecipeSubmission();
    // const [recipeDate, setRecipeDate] = useState(null);
    const [recipeDate, setRecipeDate] = useState(moment().format("YYYY-MM-DD"));
    const navigate = useNavigate();

    // DATES
    const formattedDate = recipeDate
        ? moment(recipeDate).format("YYYY-MM-DD")
        : recipeData.date
            ? recipeData.date
            : moment(new Date()).format("YYYY-MM-DD");

    // SELECT DATE
    const currentUser = auth.currentUser;

    const handleDateSelection = async selectedDate => {
        // Update the date field in Firebase
        if (currentUser && recipeData.userId === currentUser.uid) {
            const recipeRef = doc(db, 'recipes', recipeId);
            await updateDoc(recipeRef, { date: selectedDate });
        } else if (currentUser) {
            const newRecipe = {
                ...recipeData,
                userId: currentUser.uid,
                author: currentUser.displayName,
                access: 'private',
                date: selectedDate,
            };

            await onRecipeSubmit(newRecipe);
        } else {
            navigate('AccountModal');
        }

        setShowDatePicker(false);
        toast.success("Recipe is scheduled")
    };


    const datePickerContent = (
        <div className="Popover-date-picker">
            <h4>Schedule {recipeData.title}</h4>
            <input
                type="date"
                value={formattedDate}
                onChange={(event) => setRecipeDate(event.target.value)}
            />
            <div className="Button-group">
                <Button
                    name="Cancel"
                    appearance='default'
                    onClick={() => setShowDatePicker(false)}
                />
                <Button
                    name="Schedule"
                    appearance="primary"
                    style={{ color: 'white' }}
                    block
                    onClick={() => handleDateSelection(recipeDate)}
                >
                    Save
                </Button>
            </div>
        </div>
    );

    return (
        <Popover
            content={datePickerContent}
            trigger="click"
            open={showDatePicker}
            onOpenChange={() => setShowDatePicker(!showDatePicker)}
        >
            <Button
                iconBefore={<CalendarPlus size={24} color={colors.icon} />}
                appearance="icon"
                style={{ border: 0 }}
            />
        </Popover>
    );
};

export default WeekDatePicker;