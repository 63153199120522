import React, {useState, useEffect} from 'react'
import {useAuthValue} from '../context/AuthContext'
import {auth} from '../firebase-config'
import { sendEmailVerification } from 'firebase/auth'
import Button from '../components/Button.tsx'
import { useNavigate } from 'react-router-dom'

const VerifyEmail = () => {

    const {currentUser} = useAuthValue() 
    const [buttonDisabled, setButtonDisabled] = useState(false)
    
    const resendEmailVerification = () => {
        setButtonDisabled(true)
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setButtonDisabled(false)
          setTimeActive(true)
        }).catch((err) => {
          alert(err.message)
          setButtonDisabled(false)
        })
      }
    
    const navigate = useNavigate();

    const [time, setTime] = useState(60)
    const [timeActive, setTimeActive] = useState()

    // NOTE: This is the tutorial implementation but didn't work
    // const [timeActive, setTimeActive] = useAuthValue()
    
    useEffect(() => {
        let interval = null
        if(timeActive && time !== 0 ){
          interval = setInterval(() => {
            setTime((time) => time - 1)
          }, 1000)
        }
        
        else if(time === 0){
          setTimeActive(false)
          setTime(60)
          clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time])

    return (
        <div className="Auth-container">
            VerifyEmail
            <span>{currentUser?.email}</span>
            <Button
                appearance="default"
                name="Resend email"
                onClick={resendEmailVerification}
                disabled={timeActive}
            />
            {timeActive && time}
        </div>
    )
}

export default VerifyEmail