import {
    collection,
    addDoc,
    deleteDoc,
    getDocs,
    query,
    where,
} from 'firebase/firestore';
import {
    ListDashes,
    PlusCircle,
    Plus,
    Check,
    ShoppingCart,
} from 'phosphor-react';
import React, { useState } from 'react';
import parse from 'html-react-parser';
// import Toast from 'react-native-toast-message';

import { auth, db } from '../firebase-config';
import Expand from './Expand';
import moment from 'moment';
import { useTheme } from '../styles/ThemeContext';

const RecipeDetailIngredients = ({ recipe, handleEdit, user }) => {
    const { colors } = useTheme();
    const [showIngredientsExpand, setShowIngredientsExpand] = useState(true);
    const handleIngredientsExpand = () => {
        setShowIngredientsExpand(!showIngredientsExpand);
    };

    const [addedIngredients, setAddedIngredients] = useState([]);

    const addToGroceryList = async ingredientTitle => {
        const userId = auth.currentUser?.uid;
        const ingredientsListRef = collection(db, 'ingredients');
        const dateAdded = new Date().getTime();
        const formattedDate = moment(dateAdded).format("YYYY-MM-DD");

        await addDoc(ingredientsListRef, {
            title: ingredientTitle,
            userId,
            checked: false,
            dateAdded: formattedDate,
        });

        setAddedIngredients([...addedIngredients, ingredientTitle]);

        // Toast.show({
        //     type: 'success',
        //     text1: `✅ ${ingredientTitle} succesfully added to list`,
        // });
    };

    const addAllToGroceryList = async () => {
        for (const ingredient of recipe.ingredients) {
            await addToGroceryList(ingredient.title);
        }
    };

    const removeFromGroceryList = async ingredientTitle => {
        const userId = auth.currentUser?.uid;
        const ingredientsListRef = collection(db, 'ingredients');
        const q = query(
            ingredientsListRef,
            where('title', '==', ingredientTitle),
            where('userId', '==', userId)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
            deleteDoc(doc.ref);
        });

        setAddedIngredients(
            addedIngredients.filter(item => item !== ingredientTitle)
        );

        // Toast.show({
        //     type: 'success',
        //     text1: `✅ ${ingredientTitle} removed from list`,
        // });
    };

    return (
        <Expand
            iconBefore={<ListDashes size={16} color={colors.icon} />}
            onClick={handleIngredientsExpand}
            name="Ingredients"
            showExpand={showIngredientsExpand}
            extraControls={
                <div>
                    {recipe.ingredients && (
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: 8 }}
                            onClick={addAllToGroceryList}
                        >
                            <ShoppingCart size={16} color={colors.icon} />
                            <small style={{ color: colors.text }}>
                                Add all to list
                            </small>
                        </div>
                    )}
                </div>
            }
            content={
                <div style={{ gap: 16 }}>
                    {showIngredientsExpand ? (
                        <div style={{ padding: 16, gap: 16 }}>
                            {recipe.ingredients ? (
                                <div style={{ gap: 8 }}>
                                    {recipe.ingredients.map((ingredient, index) => (
                                        <div
                                            key={ingredient.key}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 8,
                                            }}
                                            onClick={() => {
                                                if (addedIngredients.includes(ingredient.title)) {
                                                    removeFromGroceryList(ingredient.title);
                                                } else {
                                                    addToGroceryList(ingredient.title);
                                                }
                                            }}
                                        >
                                            <div> • </div>
                                            <div style={{ flex: 1 }}>
                                                <p style={{ color: colors.text }}>
                                                    {ingredient.title}
                                                </p>
                                            </div>
                                            <div
                                                onClick={() => addToGroceryList(ingredient.title)}
                                            >
                                                {addedIngredients.includes(ingredient.title) ? (
                                                    <div
                                                        style={{
                                                            padding: 4,
                                                            backgroundColor: colors.G500,
                                                            borderRadius: 24,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() =>
                                                            removeFromGroceryList(ingredient.title)
                                                        }
                                                    >
                                                        <Check size={16} color={colors.iconInverse} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            padding: 4,
                                                            backgroundColor: colors.default,
                                                            borderRadius: 24,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() => addToGroceryList(ingredient.title)}
                                                    >
                                                        <Plus size={16} color={colors.icon} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {recipe.ingredientsEditorContent ? (
                                recipe.ingredientsEditorContent.startsWith('<') ? (
                                    <div style={{ color: colors.text }}>
                                        {parse(recipe.ingredientsEditorContent)}
                                    </div>
                                ) : (
                                    <p style={{ color: colors.text }}>
                                        {recipe.ingredientsEditorContent}
                                    </p>
                                )
                            ) : (
                                <div style={{ color: colors.textSubtle }}>
                                    {user && user.uid === recipe.userId ? 'Add ' : 'No '}
                                    ingredients
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            }
        />
    );
};

export default RecipeDetailIngredients;
