import React, { useState, useEffect} from 'react';
import Button from './Button.tsx';
import { X, DotsSixVertical } from 'phosphor-react';
import ListItem from './ListItem';
import Checkbox from './Checkbox.tsx';
import { useTheme } from '../styles/ThemeContext.js';

const GroceryListItem = (props) => {
  const [name, setName] = useState(props.name); // Use local state for the input field value
  const {colors} = useTheme();
  const handleChange = (event) => {
    setName(event.target.value); // Update the local state when the input value changes
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onChange({ ...props, name }); // Pass the updated name back to the parent component
  };

  

  return (
    <ListItem
      appearance="subtle"
      
      // draggable={<DotsSixVertical />}
      componentBefore={<Checkbox onChange={props.checkboxOnChange} checked={props.checked} />}
      component={
        <form className="flex-grow" onSubmit={handleSubmit}>
          <input
            className="Form-input Form-input-subtle flex-grow"
            style={{...props.checkedStyle, color: colors.text}}
            value={name} // Use the local state value for the input field
            onChange={handleChange} // Use the local state handler for input changes
          />
        </form>
      }
      componentAfter={
        <Button onClick={props.onDelete} appearance="delete Button-icon" iconBefore={<X />} />
      }
    />
  );
};

export default GroceryListItem;
